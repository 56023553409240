import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router";
import Scroll from "react-scroll";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { IMAGE_TYPES as i, } from "features/constants";
import {
	currencyFormat,
	imageFormat,
	getBrandClass,
} from "features/functions";
import { clearSearchActions } from "features/tireFinder/searchSlice";
import TireDetails from "./TireDetails";
import { scrollToCodeTires } from "features/tireResults/codeTiresSlice";
import Loader from "components/Loader";

const ScrollLink = Scroll.Link;

const getTirePrice = (tire) => {
	if (tire.Discount > 0 && tire.Minimum <= 1) return tire.Price * 1.16;
	else {
		return tire.ListPrice * 1.16;
	}
};

export default function ResultCode() {
	const dispatch = useDispatch();
	// Selectors
	const { isLoading, list, scroll } = useSelector((state) => state.codeTires);
	// State
	const [activeProduct, setActiveProduct] = useState(0);
	// Effect
	useEffect(() => {
		setActiveProduct(0);
		if (scroll) {
			dispatch(scrollToCodeTires());
		}
	}, [list, scroll, dispatch]);
	// Navigate
	const navigate = useNavigate();
	const location = useLocation();
	// Handlers
	function handleCloseClick() {
		dispatch(clearSearchActions());
		const target = document.getElementById("tireFinderElement");
		const targetPos =
			target.offsetWidth > 980 ? target.offsetTop - 100 : target.offsetTop - 50;
		const scroll = Scroll.animateScroll;
		scroll.scrollTo(targetPos, { smooth: true, delay: 100, duration: 500 });
		if (location !== "/") {
			navigate("/");
		}
	}
	function setCurrentSlide(currentSlide) {
		setActiveProduct(currentSlide);
	}
	// Render functions
	function renderProducts(activeList, active) {
		if (activeList.length > 0) {
			let data = Array.from(activeList);
			if (data.length === 2) {
				data.push(data[0]);
				data.push(data[1]);
			} else if (data.length === 3) {
				data.push(data[0]);
				data.push(data[1]);
				data.push(data[2]);
			}
			var settings = {
				dots: false,
				centerMode: true,
				infinite: true,
				initialSlide: 0,
				className: "center",
				slidesToShow: data.length === 1 ? 1 : 3,
				centerPadding: 0,
				afterChange: (currentSlide) => {
					setCurrentSlide(currentSlide);
				},
				responsive: [
					{
						breakpoint: 980,
						settings: {
							slidesToShow: 1,
							slidesToScroll: 1,
							initialSlide: 0,
						},
					},
				],
			};
			const w = window.innerWidth;
			let offset = w > 980 ? -100 : -50;
			return (
				<Slider {...settings}>
					{data.map((tire, index) => {
						if (index === active) {
							return (
								<div id={tire.ProductId} key={index}>
									<div>
										<ScrollLink
											to="resultTireDetails"
											smooth={true}
											duration={500}
											offset={offset}
										>
											<div>
												<img
													src={imageFormat(tire.TireProduct.Image, i.CAROUSEL)}
													alt={tire.TireProduct.Name}
												/>
												<span
													className={getBrandClass(tire.TireProduct.Brand)}
												></span>
											</div>
											<div>
												<h2>{tire.TireProduct.Name}</h2>
											</div>
											<div>{tire.Size}</div>
											<div>
												{currencyFormat(getTirePrice(tire))} <span>MXN</span>
											</div>
										</ScrollLink>
									</div>
								</div>
							);
						} else {
							return (
								<div id={tire.ProductId} key={index}>
									<div>
										<div>
											<img
												src={imageFormat(tire.TireProduct.Image, i.CAROUSEL)}
												alt={tire.TireProduct.Name}
											/>
											<span
												className={getBrandClass(tire.TireProduct.Brand)}
											></span>
										</div>
										<div>
											<h2>{tire.TireProduct.Name}</h2>
										</div>
										<div>{tire.Size}</div>
										<div>
											{currencyFormat(getTirePrice(tire))} <span>MXN</span>
										</div>
									</div>
								</div>
							);
						}
					})}
				</Slider>
			);
		} else {
			return (
				<div className="no-results">
					<div>No hay productos disponibles para esta opción</div>
				</div>
			);
		}
	}
	function renderTireDetails(tireDetails) {
		if (tireDetails !== null) {
			return <TireDetails {...tireDetails} />;
		} else return null;
	}
	// Render
	if (isLoading) {
		return (
			<div id="result-view">
				<div className="result-stripe vehicle-result">
					<div>0</div>
					<div>Resultados</div>
					<div>&nbsp;</div>
					<div>{"Medida: Cargando..."}</div>
				</div>
				<div className="result-products short">
					<div className="result-products-slider">
						<div className="no-results">
							<Loader msg="" />
						</div>
					</div>
				</div>
			</div>
		);
	}
	if (list == null || list.length === 0) {
		return null;
	}
	const tireDetails =
		activeProduct >= list.length
			? list[activeProduct - list.length]
			: list[activeProduct];
	return (
		<div id="result-view">
			<div className="result-stripe vehicle-result">
				<div>{list.length}</div>
				<div>Resultado</div>
				<div onClick={handleCloseClick}>
					<span className="text-remove"></span>
					<span className="icon-remove"></span>
				</div>
				<div>{list[0].TireProduct.Name}</div>
			</div>
			<div className="result-products short">
				<div className="result-products-slider">
					{renderProducts(list, activeProduct)}
				</div>
			</div>
			{renderTireDetails(tireDetails)}
		</div>
	);
}
