import { configureStore } from "@reduxjs/toolkit";
import createRootReducer from "features/reducers";
import thunk from "redux-thunk";
import { createReduxHistoryContext } from "redux-first-history";
import { createBrowserHistory } from "history";

const debug = process.env.REACT_APP_REDUX_DEBUG === "true";

const {
  createReduxHistory,
  routerMiddleware,
  routerReducer
} = createReduxHistoryContext({ history: createBrowserHistory() });

export const store = configureStore({
		reducer: createRootReducer(routerReducer),
		middleware: [thunk, routerMiddleware],
		devTools: debug,
	});
	export const history = createReduxHistory(store);;