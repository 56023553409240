import React from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactGA from "react-ga4";
import Modal from "react-modal";
import { CART_STEPS as S, PAYMENT_METHODS } from "features/constants";
import { toggleShowCart } from "features/cart/cartSlice.js";
import Cart from "./Cart";

export default function CartModal(props) {
	const dispatch = useDispatch();
	// Selectors
	const {
		isOpen,
		showTab,
		paymentType,
	} = useSelector((state) => state.cart);
	// Handlers
	function handleCloseClick() {
		ReactGA.send({ hitType: "pageview", page: "/" });
		dispatch(toggleShowCart({ isOpen: true }));
	}
	// Render Functions
	function renderDepositDisclaimer() {
		if (paymentType != null && paymentType === PAYMENT_METHODS.DEPOSIT) {
			return (
				<div className="alert-box">
					<span className="close-box" />
					Al seleccionar la forma de pago “Depósito Bancario” sus llantas están
					sujetas a disponibilidad debido a que el inventario con que se cuenta
					al momento de hacer su pedido puede variar a la fecha de la
					confirmación de su pago y validación de su pago.
				</div>
			);
		}
		if (paymentType != null && paymentType === PAYMENT_METHODS.PAYNET) {
			return (
				<div className="alert-box">
					<span className="close-box" />
					Al seleccionar la forma de pago “Pago en Establecimientos” sus llantas
					están sujetas a disponibilidad debido a que el inventario con que se
					cuenta al momento de hacer su pedido puede variar a la fecha de la
					confirmación de su pago.
				</div>
			);
		}
		return null;
	}
	function getTabProps(tab) {
		if (showTab === tab) {
			return { className: "active" };
		}
		return {};
	}
	return (
		<div>
			<Modal
				isOpen={isOpen}
				className="modal"
				overlayClassName="modal-overlay"
				onRequestClose={handleCloseClick}
				contentLabel="Carrito"
			>
				<div className="modal-inner" id="cart-inner">
					<div className="modal-close" onClick={handleCloseClick} />
					<h2>Mi Carrito</h2>
					<div className="cart-steps">
						<div id="cart-1" {...getTabProps(S.DETAILS)}>
							<span>{S.DETAILS}</span>
							Carrito
						</div>
						<div id="cart-2" {...getTabProps(S.SHIP)}>
							<span>{S.SHIP}</span>
							Envío
						</div>
						<div id="cart-3" {...getTabProps(S.MYDATA)}>
								<span>{S.MYDATA}</span>
								Mis Datos
							</div>
						<div id="cart-4" {...getTabProps(S.PAYMENT)}>
							<span>{S.PAYMENT}</span>
							Forma de Pago
						</div>
						<div id="cart-5" {...getTabProps(S.SUMMARY)}>
							<span>{S.SUMMARY}</span>
							Resumen
						</div>
					</div>
					<div className="alert-box">
						<span className="close-box" />
						Producto Disponible, se entrega en la sucursal en un periodo aproximado
						de 7 días hábiles.
					</div>
					{renderDepositDisclaimer()}
					<Cart />
				</div>
			</Modal>
		</div>
	);
}
