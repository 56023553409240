import { combineReducers } from "redux";
import { sessionReducer } from "redux-react-session";
import accountSlice from "./account/accountSlice";
import accountCardAddSlice from "./account/accountCardAddSlice";
import accountCardEditSlice from "./account/accountCardEditSlice";
import accountCardsSlice from "./account/accountCardsSlice";
import alertSlice from "./alert/alertSlice";
import bannersSlice from "./banners/bannersSlice";
import cartSlice from "./cart/cartSlice";
import codeTiresSlice from "./tireResults/codeTiresSlice";
import dealerShipAddressSlice from "./cart/dealerShipAddressSlice";
import familyFriendsSlice from "./account/familyFriendsSlice";
import footerSlice from "./account/footerSlice";
import highlightsTiresSlice from "./tireResults/highlightsTiresSlice";
import locationsSlice from "./account/locationsSlice";
import loginSlice from "./account/loginSlice";
import orderConfirmationSlice from "./orders/orderConfirmationSlice";
import ordersSlice from "./orders/ordersSlice";
import searchSlice from "./tireFinder/searchSlice";
import searchTiresSlice from "./tireResults/searchTiresSlice";
import shipLocationsSlice from "./account/shipLocationsSlice";
import sizeCanvasSlice from "./tireFinder/sizeCanvasSlice";
import sizeCanvasTruckSlice from "./tireFinder/sizeCanvasTruckSlice";
import sizeShortSlice from "./tireFinder/sizeShortSlice";
import sizeWidthDiametersSlice from "./tireFinder/sizeWidthDiametersSlice";
import sizeRatiosSlice from "./tireFinder/sizeRatiosSlice";
import sizeRimsSlice from "./tireFinder/sizeRimsSlice";
import sizeTiresSlice from "./tireResults/sizeTiresSlice";
import sizeSaeWidthsSlice from "./tireFinder/sizeSaeWidthsSlice";
import sizeSaeRimsSlice from "./tireFinder/sizeSaeRimsSlice";
import sizeSaeTiresSlice from "./tireResults/sizeSaeTiresSlice";
import vehicleTypeSlice from "./tireFinder/vehicleTypeSlice";
import vehicleBrandsSlice from "./tireFinder/vehicleBrandsSlice";
import vehicleModelsSlice from "./tireFinder/vehicleModelsSlice";
import vehicleVersionsSlice from "./tireFinder/vehicleVersionsSlice";
import vehicleYearSlice from "./tireFinder/vehicleYearsSlice";
import vehicleTiresSlice from "./tireResults/vehicleTiresSlice";
import paymentCardSlice from "./cart/paymentCardSlice";


const createRootReducer = (routerReducer) =>
	combineReducers({
		account: accountSlice,
		accountCardAdd: accountCardAddSlice,
		accountCardEdit: accountCardEditSlice,
		accountCards: accountCardsSlice,
		alert: alertSlice,
		banners: bannersSlice,
		cart: cartSlice,
		codeTires: codeTiresSlice,
		dealerShipAddress: dealerShipAddressSlice,
		familyFriends: familyFriendsSlice,
		footer: footerSlice,
		highlightsTires: highlightsTiresSlice,
		locations: locationsSlice,
		login: loginSlice,
		orderConfirmation: orderConfirmationSlice,
		orders: ordersSlice,
		paymentCard: paymentCardSlice,
		router: routerReducer,
		search: searchSlice,
		searchTires: searchTiresSlice,
		session: sessionReducer,
		shipLocations: shipLocationsSlice,
		sizeCanvas: sizeCanvasSlice,
		sizeCanvasTruck: sizeCanvasTruckSlice,
		sizeShort: sizeShortSlice,
		sizeWidthDiameters: sizeWidthDiametersSlice,
		sizeRatios: sizeRatiosSlice,
		sizeRims: sizeRimsSlice,
		sizeTires: sizeTiresSlice,
		sizeSaeWidths: sizeSaeWidthsSlice,
		sizeSaeRims: sizeSaeRimsSlice,
		sizeSaeTires: sizeSaeTiresSlice,
		vehicleType: vehicleTypeSlice,
		vehicleBrands: vehicleBrandsSlice,
		vehicleModels: vehicleModelsSlice,
		vehicleYears: vehicleYearSlice,
		vehicleVersions: vehicleVersionsSlice,
		vehicleTires: vehicleTiresSlice,
	});
export default createRootReducer;