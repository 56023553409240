import React from "react";
import { useSelector } from "react-redux";
import { format, parseISO } from "date-fns";
import { SHIP_TYPES as s, PAYMENT_METHODS as p } from "features/constants";
import { currencyFormat } from "features/functions";

export default function PaynetOrderConfirmation() {
	// Selectors
	const { order } = useSelector((state) => state.orderConfirmation);
	// Render
	return (
		<div className="order-paynet">
			<h3>{order.Nombre}</h3>
			<p>
				Su pedido ha sido completado satisfactoriamente, a continuación el
				formato para pago en establecimiento junto con los datos de su compra.
			</p>
			<div className="paynet-format">
				<div className="paynet-header">
					<div>
						<div className="paynet-item">
							<div>Fecha límite de pago</div>
							<div>
								{format(
									parseISO(order.Paynet.DueDate),
									"dd/MMM/yyyy hh:mm aaaa"
								)}{" "}
							</div>
						</div>
						<div className="paynet-item">
							<div>Total a pagar</div>
							<div className="paynet-amount-box">
								<div>
									{currencyFormat(order.Total)} <em>MXN</em>
								</div>
								<div>
									La comisión por recepción del pago varía de acuerdo a los
									términos y condiciones que cada cadena comercial establece.
								</div>
							</div>
						</div>
					</div>
					<div>
						<div className="paynet-item">
							<div>Servicio a pagar</div>
							<div className="paynet-logo">
								<img
									src="/images/paynet/logo-paynet-openpay.png"
									alt="Paynet by OpenPay"
								/>
							</div>
						</div>
						<div className="paynet-item">
							<div>Referencia de pago</div>
							<div className="paynet-barcode">
								<div>
									<img src={order.Paynet.BarcodeUrl} alt="Código de barras" />
								</div>
								<div>{order.Paynet.Reference}</div>
								<div>
									En caso de que el escáner no sea capaz de leer el código de
									barras, escribir la referencia tal como se muestra.
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="paynet-content">
					<div>Detalles de su compra</div>
					<div className="paynet-content-table">
						<div>
							<div>Número de Pedido:</div>
							<div>{order.Numero}</div>
						</div>
						<div>
							<div>Fecha y hora:</div>
							<div>
								{format(parseISO(order.Fecha), "dd/MMM/yyyy hh:mm aaaa")}{" "}
							</div>
						</div>
						<div>
							<div>Artículos:</div>
							<div>{order.Articulos}</div>
						</div>
						<div>
							<div>Acumulados en el año:</div>
							<div>{order.Acumuladas}</div>
						</div>
						<div>
							<div>Forma de Pago:</div>
							<div>{p.properties[order.MetodoPago].name}</div>
						</div>
						<div>
							<div>Envío:</div>
							<div>{s.properties[order.TipoEnvio].name}</div>
						</div>
					</div>
				</div>
				<div className="paynet-footer">
					<div>
						<div className="paynet-item">
							<div>Cómo realizar el pago</div>
							<div>
								<ol>
									<li>Acude a cualquier tienda afiliada</li>
									<li>
										Entrega al cajero el código de barras y menciona que
										realizarás un pago de servicio Paynet
									</li>
									<li>
										Realizar el pago en efectivo por{" "}
										{currencyFormat(order.Total)} MXN
									</li>
									<li>Conserva el ticket para cualquier aclaración</li>
									<li>
										Si tienes dudas comunícate a {order.Paynet.MerchantName} al
										teléfono {order.Paynet.Phone} o al correo{" "}
										{order.Paynet.Email}
									</li>
								</ol>
							</div>
						</div>
					</div>
					<div>
						<div className="paynet-item">
							<div>Instrucciones para el cajero</div>
							<div>
								<ol>
									<li>Ingresar al menú de Pago de Servicios</li>
									<li>Seleccionar Paynet</li>
									<li>
										Escanear el código de barras o ingresar el núm. de
										referencia
									</li>
									<li>Ingresa la cantidad total a pagar</li>
									<li>Cobrar al cliente el monto total más la comisión</li>
									<li>
										Confirmar la transacción y entregar el ticket al cliente
									</li>
								</ol>
							</div>
							<div className="paynet-note">
								Para cualquier duda sobre como cobrar, por favor llamar al
								teléfono (55) 5351 7371 en un horario de 8am a 9pm de lunes a
								domingo.
							</div>
						</div>
					</div>
					<div>
						<div className="paynet-store-logos">
							<figure>
								<img
									src="/images/paynet/paynet-logo-walmart.png"
									alt="Walmart"
								/>
							</figure>
							<figure>
								<img
									src="/images/paynet/paynet-logo-7eleven.png"
									alt="7 Eleven"
								/>
							</figure>
							<figure>
								<img
									src="/images/paynet/paynet-logo-bodega-aurrera.png"
									alt="Bodega Aurrera"
								/>
							</figure>
							<figure>
								<img
									src="/images/paynet/paynet-logo-farmaciasdelahorro.png"
									alt="Farmacias del Ahorro"
								/>
							</figure>
							<figure>
								<img
									src="/images/paynet/paynet-logo-circlek.png"
									alt="Circle K"
								/>
							</figure>
							<figure>
								<img
									src="/images/paynet/paynet-logo-superama.png"
									alt="Superama"
								/>
							</figure>
							<figure>
								<img
									src="/images/paynet/paynet-logo-samsclub.png"
									alt="Sams Cub"
								/>
							</figure>
							<figure>
								<img
									src="/images/paynet/paynet-logo-waldos.png"
									alt="Waldo's"
								/>
							</figure>
						</div>
						<div className="paynet-other-stores">
							<div>¿Quieres pagar en otras tiendas?</div>
							<div>Visítanos en: www.openpay.mx/tiendas</div>
						</div>
					</div>
				</div>
			</div>
			<p>Conserve todos estos datos para cualquier aclaración.</p>
		</div>
	);
}
