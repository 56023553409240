import { createSlice } from "@reduxjs/toolkit";
import ReactGA from "react-ga4";
import { BASE_API_URL } from "../constants";
import { getMatchingItemsTireSize } from "features/functions";
import { sessionService } from "redux-react-session";
import axios from "axios";
import { runUnauthorizedActions } from "features/account/loginSlice";

const initialState = {
	isLoading: false,
	value: "",
	selectedItem: {},
	suggestions: [],
	list: null,
	vehicleType: "0",
};

const sizeWidthDiameters = createSlice({
	name: "sizeWidthDiameters",
	initialState,
	reducers: {
		requestWidthDiametersList(state) {
			state.isLoading = true;
		},
		receiveWidthDiametersList(state, action) {
			state.isLoading = false;
			state.list = action.payload.list;
		},
		setSizeVehicleType(state, action) {
			state.vehicleType = action.payload.vehicleType;
		},
		loadWidthDiametersSuggestionsBegin(state) {
			state.isLoading = true;
		},
		maybeUpdateWidthDiametersSuggestions(state, action) {
			state.isLoading = false;
			if (state.value === action.payload.value) {
				state.suggestions = action.payload.suggestions;
			}
		},
		updateWidthDiameterValue(state, action) {
			state.value = action.payload.value;
		},
		updateWidthDiameterItem(state, action) {
			const selectedItem = action.payload.selectedItem;
			if (selectedItem?.Value != null) {
				if (selectedItem.IsSae) {
					ReactGA.event({
						category: "Medida SAE",
						action: "Seleccionar Diametro",
						label: selectedItem.Value + "",
						value: selectedItem.Value,
					});
				} else {
					ReactGA.event({
						category: "Medida",
						action: "Seleccionar AnchoSección",
						label: selectedItem.Value + "",
						value: selectedItem.Value,
					});
				}
			}
			state.selectedItem = selectedItem;
		},
		clearWidthDiametersSuggestions(state) {
			state.suggestions = [];
		},
		clearWidthDiameterValue(state) {
			state.value = "";
			state.selectedItem = {};
		},
		clearWidthDiameterData: () => initialState,
	},
});

export const {
	requestWidthDiametersList,
	receiveWidthDiametersList,
	setSizeVehicleType,
	loadWidthDiametersSuggestionsBegin,
	maybeUpdateWidthDiametersSuggestions,
	updateWidthDiameterValue,
	updateWidthDiameterItem,
	clearWidthDiametersSuggestions,
	clearWidthDiameterValue,
	clearWidthDiameterData,
} = sizeWidthDiameters.actions;

const shouldFetchWidthDiameters = (state, value) => {
	if (state.sizeWidthDiameters.list == null) {
		return true;
	}
	if (state.sizeWidthDiameters.value !== value) {
		return true;
	}
	return false;
};
export const fetchWidthDiametersIfNeeded =
	(value = "") =>
	async (dispatch, getState) => {
		if (shouldFetchWidthDiameters(getState(), value)) {
			const theState = getState();
			return dispatch(fetchWidthDiameters(theState.vehicleType.value));
		}
	};
export const loadWidthDiametersSuggestions =
	(value) => async (dispatch, getState) => {
		dispatch(loadWidthDiametersSuggestionsBegin());
		dispatch(fetchWidthDiametersIfNeeded());
		const theState = getState();
		setTimeout(() => {
			dispatch(
				maybeUpdateWidthDiametersSuggestions({
					suggestions: getMatchingItemsTireSize(
						theState.sizeWidthDiameters,
						value
					),
					value,
				})
			);
		});
	};
export const fetchWidthDiameters = (dept) => async (dispatch) => {
	sessionService
		.loadSession()
		.then((session) => {
			const headers = {
				Authorization: "bearer " + session.loginData.access_token,
				"Content-Type": "application/json",
			};
			const options = {
				headers: headers,
			};
			const url = BASE_API_URL + "/api/Empleados/Medidas/?depto=" + dept;
			dispatch(requestWidthDiametersList());
			return axios
				.get(url, options)
				.then((response) => {
					dispatch(receiveWidthDiametersList({ list: response.data }));
				})
				.catch((error) => {
					// If 401 Unauthorized login failed
					if (error.response.status === 401) {
						dispatch(runUnauthorizedActions());
					}
				});
		})
		.catch((err) => console.log("Error", err));
};

export default sizeWidthDiameters.reducer;
