const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
	focus: null,
	isSAE: false,
	first: null,
	firstLoad: true,
};

const sizeCanvasTruckSlice = createSlice({
	name: "sizeCanvas",
	initialState,
	reducers: {
		setCanvasTruckFirstItem(state, action) {
			state.first = action.payload.first;
			state.focus = null;
			state.isSAE = action.payload.isSAE??false;
			state.firstLoad = false;
		},
		setCanvasTruckFocus(state, action) {
			state.focus = action.payload.focus;
			state.firstLoad = false;
		},
	},
});

export const {
	setCanvasTruckFirstItem,
	setCanvasTruckFocus,
} = sizeCanvasTruckSlice.actions;

export default sizeCanvasTruckSlice.reducer;
