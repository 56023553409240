import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Dropdown from "react-dropdown";
import ReactGA from "react-ga4";
import Scroll from "react-scroll";
import { IMAGE_TYPES } from "features/constants";
import {
	imageFormat,
	getBrandClass,
	getVehicleTypeClass,
	renderPrice,
	renderOriginalPrice,
	formatDetails,
	renderRadial,
	renderIndiceVelocidad,
	renderIndiceCarga,
	renderSsr,
	renderDureza,
	renderCamara,
} from "features/functions";
import {
	fetchAddItemToCartObject,
	setCartActiveTab,
	toggleShowCart,
} from "features/cart/cartSlice";
import ProductDetailsQty from "./ProductDetailsQty";

const Element = Scroll.Element;

const getIndex = (options, selected) => {
	var filtered = options.filter((el) => el.value === selected.value);
	return filtered[0].index;
};

export default function ProductDetails(props) {
	const dispatch = useDispatch();
	// State
	const [option, setOption] = useState(props.options[0]);
	const [optionIndex, setOptionIndex] = useState(0);
	const [defaultValue, setDefaultValue] = useState(null);
	const [qty, setQty] = useState(1);
	const [showDetails, setShowDetails] = useState(false);
	const defaultQty = 1;
	// Effects
	useEffect(() => {
		setOption(props.options[0]);
		setDefaultValue(props.options[0].value);
	}, [props.options]);
	// Handlers
	function _onSelect(option) {
		ReactGA.event({
			category: "Producto",
			action: "Seleccionar Medida",
			label: props.product.Tires[getIndex(props.options, option)].Code,
		});
		setOption(option);
		setOptionIndex(getIndex(props.options, option));
		setDefaultValue(props.options[0].value);
	}
	function handleQtyChange(value) {
		setQty(value);
	}
	function handleQtyBlur(qty) {
		if (qty === 0) {
			setQty(defaultQty);
		}
	}
	function handleBuyClick() {
		const { product } = props;
		const opIndex = props.options[0].value !== defaultValue ? 0 : optionIndex;
		const tire = product.Tires[opIndex];
		// const data = {
		// 	Brand: product.Brand,
		// 	Name: product.Name,
		// 	Code: tire.Code,
		// 	Size: tire.Size,
		// 	qty,
		// };
		ReactGA.gtag("event", "add_to_cart", {
			currency: "MXN",
			value: tire.ListPrice * qty,
			items: [
				{
					item_id: tire.Code,
					item_name: product.Name,
					item_brand: product.Brand,
					item_category: "Llantas/" + tire.VehicleType,
					item_variant: tire.Size,
					price: tire.ListPrice,
					quantity: qty,
				},
			],
		});
		ReactGA.event({
			category: "Compra",
			action: "Agregar Producto",
			label: option.value,
			value: qty,
		});
		dispatch(fetchAddItemToCartObject(option.value, qty));
		dispatch(setCartActiveTab({ showTab: 1 }));
		dispatch(toggleShowCart({ isOpen: false }));
	}
	function toggleDetailsClick() {
		if (showDetails) {
			ReactGA.event({
				category: "Producto",
				action: "Mostrar Imagen",
				label: props.product.Tires[optionIndex].Code,
			});
		} else {
			ReactGA.event({
				category: "Producto",
				action: "Mostrar Detalles",
				label: props.product.Tires[optionIndex].Code,
			});
		}
		setShowDetails(!showDetails);
	}
	// Child items
	function renderDropdown(index) {
		const defaultOption = props.options[index];
		return (
			<Dropdown
				options={props.options}
				onChange={_onSelect}
				value={defaultOption}
				placeholder="Seleccione"
			/>
		);
	}
	function renderImage() {
		if (!showDetails) {
			return (
				<div className="product-image">
					<div className="image-options">
						<a id="show-details" onClick={toggleDetailsClick}>
							Detalles
						</a>
					</div>
					<div className="product-image-box">
						<img
							id="product-image-file"
							src={imageFormat(props.product.Image, IMAGE_TYPES.DETAILS)}
							alt={props.product.Name}
						/>
					</div>
				</div>
			);
		} else {
			return null;
		}
	}
	function renderDetailsSpecs() {
		if (showDetails) {
			return (
				<div className="product-description">
					<div className="description-options">
						<a id="show-image" onClick={toggleDetailsClick}>
							Imagen
						</a>
					</div>
					{formatDetails(props.product.Details)}
				</div>
			);
		} else {
			return null;
		}
	}

	// Render
	if (defaultValue == null) {
		return null;
	}
	let index = props.options[0].value !== defaultValue ? 0 : optionIndex;
	return (
		<div id="details-view">
			<Element
				name="resultProductDetails"
				id="resultProductDetails"
				className="element"
			></Element>
			<div className="product-separator">
				<span className="product-separator-image"></span>
			</div>
			<div className="product-details">
				<p className="brand-type">
					<span
						id="details-brand"
						className={getBrandClass(props.product.Brand)}
					></span>
					<span
						id="product-type"
						className={getVehicleTypeClass(
							props.product.Tires[index].VehicleType
						)}
					></span>
				</p>
				<h3 className={getBrandClass(props.product.Brand)}>
					{props.product.Name}
				</h3>
				<h4>{props.product.Slogan}</h4>
				<div className="product-measures">
					<h2>Medida de Llanta</h2>
					{renderDropdown(index)}
					<p>
						Código:{" "}
						<span id="product-code">{props.product.Tires[index].Code}</span>
					</p>
				</div>
				<div className="product-purchase">
					{renderOriginalPrice(props.product.Tires[index], qty)}
					{renderPrice(props.product.Tires[index], qty)}
					<h4>IVA Incluido</h4>
					<div>
						<div>Cantidad</div>
						<ProductDetailsQty
							Kartis={option.value}
							onChange={handleQtyChange}
							onBlur={handleQtyBlur}
							value={qty}
						/>
					</div>
					<div>
						<button
							id={option.value + "-qty"}
							name="shop-cart-submit"
							onClick={handleBuyClick}
							type="button"
						>
							Comprar
						</button>
					</div>
				</div>
				<div className={"product-specs " + getBrandClass(props.product.Brand)}>
					{renderRadial(props.product.Tires[index].Radial)}
					{renderIndiceCarga(props.product.Tires[index].Load)}
					{renderIndiceVelocidad(props.product.Tires[index].Speed)}
					{renderSsr(props.product.Tires[index].SSR)}
					{renderDureza(props.product.Tires[index].Strength)}
					{renderCamara(props.product.Tires[index].Tube)}
				</div>
			</div>
			{renderImage()}
			{renderDetailsSpecs()}
		</div>
	);
}
