import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { format, parseISO } from "date-fns";
import Modal from "react-modal";
import ReactGA from "react-ga4";
import {
	PAYMENT_METHODS as p,
	SHIP_TYPES as s,
	ORDER_STATUS_TYPES as o,
} from "features/constants";
import {
	currencyFormat,
	imageFormat,
	getBrandClass,
	formatShipAddress,
} from "features/functions";
import { toggleShowOrdersListItem } from "features/orders/ordersSlice";
import Loader from "../../Loader";

export default function OrderModal() {
	const dispatch = useDispatch();
	// Selectors
	const { isLoadingItem, isOpenItem, item } = useSelector(
		(state) => state.orders
	);
	// Handlers
	function handleCloseClick() {
		ReactGA.event({
			category: "Cuenta",
			action: "Pedidos",
		});
		dispatch(toggleShowOrdersListItem({ isOpenItem }));
	}
	// Render functions
	function renderLoader() {
		if (isLoadingItem) {
			return <Loader msg="Cargando..." />;
		}
		return null;
	}
	function renderDHL() {
		if (item.TipoEnvio === s.ADDRESS && item.Guia != null) {
			return (
				<div>
					<div>Mensajería</div>
					<div>{item.Carrier}</div>
					<div>Número de Guía</div>
					<div>
						<a href={item.CarrierURL} target="_blank" rel="noopener noreferrer">
							{item.Guia}
						</a>
					</div>
				</div>
			);
		}
		return null;
	}
	function renderItemsQty() {
		let strItems = " Artículos";
		if (item.Articulos === 1) {
			strItems = " Artículo";
		}
		return <div id="cart-items-count">{item.Articulos + strItems}</div>;
	}
	function renderItems() {
		return (
			<div id="cart-content-summary">
				{item.Elementos.map((tire, index) => {
					return (
						<div key={index} className="item" id={tire.Codigo}>
							<div>
								<h3>{tire.Cantidad}</h3>
							</div>
							<div>
								<img src={imageFormat(tire.Imagen)} alt={tire.Nombre} />
								<span className={"cart-" + getBrandClass(tire.Marca)}></span>
							</div>
							<div>
								<h2>{tire.Nombre}</h2>
								<h3>{tire.Medida}</h3>
								<p>{tire.Codigo}</p>
							</div>
							<div>
								<h3>{currencyFormat(tire.Precio * 1.16)}</h3>
								<p>MXN</p>
							</div>
							<div>
								<h3>{currencyFormat(tire.Precio * tire.Cantidad * 1.16)}</h3>
								<p>MXN</p>
							</div>
						</div>
					);
				})}
			</div>
		);
	}
	function renderDetails() {
		if (item != null && !isLoadingItem) {
			return (
				<div className="history-details">
					<div className="history-data">
						<div>
							<div>
								Número<span> de Pedido</span>
							</div>
							<div>{item.Numero}</div>
						</div>
						<div>
							<div>Fecha y Hora</div>
							<div>
								{format(parseISO(item.Fecha), "dd/MMM/yyyy hh:mm aaaa")}
							</div>
						</div>
						<div>
							<div>Forma de Pago</div>
							<div>{p.properties[item.MetodoPago].fullname}</div>
						</div>
						<div>
							<div>Forma de Envío</div>
							<div>{s.properties[item.TipoEnvio].fullname}</div>
						</div>
						<div>
							<div>Estatus</div>
							<div>{o.properties[item.Estatus].name}</div>
						</div>
						<div>
							<div>Acumuladas en el año:</div>
							<div>{item.Acumuladas}</div>
						</div>
						{renderDHL()}
					</div>
					<div>
						{renderItemsQty()}
						<div id="cart-content-headers">
							<div>Cant</div>
							<div>Descripción</div>
							<div>Precio</div>
							<div>Importe</div>
						</div>
						{renderItems()}
						<div id="order-totals">
							<div>
								<div>Subtotal</div>
								<div>{currencyFormat(item.Importe * 1.16)}</div>
							</div>
							<div>
								<div>Envío</div>
								<div>{currencyFormat(item.Envio)}</div>
							</div>
							<div>
								<div className="totals">Total</div>
								<div className="totals">{currencyFormat(item.Total)}</div>
							</div>
							<div>
								<div>Desglose IVA</div>
								<div>{currencyFormat(item.IVA)}</div>
							</div>
						</div>
					</div>
					<div className="history-adresss">
						<div>
							<div>Dirección de Envío</div>
							{formatShipAddress(item.DireccionEnvio)}
						</div>
						<div>
							<div>&nbsp;</div>
							<div>&nbsp;</div>
						</div>
					</div>
					<div className="order-controls">
						<div>
							<div
								onClick={handleCloseClick}
								className="modal-button alternate large "
							>
								Cerrar
							</div>
						</div>
					</div>
				</div>
			);
		}
		return null;
	}
	// Render
	return (
		<div>
			<Modal
				isOpen={isOpenItem}
				className="modal"
				overlayClassName="modal-overlay"
				onRequestClose={handleCloseClick}
				contentLabel="Detalles de Pedido"
			>
				<div className="modal-inner" id="history-inner">
					<div className="modal-close" onClick={handleCloseClick}></div>
					<h2>Detalles de Pedido</h2>
					{renderLoader()}
					{renderDetails()}
				</div>
			</Modal>
		</div>
	);
}
