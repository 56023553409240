import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { setCartUserData } from "features/cart/cartSlice";
import { sessionService } from "redux-react-session";
import { BASE_API_URL } from "../constants";
import { runAuthenticatedActions, runUnauthorizedActions } from "./loginSlice";

const initialState = { isLoading: false, errorMsg: null };

const accountSlice = createSlice({
	name: "account",
	initialState,
	reducers: {
		requestAccountData: (state) => {
			state.isLoading = true;
		},
		receiveAccountData: (state) => {
			state.isLoading = false;
		},
		setAccountErrorMsg: (state, action) => {
			state.errorMsg = action.payload.errorMsg;
		},
	},
});

export const { requestAccountData, receiveAccountData, setAccountErrorMsg } =
	accountSlice.actions;

export const fetchAccount = () => async (dispatch) => {
	console.log("fetchAccount");
	sessionService
		.loadSession()
		.then((session) => {
			const headers = {
				Authorization: "bearer " + session.loginData.access_token,
				"Content-Type": "application/json",
			};
			const options = {
				headers: headers,
			};
			const url = BASE_API_URL + "/api/Cuenta/MiCuenta";
			dispatch(requestAccountData());
			return axios
				.get(url, options)
				.then((response) => {
					sessionService
						.saveUser({ user: response.data })
						.then()
						.catch((error) => console.log("error", error));
					dispatch(receiveAccountData());
					dispatch(
						setCartUserData({
							email: response.data.email,
							phone: response.data.phone,
						})
					);
					dispatch(runAuthenticatedActions());
				})
				.catch((error) => {
					// If 401 Unauthorized login failed
					if (error.response.status === 401) {
						dispatch(runUnauthorizedActions());
					}
				});
		})
		.catch((err) => console.log("Error", err));
};

export default accountSlice.reducer;
