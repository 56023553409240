import { createSlice } from "@reduxjs/toolkit";
import ReactGA from "react-ga4";
import { BASE_API_URL } from "../constants";
import { getMatchingItems } from "features/functions";
import axios from "axios";
import { sessionService } from "redux-react-session";
import { runUnauthorizedActions } from "features/account/loginSlice";

const initialState = {
	isLoading: false,
	value: "",
	selectedItem: {},
	suggestions: [],
	list: null,
};

const vehicleBrands = createSlice({
	name: "vehicleBrands",
	initialState,
	reducers: {
		requestVehicleBrandsList(state) {
			state.isLoading = true;
		},
		receiveVehicleBrandsList(state, { payload }) {
			state.isLoading = false;
			state.list = payload.list;
		},
		loadVehicleBrandsSuggestionsBegin(state) {
			state.isLoading = true;
		},
		maybeUpdateVehicleBrandsSuggestions(state, { payload }) {
			state.isLoading = false;
			if (state.value !== payload.value) {
			} else {
				state.suggestions = payload.suggestions;
			}
		},
		updateVehicleBrandValue(state, {payload}) {
			state.value = payload.value;
		},
		updateVehicleBrandItem(state, { payload }) {
			if (payload.selectedItem?.Name != null) {
				ReactGA.event({
					category: "Vehiculo",
					action: "Seleccionar Marca",
					label: payload.selectedItem.Name,
					value: payload.selectedItem.ID,
				});
			}
			state.selectedItem = payload.selectedItem;
		},
		clearVehicleBrandsSuggestions(state) {
			state.suggestions = [];
		},
		clearVehicleBrandValue(state) {
			state.value = "";
			state.selectedItem = {};
		},
		clearVehicleBrandData: () => initialState,
	},
});

export const {
	requestVehicleBrandsList,
	receiveVehicleBrandsList,
	loadVehicleBrandsSuggestionsBegin,
	maybeUpdateVehicleBrandsSuggestions,
	updateVehicleBrandValue,
	updateVehicleBrandItem,
	clearVehicleBrandsSuggestions,
	clearVehicleBrandValue,
	clearVehicleBrandData,
} = vehicleBrands.actions;

const shouldFetchVehicleBrands = (state) => {
	if (state.vehicleBrands.list == null) {
		return true;
	}
	return false;
};
export const fetchVehicleBrandsIfNeeded = () => async (dispatch, getState) => {
	if (shouldFetchVehicleBrands(getState())) {
		return dispatch(fetchVehicleBrands());
	}
};
export const loadVehicleBrandsSuggestions =
	(value) => async (dispatch, getState) => {
		dispatch(loadVehicleBrandsSuggestionsBegin());
		dispatch(fetchVehicleBrandsIfNeeded());
		const theState = getState();
		setTimeout(() => {
			dispatch(
				maybeUpdateVehicleBrandsSuggestions({
					suggestions: getMatchingItems(theState.vehicleBrands, value),
					value,
				})
			);
		});
	};
export const fetchVehicleBrands = () => async (dispatch) => {
	sessionService
		.loadSession()
		.then((session) => {
			const headers = {
				Authorization: "bearer " + session.loginData.access_token,
				"Content-Type": "application/json",
			};
			const options = {
				headers: headers,
			};
			const url = BASE_API_URL + "/api/Empleados/Vehiculos/Buscar/Marcas";
			dispatch(requestVehicleBrandsList());
			return axios
				.get(url, options)
				.then((response) => {
					dispatch(receiveVehicleBrandsList({ list: response.data }));
				})
				.catch((error) => {
					// If 401 Unauthorized login failed
					if (error.response.status === 401) {
						dispatch(runUnauthorizedActions());
					}
				});
		})
		.catch((err) => console.log("Error", err));
};

export default vehicleBrands.reducer;
