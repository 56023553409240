import React, { useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router";
import Autosuggest from "react-autosuggest";
import Scroll from "react-scroll";
import { VEHICLE_TYPES as vt } from "features/constants";
import {
	clearSearchActions,
	clearSizeWidthDiameterActions,
	clearSizeRatioActions,
	clearSizeRimActions,
	clearSizeSaeWidthActions,
	clearSizeSaeRimActions,
} from "features/tireFinder/searchSlice";
import {
	setCanvasFirstItem,
	setCanvasSecondItem,
	setCanvasThirdItem,
	setCanvasFocus,
} from "features/tireFinder/sizeCanvasSlice";
import {
	fetchWidthDiametersIfNeeded,
	updateWidthDiameterItem,
	updateWidthDiameterValue,
	loadWidthDiametersSuggestions,
	clearWidthDiametersSuggestions,
} from "features/tireFinder/sizeWidthDiametersSlice";
import { setVehicleType } from "features/tireFinder/vehicleTypeSlice";
import {
	fetchSizeRatiosIfNeeded,
	clearSizeRatioValue,
	updateSizeRatioValue,
	updateSizeRatioItem,
	loadSizeRatiosSuggestions,
	clearSizeRatiosSuggestions,
} from "features/tireFinder/sizeRatiosSlice";
import {
	clearSizeRimValue,
	fetchSizeRimsIfNeeded,
	updateSizeRimValue,
	updateSizeRimItem,
	loadSizeRimsSuggestions,
	clearSizeRimsSuggestions,
} from "features/tireFinder/sizeRimsSlice";
import {
	clearWidthSaeValue,
	fetchWidthSaesIfNeeded,
	updateWidthSaeValue,
	updateWidthSaeItem,
	loadWidthSaesSuggestions,
	clearWidthSaesSuggestions,
} from "features/tireFinder/sizeSaeWidthsSlice";
import {
	clearSizeRimSaesSuggestions,
	clearSizeRimSaeValue,
	fetchSizeRimSaesIfNeeded,
	loadSizeRimSaesSuggestions,
	updateSizeRimSaeItem,
	updateSizeRimSaeValue,
} from "features/tireFinder/sizeSaeRimsSlice";
import { fetchSizeTiresListIfNeeded } from "features/tireResults/sizeTiresSlice";
import { hideHighlightsTiresList } from "features/tireResults/highlightsTiresSlice";
import { fetchSizeSaeTiresListIfNeeded } from "features/tireResults/sizeSaeTiresSlice";
import TireCanvas from "./TireCanvas";
import VehicleTypeSelector from "./VehicleTypeSelector";

const renderSuggestion = (suggestion) => {
	return <div>{suggestion.Value}</div>;
};
const getSuggestionValue = (suggestion) => {
	return suggestion.Value + "";
};

const scroll = Scroll.animateScroll;

export default function SizeFinder() {
	const dispatch = useDispatch();
	// Selectors
	const {
		vehicleType,
		sizeWidthDiameters,
		sizeRatios,
		sizeRims,
		sizeSaeWidths,
		sizeSaeRims,
	} = useSelector((state) => state);
	const tireList = useSelector((state) => state.sizeTires.list);
	const tireSaeList = useSelector((state) => state.sizeSaeTires.list);
	// Refs
	const ratioRef = useRef();
	const rimRef = useRef();
	const saeWidthRef = useRef();
	const saeRimRef = useRef();
	// Navigate
	const navigate = useNavigate();
	const location = useLocation();
	// Effect
	useEffect(() => {
		if (
			sizeRatios.renderInput &&
			sizeRatios.showInput &&
			sizeRatios.list != null &&
			tireList == null
		) {
			ratioRef.current.focus();
		}
		if (
			sizeRims.renderInput &&
			sizeRims.showInput &&
			sizeRims.list != null &&
			tireList == null
		) {
			rimRef.current.focus();
		}
		if (
			sizeSaeWidths.renderInput &&
			sizeSaeWidths.showInput &&
			sizeSaeWidths.list != null &&
			tireList == null
		) {
			saeWidthRef.current.focus();
		}
		if (
			sizeSaeRims.renderInput &&
			sizeSaeRims.showInput &&
			sizeSaeRims.list != null &&
			tireSaeList == null
		) {
			saeRimRef.current.focus();
		}
	}, [
		sizeRatios.showInput,
		sizeRatios.renderInput,
		sizeRatios.list,
		sizeRims.showInput,
		sizeRims.renderInput,
		sizeRims.list,
		sizeSaeWidths.showInput,
		sizeSaeWidths.renderInput,
		sizeSaeWidths.list,
		sizeSaeRims.showInput,
		sizeSaeRims.renderInput,
		sizeSaeRims.list,
		tireList,
		tireSaeList,
	]);
	// Handlers
	function handleLocationReset() {
		if (location !== "/") {
			navigate("/");
		}
	}
	function handleVehicleTypeChange(value) {
		dispatch(clearSearchActions());
		dispatch(setVehicleType({ value }));
		dispatch(fetchWidthDiametersIfNeeded(value));
	}
	function handleBlur(e) {
		dispatch(setCanvasFocus({ focus: null }));
	}
	// Width Diameter
	function handleWidthDiameterChange(e, { newValue, method }) {
		if (method === "type" || method === "enter" || method === "click") {
			dispatch(clearSizeWidthDiameterActions());
			dispatch(updateWidthDiameterValue({ value: newValue }));
			dispatch(updateWidthDiameterItem({ selectedItem: {} }));
			handleLocationReset();
		}
	}
	function handleWidthDiameterFocus(e) {
		e.target.select();
		dispatch(setCanvasFocus({ focus: 0 }));
	}
	function onWidthDiameterSelected(e, { suggestion }) {
		if (suggestion.IsSae) {
			dispatch(
				setCanvasFirstItem({ first: suggestion.Value + "", isSAE: true })
			);
			dispatch(fetchWidthSaesIfNeeded(suggestion));
			dispatch(clearWidthSaeValue({ renderInput: true, showInput: true }));
			dispatch(clearSizeRimSaeValue({ renderInput: false, showInput: true }));
			dispatch(clearSizeRatioValue({ renderInput: false, showInput: false }));
			dispatch(clearSizeRimValue({ renderInput: false, showInput: false }));
		} else {
			dispatch(
				setCanvasFirstItem({ first: suggestion.Value + "", isSAE: false })
			);
			dispatch(fetchSizeRatiosIfNeeded(suggestion));
			dispatch(clearSizeRatioValue({ renderInput: true, showInput: true }));
			dispatch(clearSizeRimValue({ renderInput: false, showInput: true }));
			dispatch(clearWidthSaeValue({ renderInput: false, showInput: false }));
			dispatch(clearSizeRimSaeValue({ renderInput: false, showInput: false }));
		}
		dispatch(updateWidthDiameterItem({ selectedItem: suggestion }));
	}
	function onWidthDiameterFetchRequested({ value }) {
		dispatch(loadWidthDiametersSuggestions(value));
	}
	function onWidthDiameterClearRequested() {
		dispatch(clearWidthDiametersSuggestions());
	}
	// Ratio
	function handleRatioChange(e, { newValue, method }) {
		if (method === "type" || method === "enter" || method === "click") {
			dispatch(clearSizeRatioActions());
			dispatch(updateSizeRatioValue({ value: newValue }));
			dispatch(updateSizeRatioItem({ selectedItem: {} }));
			handleLocationReset();
		}
	}
	function handleRatioFocus(e) {
		e.target.select();
		dispatch(setCanvasFocus({ focus: 1 }));
	}
	function onRatioSelected(e, { suggestion }) {
		dispatch(fetchSizeRimsIfNeeded(suggestion));
		dispatch(updateSizeRatioItem({ selectedItem: suggestion }));
		dispatch(setCanvasSecondItem({ second: suggestion.Value }));
	}
	function onRatioFetchRequested({ value }) {
		dispatch(loadSizeRatiosSuggestions(value));
	}
	function onRatioClearRequested() {
		dispatch(clearSizeRatiosSuggestions());
	}
	// Rim
	function handleRimChange(e, { newValue, method }) {
		if (method === "type" || method === "enter" || method === "click") {
			dispatch(clearSizeRimActions());
			dispatch(updateSizeRimValue({ value: newValue }));
			dispatch(updateSizeRimItem({ selectedItem: {} }));
			handleLocationReset();
		}
	}
	function handleRimFocus(e) {
		e.target.select();
		dispatch(setCanvasFocus({ focus: 2 }));
	}
	function onRimSelected(e, { suggestion }) {
		dispatch(fetchSizeTiresListIfNeeded(suggestion));
		dispatch(updateSizeRimItem({ selectedItem: suggestion }));
		dispatch(setCanvasThirdItem({ third: suggestion.Value }));
		dispatch(hideHighlightsTiresList({ hide: true }));
		navigate(
			"/llantas/medida/" +
				vt.properties[vehicleType.value].slug +
				"/" +
				sizeWidthDiameters.value +
				"/" +
				sizeRatios.value +
				"R" +
				suggestion.Value
		);
		setTimeout(() => {
			const target = document.getElementById("resultCarousel");
			const targetPos =
				target.offsetWidth > 980
					? target.offsetTop - 200
					: target.offsetTop - 150;
			scroll.scrollTo(targetPos, { smooth: true, delay: 100, duration: 500 });
		}, 500);
		rimRef.current.blur();
	}
	function onRimFetchRequested({ value }) {
		dispatch(loadSizeRimsSuggestions(value));
	}
	function onRimClearRequested() {
		dispatch(clearSizeRimsSuggestions());
	}
	// SAE Width
	function handleSaeWidthChange(e, { newValue, method }) {
		if (method === "type" || method === "enter" || method === "click") {
			dispatch(clearSizeSaeWidthActions());
			dispatch(updateWidthSaeValue({ value: newValue }));
			dispatch(updateWidthSaeItem({ selectedItem: {} }));
			handleLocationReset();
		}
	}
	function handleSaeWidthFocus(e) {
		e.target.select();
		dispatch(setCanvasFocus({ focus: 1 }));
	}
	function onSaeWidthSelected(e, { suggestion }) {
		dispatch(fetchSizeRimSaesIfNeeded(suggestion));
		dispatch(updateWidthSaeItem({ selectedItem: suggestion }));
		dispatch(setCanvasSecondItem({ second: suggestion.Value }));
	}
	function onSaeWidthFetchRequested({ value }) {
		dispatch(loadWidthSaesSuggestions(value));
	}
	function onSaeWidthClearRequested() {
		dispatch(clearWidthSaesSuggestions());
	}
	// SAE Rim
	function handleSaeRimChange(e, { newValue, method }) {
		if (method === "type" || method === "enter" || method === "click") {
			dispatch(clearSizeSaeRimActions());
			dispatch(updateSizeRimSaeValue({ value: newValue }));
			dispatch(updateSizeRimSaeItem({ selectedItem: {} }));
			handleLocationReset();
		}
	}
	function handleSaeRimFocus(e) {
		e.target.select();
		dispatch(setCanvasFocus({ focus: 2 }));
	}
	function onSaeRimSelected(e, { suggestion }) {
		dispatch(fetchSizeSaeTiresListIfNeeded(suggestion));
		dispatch(updateSizeRimSaeItem({ selectedItem: suggestion }));
		dispatch(setCanvasThirdItem({ third: suggestion.Value }));
		dispatch(hideHighlightsTiresList({ hide: true }));
		navigate(
			"/llantas/medida/" +
				vt.properties[vehicleType.value].slug +
				"/" +
				sizeWidthDiameters.value +
				"X" +
				sizeSaeWidths.value +
				"R" +
				suggestion.Value
		);
		setTimeout(() => {
			const target = document.getElementById("resultCarousel");
			const targetPos =
				target.offsetWidth > 980
					? target.offsetTop - 200
					: target.offsetTop - 150;
			scroll.scrollTo(targetPos, { smooth: true, delay: 100, duration: 500 });
		}, 500);
		saeRimRef.current.blur();
	}
	function onSaeRimFetchRequested({ value }) {
		dispatch(loadSizeRimSaesSuggestions(value));
	}
	function onSaeRimClearRequested() {
		dispatch(clearSizeRimSaesSuggestions());
	}
	if (vehicleType.value === "5") {
		return null;
	}
	// Input props
	const widthDiameterProps = {
		placeholder: "ANCHO DE SECCION",
		value: sizeWidthDiameters.value,
		onChange: handleWidthDiameterChange,
		onFocus: handleWidthDiameterFocus,
		onBlur: handleBlur,
		tabIndex: 201,
	};
	const ratioProps = {
		placeholder: "RELACION ASPECTO",
		value: sizeRatios.value,
		onChange: handleRatioChange,
		onFocus: handleRatioFocus,
		onBlur: handleBlur,
		tabIndex: 202,
		ref: ratioRef,
	};
	const rimProps = {
		placeholder: "RIN",
		value: sizeRims.value,
		onChange: handleRimChange,
		onFocus: handleRimFocus,
		onBlur: handleBlur,
		tabIndex: 203,
		ref: rimRef,
	};
	const saeWidthProps = {
		placeholder: "ANCHO DE SECCION",
		value: sizeSaeWidths.value,
		onChange: handleSaeWidthChange,
		onFocus: handleSaeWidthFocus,
		onBlur: handleBlur,
		tabIndex: 202,
		ref: saeWidthRef,
	};
	const saeRimProps = {
		placeholder: "ANCHO DE SECCION",
		value: sizeSaeRims.value,
		onChange: handleSaeRimChange,
		onFocus: handleSaeRimFocus,
		onBlur: handleBlur,
		tabIndex: 203,
		ref: saeRimRef,
	};
	// Render functions
	function renderRatio() {
		if (!sizeRatios.showInput) {
			return null;
		}
		if (sizeRatios.renderInput) {
			return (
				<div className="field">
					<div>
						<Autosuggest
							highlightFirstSuggestion
							suggestions={sizeRatios.suggestions}
							onSuggestionsFetchRequested={onRatioFetchRequested}
							onSuggestionsClearRequested={onRatioClearRequested}
							getSuggestionValue={getSuggestionValue}
							shouldRenderSuggestions={() => true}
							renderSuggestion={renderSuggestion}
							onSuggestionSelected={onRatioSelected}
							inputProps={ratioProps}
							id="sizeRatio"
						/>
					</div>
				</div>
			);
		}
		return (
			<div className="field disabled">
				<div className="vehiclefinder-empty">
					<div>RELACION ASPECTO</div>
				</div>
			</div>
		);
	}
	function renderRim() {
		if (!sizeRims.showInput) {
			return null;
		}
		if (sizeRims.renderInput) {
			return (
				<div className="field">
					<div>
						<Autosuggest
							highlightFirstSuggestion
							suggestions={sizeRims.suggestions}
							onSuggestionsFetchRequested={onRimFetchRequested}
							onSuggestionsClearRequested={onRimClearRequested}
							getSuggestionValue={getSuggestionValue}
							shouldRenderSuggestions={() => true}
							renderSuggestion={renderSuggestion}
							onSuggestionSelected={onRimSelected}
							inputProps={rimProps}
							id="sizeRim"
						/>
					</div>
				</div>
			);
		}
		return (
			<div className="field disabled">
				<div className="vehiclefinder-empty">
					<div>RIN</div>
				</div>
			</div>
		);
	}
	function renderSaeWidth() {
		if (!sizeSaeWidths.showInput) {
			return null;
		}
		if (sizeSaeWidths.renderInput) {
			return (
				<div className="field">
					<div>
						<Autosuggest
							highlightFirstSuggestion
							suggestions={sizeSaeWidths.suggestions}
							onSuggestionsFetchRequested={onSaeWidthFetchRequested}
							onSuggestionsClearRequested={onSaeWidthClearRequested}
							getSuggestionValue={getSuggestionValue}
							shouldRenderSuggestions={() => true}
							renderSuggestion={renderSuggestion}
							onSuggestionSelected={onSaeWidthSelected}
							inputProps={saeWidthProps}
							id="saeWidth"
						/>
					</div>
				</div>
			);
		}
		return (
			<div className="field disabled">
				<div className="vehiclefinder-empty">
					<div>ANCHO DE SECCION</div>
				</div>
			</div>
		);
	}
	function renderSaeRim() {
		if (!sizeSaeRims.showInput) {
			return null;
		}
		if (sizeSaeRims.renderInput) {
			return (
				<div className="field">
					<div>
						<Autosuggest
							highlightFirstSuggestion
							suggestions={sizeSaeRims.suggestions}
							onSuggestionsFetchRequested={onSaeRimFetchRequested}
							onSuggestionsClearRequested={onSaeRimClearRequested}
							getSuggestionValue={getSuggestionValue}
							shouldRenderSuggestions={() => true}
							renderSuggestion={renderSuggestion}
							onSuggestionSelected={onSaeRimSelected}
							inputProps={saeRimProps}
							id="sizeSaeRim"
						/>
					</div>
				</div>
			);
		} else {
			return (
				<div className="field disabled">
					<div className="vehiclefinder-empty">
						<div>RIN</div>
					</div>
				</div>
			);
		}
	}
	// Render
	return (
		<div id="measure-finder">
			<VehicleTypeSelector
				onChange={handleVehicleTypeChange}
				vehicleType={vehicleType}
			/>
			<div>
				<div className="field">
					<div>
						<Autosuggest
							highlightFirstSuggestion
							suggestions={sizeWidthDiameters.suggestions}
							onSuggestionsFetchRequested={onWidthDiameterFetchRequested}
							onSuggestionsClearRequested={onWidthDiameterClearRequested}
							getSuggestionValue={getSuggestionValue}
							renderSuggestion={renderSuggestion}
							onSuggestionSelected={onWidthDiameterSelected}
							inputProps={widthDiameterProps}
							id="widthDiameter"
						/>
					</div>
				</div>
				{renderRatio()}
				{renderRim()}
				{renderSaeWidth()}
				{renderSaeRim()}
			</div>
			<TireCanvas />
		</div>
	);
}
