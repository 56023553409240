import { createSlice } from "@reduxjs/toolkit";
import ReactGA from "react-ga4";
import { BASE_API_URL } from "../constants";
import { getMatchingItemsTireSize } from "features/functions";
import { sessionService } from "redux-react-session";
import axios from "axios";
import { runUnauthorizedActions } from "features/account/loginSlice";

const initialState = {
	isLoading: false,
	value: "",
	selectedItem: {},
	suggestions: [],
	list: null,
	renderInput: false,
	showInput: true,
};

const sizeRatios = createSlice({
	name: "sizeRatios",
	initialState,
	reducers: {
		requestSizeRatiosList(state) {
			state.isLoading = true;
		},
		receiveSizeRatiosList(state, action) {
			state.isLoading = false;
			state.list = action.payload.list;
			state.renderInput = action.payload.renderInput;
		},
		loadSizeRatiosSuggestionsBegin(state) {
			state.isLoading = true;
		},
		maybeUpdateSizeRatiosSuggestions(state, action) {
			state.isLoading = false;
			if (state.value === action.payload.value) {
				state.suggestions = action.payload.suggestions;
			}
		},
		updateSizeRatioValue(state, action) {
			state.value = action.payload.value;
		},
		updateSizeRatioItem(state, action) {
			const selectedItem = action.payload.selectedItem;
			if (selectedItem?.Value != null) {
				ReactGA.event({
					category: "Medida",
					action: "Seleccionar RelaciónAspecto",
					label: selectedItem.Value + "",
					value: selectedItem.Value,
				});
			}
			state.selectedItem = selectedItem;
		},
		clearSizeRatiosSuggestions(state) {
			state.suggestions = [];
		},
		clearSizeRatioValue(state, action) {
			state.value = "";
			state.selectedItem = {};
			state.list = null;
			state.renderInput = action.payload.renderInput;
			state.showInput = action.payload.showInput;
		},
		clearSizeRatiosData: () => initialState,
	},
});

export const {
	requestSizeRatiosList,
	receiveSizeRatiosList,
	loadSizeRatiosSuggestionsBegin,
	maybeUpdateSizeRatiosSuggestions,
	updateSizeRatioValue,
	updateSizeRatioItem,
	clearSizeRatiosSuggestions,
	clearSizeRatioValue,
	clearSizeRatiosData,
} = sizeRatios.actions;

const shouldFetchSizeRatios = (state, newWidth) => {
	if (state.sizeRatios.list == null) {
		return true;
	}
	const oldWidth = state.sizeWidthDiameters.selectedItem;
	if (oldWidth !== newWidth) {
		return true;
	}
	return false;
};
export const fetchSizeRatiosIfNeeded =
	(selectedWidth) => async (dispatch, getState) => {
		if (shouldFetchSizeRatios(getState(), selectedWidth)) {
			const theState = getState();
			return dispatch(
				fetchSizeRatios(theState.vehicleType.value, selectedWidth.OriginalValue)
			);
		}
	};
export const loadSizeRatiosSuggestions =
	(value) => async (dispatch, getState) => {
		dispatch(loadSizeRatiosSuggestionsBegin());
		let theState = getState();
		if (theState.sizeRatios.list == null) {
			dispatch(
				fetchSizeRatios(
					theState.vehicleType.value,
					theState.sizeWidthDiameters.selectedItem.OriginalValue
				)
			);
		}
		theState = getState();
		return setTimeout(() => {
			dispatch(
				maybeUpdateSizeRatiosSuggestions({
					suggestions: getMatchingItemsTireSize(theState.sizeRatios, value),
					value,
				})
			);
		});
	};
export const fetchSizeRatios = (dept, width) => async (dispatch) => {
	if (width == null) return;
	sessionService
		.loadSession()
		.then((session) => {
			const headers = {
				Authorization: "bearer " + session.loginData.access_token,
				"Content-Type": "application/json",
			};
			const options = {
				headers: headers,
			};
			dispatch(requestSizeRatiosList());
			const url =
				BASE_API_URL +
				"/api/Empleados/Medidas/Serie/?depto=" +
				dept +
				"&marca=0&anchoseccion=" +
				width;
			return axios
				.get(url, options)
				.then((response) => {
					dispatch(
						receiveSizeRatiosList({ list: response.data, renderInput: true })
					);
				})
				.catch((error) => {
					// If 401 Unauthorized login failed
					if (error.response.status === 401) {
						dispatch(runUnauthorizedActions());
					}
				});
		})
		.catch((err) => console.log("Error", err));
};

export default sizeRatios.reducer;
