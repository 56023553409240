import { createSlice } from "@reduxjs/toolkit";
import { sessionService } from "redux-react-session";
import ReactGA from "react-ga4";
import { BASE_API_URL } from "../constants";
import {
	getMatchingSearchItems,
	getMatchingItemById,
} from "features/functions";
import { runUnauthorizedActions } from "features/account/loginSlice";
import {
	clearSearchResultData,
	fetchSearchResultsTireListIfNeeded,
	hideSearchResultsList,
} from "features/tireResults/searchTiresSlice";
import { hideHighlightsTiresList } from "features/tireResults/highlightsTiresSlice";
import { clearVehicleTiresData } from "features/tireResults/vehicleTiresSlice";
import { clearVehicleBrandValue } from "./vehicleBrandsSlice";
import { clearVehicleModelValue } from "./vehicleModelsSlice";
import { clearVehicleYearValue } from "./vehicleYearsSlice";
import { clearVehicleVersionValue } from "./vehicleVersionsSlice";
import { clearWidthDiameterValue } from "./sizeWidthDiametersSlice";
import { clearSizeRatioValue } from "./sizeRatiosSlice";
import { clearSizeRimValue } from "./sizeRimsSlice";
import { clearWidthSaeValue } from "./sizeSaeWidthsSlice";
import { clearSizeRimSaeValue } from "./sizeSaeRimsSlice";
import { clearSizeTiresList } from "features/tireResults/sizeTiresSlice";
import { clearSizeSaeTiresList } from "features/tireResults/sizeSaeTiresSlice";
import {
	setCanvasFirstItem,
	setCanvasSecondItem,
	setCanvasThirdItem,
} from "features/tireFinder/sizeCanvasSlice";
import axios from "axios";
import { clearCodeTiresData } from "features/tireResults/codeTiresSlice";
import { clearSizeShortData } from "./sizeShortSlice";

const initialState = {
	isLoading: false,
	value: "",
	selectedItem: {},
	suggestions: [],
	list: null,
	showField: false,
};

const search = createSlice({
	name: "search",
	initialState,
	reducers: {
		requestSearchList: (state) => {
			state.isLoading = true;
		},
		receiveSearchList: (state, action) => {
			state.isLoading = false;
			state.list = action.payload.list;
		},
		loadSearchSuggestionsBegin: (state) => {
			state.isLoading = true;
		},
		maybeUpdateSearchSuggestions: (state, action) => {
			state.isLoading = false;
			if (action.payload.update) {
				state.suggestions = action.payload.suggestions;
			}
		},
		updateSearchValue: (state, action) => {
			state.value = action.payload.value;
		},
		updateSearchItem: (state, action) => {
			const selectedItem = action.payload.selectedItem;
			if (selectedItem?.Value != null) {
				ReactGA.event({
					category: "Producto",
					action: "Buscar",
					label: selectedItem.Name,
				});
			}
			state.selectedItem = selectedItem;
		},
		clearSearchSuggestions: (state) => {
			state.suggestions = [];
		},
		clearSearchValue: (state) => {
			state.value = "";
			state.selectedItem = {};
		},
		toggleShowSearchField: (state, action) => {
			state.showField = !action.payload.showField;
		},
		clearSearchData: () => initialState,
	},
});

export const {
	requestSearchList,
	receiveSearchList,
	loadSearchSuggestionsBegin,
	maybeUpdateSearchSuggestions,
	updateSearchValue,
	updateSearchItem,
	clearSearchSuggestions,
	clearSearchValue,
	toggleShowSearchField,
	clearSearchData,
} = search.actions;

export const clearSearchActions = () => (dispatch) => {
	dispatch(clearSearchValue());
	dispatch(hideSearchResultsList({ hide: true }));
	dispatch(clearSearchResultData());
	dispatch(setCanvasFirstItem({ first: null, isSAE: false }));
	dispatch(toggleShowSearchField({ showField: true }));
	dispatch(clearVehicleTiresData());
	dispatch(hideHighlightsTiresList({ hide: false }));
	dispatch(clearVehicleBrandValue());
	dispatch(clearVehicleModelValue({ renderInput: false }));
	dispatch(clearVehicleYearValue({ renderInput: false }));
	dispatch(clearVehicleVersionValue({ renderInput: false }));
	dispatch(clearWidthDiameterValue());
	dispatch(clearSizeRatioValue({ renderInput: false, showInput: true }));
	dispatch(clearSizeRimValue({ renderInput: false, showInput: true }));
	dispatch(clearWidthSaeValue({ renderInput: false, showInput: false }));
	dispatch(clearSizeRimSaeValue({ renderInput: false, showInput: false }));
	dispatch(clearSizeTiresList());
	dispatch(clearSizeSaeTiresList());
	dispatch(clearCodeTiresData());
	// dispatch(clearSizeShortData());
};
export const clearVehicleBrandActions = () => (dispatch) => {
	dispatch(hideSearchResultsList({ hide: true }));
	dispatch(clearSearchResultData());
	dispatch(setCanvasFirstItem({ first: null, isSAE: false }));
	dispatch(toggleShowSearchField({ showField: true }));
	dispatch(clearVehicleTiresData());
	dispatch(hideHighlightsTiresList({ hide: false }));
	dispatch(clearVehicleModelValue({ renderInput: false }));
	dispatch(clearVehicleYearValue({ renderInput: false }));
	dispatch(clearVehicleVersionValue({ renderInput: false }));
	dispatch(clearWidthDiameterValue());
	dispatch(clearSizeRatioValue({ renderInput: false, showInput: true }));
	dispatch(clearSizeRimValue({ renderInput: false, showInput: true }));
	dispatch(clearWidthSaeValue({ renderInput: false, showInput: false }));
	dispatch(clearSizeRimSaeValue({ renderInput: false, showInput: false }));
	dispatch(clearSizeTiresList());
	dispatch(clearSizeSaeTiresList());
	dispatch(clearCodeTiresData());
};
export const clearVehicleModelActions = () => (dispatch) => {
	dispatch(hideSearchResultsList({ hide: true }));
	dispatch(clearSearchResultData());
	dispatch(setCanvasFirstItem({ first: null, isSAE: false }));
	dispatch(toggleShowSearchField({ showField: true }));
	dispatch(clearVehicleTiresData());
	dispatch(hideHighlightsTiresList({ hide: false }));
	dispatch(clearVehicleYearValue({ renderInput: false }));
	dispatch(clearVehicleVersionValue({ renderInput: false }));
	dispatch(clearWidthDiameterValue());
	dispatch(clearSizeRatioValue({ renderInput: false, showInput: true }));
	dispatch(clearSizeRimValue({ renderInput: false, showInput: true }));
	dispatch(clearWidthSaeValue({ renderInput: false, showInput: false }));
	dispatch(clearSizeRimSaeValue({ renderInput: false, showInput: false }));
	dispatch(clearSizeTiresList());
	dispatch(clearSizeSaeTiresList());
	dispatch(clearCodeTiresData());
};
export const clearVehicleYearActions = () => (dispatch) => {
	dispatch(hideSearchResultsList({ hide: true }));
	dispatch(clearSearchResultData());
	dispatch(setCanvasFirstItem({ first: null, isSAE: false }));
	dispatch(toggleShowSearchField({ showField: true }));
	dispatch(clearVehicleTiresData());
	dispatch(hideHighlightsTiresList({ hide: false }));
	dispatch(clearVehicleVersionValue({ renderInput: false }));
	dispatch(clearWidthDiameterValue());
	dispatch(clearSizeRatioValue({ renderInput: false, showInput: true }));
	dispatch(clearSizeRimValue({ renderInput: false, showInput: true }));
	dispatch(clearWidthSaeValue({ renderInput: false, showInput: false }));
	dispatch(clearSizeRimSaeValue({ renderInput: false, showInput: false }));
	dispatch(clearSizeTiresList());
	dispatch(clearSizeSaeTiresList());
	dispatch(clearCodeTiresData());
};
export const clearVehicleVersionActions = () => (dispatch) => {
	dispatch(hideSearchResultsList({ hide: true }));
	dispatch(clearSearchResultData());
	dispatch(setCanvasFirstItem({ first: null, isSAE: false }));
	dispatch(toggleShowSearchField({ showField: true }));
	dispatch(clearVehicleTiresData());
	dispatch(hideHighlightsTiresList({ hide: false }));
	dispatch(clearWidthDiameterValue());
	dispatch(clearSizeRatioValue({ renderInput: false, showInput: true }));
	dispatch(clearSizeRimValue({ renderInput: false, showInput: true }));
	dispatch(clearWidthSaeValue({ renderInput: false, showInput: false }));
	dispatch(clearSizeRimSaeValue({ renderInput: false, showInput: false }));
	dispatch(clearSizeTiresList());
	dispatch(clearSizeSaeTiresList());
	dispatch(clearCodeTiresData());
};
export const clearSizeWidthDiameterActions = () => (dispatch) => {
	dispatch(clearSearchValue());
	dispatch(hideSearchResultsList({ hide: true }));
	dispatch(clearSearchResultData());
	dispatch(setCanvasSecondItem({ second: null }));
	dispatch(setCanvasThirdItem({ third: null }));
	dispatch(toggleShowSearchField({ showField: true }));
	dispatch(clearVehicleTiresData());
	dispatch(hideHighlightsTiresList({ hide: false }));
	dispatch(clearVehicleBrandValue());
	dispatch(clearVehicleModelValue({ renderInput: false }));
	dispatch(clearVehicleYearValue({ renderInput: false }));
	dispatch(clearVehicleVersionValue({ renderInput: false }));
	dispatch(clearSizeRatioValue({ renderInput: false, showInput: true }));
	dispatch(clearSizeRimValue({ renderInput: false, showInput: true }));
	dispatch(clearWidthSaeValue({ renderInput: false, showInput: false }));
	dispatch(clearSizeRimSaeValue({ renderInput: false, showInput: false }));
	dispatch(clearSizeTiresList());
	dispatch(clearSizeSaeTiresList());
	dispatch(clearCodeTiresData());
};
export const clearSizeRatioActions = () => (dispatch) => {
	dispatch(clearSearchValue());
	dispatch(hideSearchResultsList({ hide: true }));
	dispatch(clearSearchResultData());
	dispatch(setCanvasThirdItem({ third: null }));
	dispatch(toggleShowSearchField({ showField: true }));
	dispatch(clearVehicleTiresData());
	dispatch(hideHighlightsTiresList({ hide: false }));
	dispatch(clearVehicleBrandValue());
	dispatch(clearVehicleModelValue({ renderInput: false }));
	dispatch(clearVehicleYearValue({ renderInput: false }));
	dispatch(clearVehicleVersionValue({ renderInput: false }));
	dispatch(clearSizeRimValue({ renderInput: false, showInput: true }));
	dispatch(clearWidthSaeValue({ renderInput: false, showInput: false }));
	dispatch(clearSizeRimSaeValue({ renderInput: false, showInput: false }));
	dispatch(clearSizeTiresList());
	dispatch(clearSizeSaeTiresList());
	dispatch(clearCodeTiresData());
};
export const clearSizeRimActions = () => (dispatch) => {
	dispatch(clearSearchValue());
	dispatch(hideSearchResultsList({ hide: true }));
	dispatch(clearSearchResultData());
	dispatch(setCanvasThirdItem({ third: null }));
	dispatch(toggleShowSearchField({ showField: true }));
	dispatch(clearVehicleTiresData());
	dispatch(hideHighlightsTiresList({ hide: false }));
	dispatch(clearVehicleBrandValue());
	dispatch(clearVehicleModelValue({ renderInput: false }));
	dispatch(clearVehicleYearValue({ renderInput: false }));
	dispatch(clearVehicleVersionValue({ renderInput: false }));
	dispatch(clearWidthSaeValue({ renderInput: false, showInput: false }));
	dispatch(clearSizeRimSaeValue({ renderInput: false, showInput: false }));
	dispatch(clearSizeTiresList());
	dispatch(clearSizeSaeTiresList());
	dispatch(clearCodeTiresData());
};
export const clearSizeSaeWidthActions = () => (dispatch) => {
	dispatch(clearSearchValue());
	dispatch(hideSearchResultsList({ hide: true }));
	dispatch(clearSearchResultData());
	dispatch(setCanvasThirdItem({ third: null }));
	dispatch(toggleShowSearchField({ showField: true }));
	dispatch(clearVehicleTiresData());
	dispatch(hideHighlightsTiresList({ hide: false }));
	dispatch(clearVehicleBrandValue());
	dispatch(clearVehicleModelValue({ renderInput: false }));
	dispatch(clearVehicleYearValue({ renderInput: false }));
	dispatch(clearVehicleVersionValue({ renderInput: false }));
	dispatch(clearSizeRimValue({ renderInput: false, showInput: false }));
	dispatch(clearSizeRatioValue({ renderInput: false, showInput: false }));
	dispatch(clearSizeRimSaeValue({ renderInput: false, showInput: true }));
	dispatch(clearSizeTiresList());
	dispatch(clearSizeSaeTiresList());
	dispatch(clearCodeTiresData());
};
export const clearSizeSaeRimActions = () => (dispatch) => {
	dispatch(clearSearchValue());
	dispatch(hideSearchResultsList({ hide: true }));
	dispatch(clearSearchResultData());
	dispatch(setCanvasThirdItem({ third: null }));
	dispatch(toggleShowSearchField({ showField: true }));
	dispatch(clearVehicleTiresData());
	dispatch(hideHighlightsTiresList({ hide: false }));
	dispatch(clearVehicleBrandValue());
	dispatch(clearVehicleModelValue({ renderInput: false }));
	dispatch(clearVehicleYearValue({ renderInput: false }));
	dispatch(clearVehicleVersionValue({ renderInput: false }));
	dispatch(clearSizeRatioValue({ renderInput: false, showInput: false }));
	dispatch(clearSizeRimValue({ renderInput: false, showInput: false }));
	dispatch(clearSizeTiresList());
	dispatch(clearSizeSaeTiresList());
	dispatch(clearCodeTiresData());
};
const shouldFetchSearch = (state) => {
	if (state.search.list == null) {
		return true;
	}
	return false;
};
export const fetchSearchIfNeeded = () => async (dispatch, getState) => {
	const theState = getState();
	if (shouldFetchSearch(theState)) {
		return dispatch(fetchSearch());
	}
};
export const loadSearchSuggestions = (value) => async (dispatch, getState) => {
	dispatch(loadSearchSuggestionsBegin());
	dispatch(fetchSearchIfNeeded());
	const theState = getState();
	const update = theState.search.value === value;
	setTimeout(() => {
		dispatch(
			maybeUpdateSearchSuggestions({
				suggestions: getMatchingSearchItems(theState.search, value),
				update,
			})
		);
	});
};

export const updateSearchResultTiresFromURL =
	(productId) => async (dispatch, getState) => {
		const theState = getState();
		if (theState.search.list != null) {
			// filtrar el item por id
			const item = getMatchingItemById(theState.search, productId);
			if (item != null) {
				dispatch(updateSearchItem({ selectedItem: item }));
				dispatch(fetchSearchResultsTireListIfNeeded());
			}
		}
	};

export const fetchSearch = (productId) => async (dispatch) => {
	sessionService
		.loadSession()
		.then((session) => {
			const headers = {
				Authorization: "bearer " + session.loginData.access_token,
				"Content-Type": "application/json",
			};
			const options = {
				headers: headers,
			};
			dispatch(requestSearchList());
			const url = BASE_API_URL + "/api/Empleados/Productos";
			return axios
				.get(url, options)
				.then((response) => {
					dispatch(receiveSearchList({ list: response.data }));
					if (productId != null) {
						dispatch(updateSearchResultTiresFromURL(productId));
					}
				})
				.catch((error) => {
					// If 401 Unauthorized login failed
					if (error.response.status === 401) {
						dispatch(runUnauthorizedActions());
					}
				});
		})
		.catch((err) => console.log("Error", err));
};

export default search.reducer;
