import AccountCardDelete from "components/account/AccountCardDelete";
import AccountCardEdit from "components/account/AccountCardEdit";
import AccountCards from "components/account/AccountCards";
import AccountOrders from "components/account/AccountOrders";
import OrderModal from "components/store/orders/OrderModal";
import AlertModal from "components/alert/AlertModal";
import CartModal from "components/cart/CartModal";
import OpenPayPointsModal from "components/cart/OpenPayPointsModal";
import React from "react";
import DealerServices from "./DealerServices";
import FamilyModal from "./FamilyModal";
import MainMenu from "./MainMenu";
import OrderConfirmationModal from "./orders/OrderConfirmationModal";
import StoreContent from "./StoreContent";
import TermsModal from "./TermsModal";

export default function Store() {
	return (
		<div>
			<div id="logo-conti-top">
				<span></span>
			</div>
			<MainMenu />
			<div id="outer-frame">
				<div id="main-container"></div>
			</div>
			<StoreContent />
			<CartModal />
			<OrderConfirmationModal />
			<AccountOrders />
			<AccountCards />
			<AccountCardEdit />
			<AccountCardDelete />
			<OpenPayPointsModal />
			<OrderModal />
			<FamilyModal />
			<TermsModal />
			<DealerServices />
			<AlertModal />
		</div>
	);
}
