import React from "react";

export default function VehicleTypeSelector({ onChange, vehicleType }) {
	// Handlers
	function handleChange(e) {
		onChange(e.target.value);
	}
	return (
		<>
			<h3>¿Cuál es tu medida?</h3>
			<div className="vehicle-types">
				<fieldset>
					<ul>
						<li>
							<input
								onChange={handleChange}
								type="radio"
								value="0"
								name="vehicle-type"
								id="vehicle-type-car"
								checked={vehicleType?.value === "0"}
							/>
							<label htmlFor="vehicle-type-car">
								<span className="icon-car"></span>
								<span className="label-text">Auto / Camioneta</span>
							</label>
						</li>
						<li>
							<input
								onChange={handleChange}
								type="radio"
								value="5"
								name="vehicle-type"
								id="vehicle-type-truck"
								checked={vehicleType?.value === "5"}
							/>
							<label htmlFor="vehicle-type-truck">
								<span className="icon-truck"></span>
								<span className="label-text">Camión</span>
							</label>
						</li>
					</ul>
				</fieldset>
			</div>
		</>
	);
}
