import { toggleShowDealers } from "features/account/footerSlice";
import React from "react";
import ReactModal from "react-modal";
import { useDispatch, useSelector } from "react-redux";

export default function DealerServices() {
	const dispatch = useDispatch();
	// Selectors
	const { isOpenDealers } = useSelector((state) => state.footer);
	// Handlers
	function handleCloseClick() {
		dispatch(toggleShowDealers({ isOpenDealers: true }));
	}
	// Render
	return (
		<div>
			<ReactModal
				isOpen={isOpenDealers}
				className="modal full"
				overlayClassName="modal-overlay full"
				onRequestClose={handleCloseClick}
				contentLabel="Registro"
			>
				<div className="modal-inner" id="legal-inner">
					<div className="modal-close" onClick={handleCloseClick}></div>
					<h2>Servicios Complementarios</h2>
					<h3>Aguascalientes</h3>
					<ul>
						<li>
							<strong>LLANTAMAN MATRIZ</strong>
							<br />
							José Maria Chavez 1215
							<br />
							Col. Linda Vista
							<br />
							20270 <strong>Aguascalientes, Aguascalientes</strong>
						</li>
					</ul>
					<h3>Guanajuato</h3>
					<ul>
						<li>
							<strong>JASMAN IRAPUATO</strong>
							<br />
							Blvd. Diaz Ordaz S/N
							<br />
							Col. Centro
							<br />
							36500 <strong>Irapuato, Guanajuato</strong>
						</li>
					</ul>
					<ul>
						<li>
							<strong>JASMAN LEON</strong>
							<br />
							Blvd. Jose Maria Morelos 3631 Bis
							<br />
							Col. Industrial Santa Julia De Jerez
							<br />
							37290 <strong>Leon, Guanajuato</strong>
						</li>
					</ul>
					<h3>JALISCO</h3>
					<ul>
						<li>
							<strong>JASMAN LÓPEZ MATEOS</strong>
							<br />
							Av. López Mateos Sur 682
							<br />
							Col. Los Arcos
							<br />
							44500 <strong>Guadalajara, Jalisco</strong>
						</li>
					</ul>
					<ul>
						<li>
							<strong>JASMAN PATRIA</strong>
							<br />
							Av. Patria 613
							<br />
							Col. Jardines de Guadalupe
							<br />
							45030 <strong>Zapopan, Jalisco</strong>
						</li>
					</ul>
					<h3>Estado de México</h3>
					<ul>
						<li>
							<strong>JASMAN INTERLOMAS</strong>
							<br />
							Circuito Empresarial 4
							<br />
							Col. Huixquilucan
							<br />
							52760 <strong>Huixquilucan, México</strong>
						</li>
					</ul>
					<ul>
						<li>
							<strong>JASMAN LOMAS VERDES</strong>
							<br />
							Av. Lomas Verdes 407
							<br />
							Col. Lomas Verdes
							<br />
							53120 <strong>Naucalpan, México</strong>
						</li>
					</ul>
					<h3>Morelos</h3>
					<ul>
						<li>
							<strong>JASMAN CUAUTLA</strong>
							<br />
							San Martin 45
							<br />
							Col. Centro
							<br />
							62740 <strong>Cuautla, Morelos</strong>
						</li>
					</ul>
					<h3>Nuevo León</h3>
					<ul>
						<li>
							<strong>JASMAN UNIVERSIDAD</strong>
							<br />
							Ave. Alfonso Reyes 3339
							<br />
							Col. Regina
							<br />
							64290 <strong>Monterrey, Nuevo León</strong>
						</li>
					</ul>
					<h3>Querétaro</h3>
					<ul>
						<li>
							<strong>JASMAN QUERETARO</strong>
							<br />
							Ezequiel Montes Sur No. 164
							<br />
							Col. El Carrizal
							<br />
							76130 <strong>Querétaro, Querétaro</strong>
						</li>
					</ul>
					<h3>San Luis Potosí</h3>
					<ul>
						<li>
							<strong>TOCOLLANTA MATRIZ</strong>
							<br />
							Av. Muñoz 790
							<br />
							Col. Los Reyes
							<br />
							78170 <strong>San Luis Potosí, San Luis Potosí</strong>
						</li>
					</ul>
				</div>
			</ReactModal>
		</div>
	);
}
