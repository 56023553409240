import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import ReactGA from "react-ga4";
import { setAlert, toggleShowAlert } from "features/alert/alertSlice";
import { BASE_API_URL } from "features/constants";
import { sessionService } from "redux-react-session";
import { runUnauthorizedActions } from "./loginSlice";

const initialState = {
	isLoading: false,
	isOpen: false,
};

const familyFriends = createSlice({
	name: "familyFriends",
	initialState,
	reducers: {
		requestFamily: (state) => {
			state.isLoading = true;
		},
		completeFamily: (state) => {
			state.isLoading = false;
		},
		toggleShowFamily: (state, action) => {
			state.isOpen = !action.payload.isOpen;
		},
	},
});

export const { requestFamily, completeFamily, toggleShowFamily } =
	familyFriends.actions;

export const fetchFamily = () => async (dispatch) => {
	sessionService
		.loadSession()
		.then((session) => {
			const headers = {
				Authorization: "bearer " + session.loginData.access_token,
				"Content-Type": "application/json",
			};
			const options = {
				headers: headers,
			};
			dispatch(requestFamily());
			const url = BASE_API_URL + "/api/Empleados/FamilyFriends/SolicitarCupon";
			return axios
				.post(url, JSON.stringify({}), options)
				.then((response) => {
					ReactGA.event({
						category: "Usuario",
						action: "Family Friends",
					});
					const link = document.createElement("a");
					const url =
						"data:application/octec-stream;charset=utf-8;base64," +
						response.data;
					link.href = url;
					link.download = "CuponFamilyFriends.pdf";
					link.click();
					dispatch(
						setAlert({
							title: "¡Excelente!",
							description: "Se ha generado el cupón Family & Friends.",
							details: null,
						})
					);
					dispatch(toggleShowFamily({ isOpen: true }));
					dispatch(toggleShowAlert({ isOpen: false }));
					dispatch(completeFamily());
				})
				.catch((error) => {
					ReactGA.event({
						category: "Usuario",
						action: "Error Family Friends",
					});
					dispatch(completeFamily());
					// If 401 Unauthorized login failed
					if (error.response.status === 401) {
						dispatch(runUnauthorizedActions());
					}
					// 400 Bad Request
					else if (error.response.status === 400) {
						dispatch(
							setAlert({
								title: "¡Lo sentimos!",
								description:
									"No se ha podido generar el cupón por el siguiente motivo.",
								details: error.response.data.Message,
							})
						);
						dispatch(toggleShowFamily({ isOpen: true }));
						dispatch(toggleShowAlert({ isOpen: false }));
					} else {
						dispatch(
							setAlert({
								title: "¡Lo sentimos!",
								description: "No se ha podido generar el cupón.",
							})
						);
						dispatch(toggleShowFamily({ isOpen: true }));
						dispatch(toggleShowAlert({ isOpen: false }));
					}
				});
		})
		.catch((err) => console.log("Error", err));
};

export default familyFriends.reducer;
