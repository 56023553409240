import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchLocationListIfNeeded } from "features/account/locationsSlice";
import { fetchSearch } from "features/tireFinder/searchSlice";
import { hideHighlightsTiresList } from "features/tireResults/highlightsTiresSlice";
import {
	setOrderConfirmationId,
	fetchOrderConfirmation,
	toggleShowOrderConfirmation,
} from "features/orders/orderConfirmationSlice";
import Home from "./home/Home";
import Store from "./store/Store";
import { fetchCodeTiresListIfNeeded } from "features/tireResults/codeTiresSlice";
import { useParams } from "react-router-dom";
import { fetchSizeStringTireList } from "features/tireResults/sizeTiresSlice";
import { fetchSizeSaeStringTireList } from "features/tireResults/sizeSaeTiresSlice";
import { fetchAccount } from "features/account/accountSlice";
import { setCanvasFirstItem } from "features/tireFinder/sizeCanvasSlice";
import { updateSizeShortValue } from "features/tireFinder/sizeShortSlice";

function App() {
	const dispatch = useDispatch();
	// Selectors
	const { authenticated, checked } = useSelector((state) => state.session);
	const params = useParams();
	// Effect
	useEffect(() => {
		if (checked) {
			dispatch(fetchLocationListIfNeeded());
			if (authenticated === true) {
				dispatch(fetchAccount());
				if (params != null) {
					const {
						productcode,
						searchtype,
						vehicletype,
						s1,
						s2,
						orderId,
						productId,
					} = params;
					if (productId != null) {
						dispatch(fetchSearch(params.productId));
						dispatch(hideHighlightsTiresList({ hide: true }));
					}
					if (productcode != null) {
						dispatch(fetchCodeTiresListIfNeeded(params.productcode));
						dispatch(hideHighlightsTiresList({ hide: true }));
					}
					if (orderId != null) {
						dispatch(setOrderConfirmationId({ orderId: params.orderId }));
						dispatch(toggleShowOrderConfirmation({ isOpen: false }));
						dispatch(fetchOrderConfirmation());
					}
					if (searchtype != null && searchtype === "medida") {
						if (s2 != null) {
							dispatch(fetchSizeStringTireList(vehicletype, `${s1}/${s2}`));
							// dispatch(setCanvasFirstItem({ first: `${s1}/${s2}` }));
						} else {
							dispatch(fetchSizeSaeStringTireList(vehicletype, s1));
							// dispatch(setCanvasFirstItem({ first: s1 }));
						}
					}
				}
			}
		}
	}, [params, checked, authenticated, dispatch]);
	if (authenticated) {
		return <Store />;
	}
	return <Home />;
}
export default App;
