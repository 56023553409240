import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { setCartShipAddress, setCartShipType } from "features/cart/cartSlice";
import { BASE_API_URL, SHIP_TYPES } from "features/constants";
import { sessionService } from "redux-react-session";
import { runUnauthorizedActions } from "./loginSlice";

const initialState = {
	isLoading: false,
	data: null,
	selectedId: "",
};

const shipLocations = createSlice({
	name: "shipLocations",
	initialState,
	reducers: {
		requestShipLocationData: (state) => {
			state.isLoading = true;
		},
		receiveShipLocationData: (state, action) => {
			state.isLoading = false;
			state.data = action.payload.data;
		},
		setShipLocationId: (state, action) => {
			state.selectedId = action.payload.selectedId;
		},
		clearShipLocationData: () => initialState,
	},
});

export const {
	requestShipLocationData,
	receiveShipLocationData,
	setShipLocationId,
	clearShipLocationData,
} = shipLocations.actions;

export const fetchShipLocationData = () => async (dispatch) => {
	sessionService
		.loadSession()
		.then((session) => {
			const headers = {
				Authorization: "bearer " + session.loginData.access_token,
				"Content-Type": "application/json",
			};
			const options = {
				headers: headers,
			};
			const url = BASE_API_URL + "/api/Cuenta/Localidades";
			dispatch(requestShipLocationData());
			return axios
				.get(url, options)
				.then((response) => {
					dispatch(receiveShipLocationData({ data: response.data }));
					if (
						response.data.ShipHR === false &&
						response.data.ShipDealer === true
					) {
						dispatch(setCartShipType({ shipType: SHIP_TYPES.DEALER }));
						dispatch(
							setCartShipAddress({ shipAddress: null, shipAddressItem: null })
						);
					}
				})
				.catch((error) => {
					// If 401 Unauthorized login failed
					if (error.response.status === 401) {
						dispatch(runUnauthorizedActions());
					}
				});
		})
		.catch((err) => {
			console.log("Error", err);
		});
};

export default shipLocations.reducer;
