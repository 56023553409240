import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Dropdown from "react-dropdown";
import { cartLoadOpenPay, setPaymentProcess } from "features/cart/cartSlice";
import {
	setCardAddId,
	setCardAddErrorMsg,
} from "features/account/accountCardAddSlice";
import Loader from "components/Loader";
import { fetchPaymentCard } from "features/cart/cartSlice";
import { toggleShowPoints } from "features/cart/cartSlice";

export default function OpenPayCvvCardForm(props) {
	const dispatch = useDispatch();
	// Selectors
	const { errorMsg, sessId } = useSelector((state) => state.accountCardAdd);
	const isLoadingCart = useSelector((state) => state.cart.isLoading);
	const { cartObject } = useSelector((state) => state.cart);
	const { openPayLoaded, paymentCard, paymentCardItem } = useSelector(
		(state) => state.cart
	);
	const cardList = useSelector((state) => state.accountCards.list);
	const promoList = useSelector((state) => state.paymentCard.list);
	// State
	const [cardValidators, setCardValidators] = useState(null);
	const [code, setCode] = useState("");
	const [promo, setPromo] = useState(null);
	// Effect
	useEffect(() => {
			// OpenPay not loaded
			if (!openPayLoaded) {
				dispatch(cartLoadOpenPay());
				// Antifraud not loaded
			} else if (sessId == null) {
				dispatch(setCardAddId({ sessId: window.OpenPay.deviceData.setup() }));
				// Antifraud Loaded
			} else {
				let newCardValidators = window.OpenPay.card;
				setCardValidators({
					validateCVC: newCardValidators.validateCVC,
				});
			}
	}, [openPayLoaded, sessId, isLoadingCart, dispatch]);
	// Handlers
	function handleReset() {
		setCode("");
		setPromo(null);
		dispatch(setCardAddErrorMsg({ errorMsg: null }));
	}
	function handlePrevClick() {
		handleReset();
		props.onPrev();
	}
	function handleCodeChange(e) {
		const value = e.target.value.replace(/[^0-9]/g, "");
		if (errorMsg != null && errorMsg.code != null) {
			dispatch(
				setCardAddErrorMsg({
					errorMsg: { ...errorMsg, code: null, error: null },
				})
			);
		}
		setCode(value);
	}
	function validateCode(cardCode) {
		if (cardCode.trim().length === 0) {
			return {
				isValid: false,
				error: "Debes escribir el código de seguridad.",
			};
		}
		const validCode = cardValidators.validateCVC(cardCode);
		if (!validCode) {
			return { isValid: false, error: "El código de seguridad no es válido." };
		}
		if (
			paymentCardItem.label.substring(0, 9) === "AMERICAN_" &&
			cardCode.length !== 4
		) {
			return { isValid: false, error: "El código de seguridad no es válido." };
		}
		if (
			paymentCardItem.label.substring(0, 9) !== "AMERICAN_" &&
			cardCode.length !== 3
		) {
			return { isValid: false, error: "El código de seguridad no es válido." };
		}
		return { isValid: true, error: null };
	}
	function handleCodeBlur(e) {
		const validatedCode = validateCode(e.target.value);
		if (!validatedCode.isValid) {
			dispatch(
				setCardAddErrorMsg({
					errorMsg: {
						...errorMsg,
						code: validatedCode.error,
					},
				})
			);
		}
	}
	function handlePromoChange(option) {
		setPromo(option);
	}
	function handleSubmit(e) {
		e.preventDefault();
		let valid = true;
		let errorMessages = { ...errorMsg };
		const validCode = validateCode(code);
		if (!validCode.isValid) {
			errorMessages = {
				...errorMessages,
				code: validCode.error,
			};
			valid = false;
		}
		if (!valid) {
			dispatch(setCardAddErrorMsg({ errorMsg: errorMessages }));
		} else {
			const data = {
				code,
				save: false,
				usePoints: false,
				cardPromo: promo != null ? promo.value : 0,
			};
			dispatch(setPaymentProcess({ isProcessing: true }));
			const selectedCard = cardList.filter((el) => el.Id === paymentCard)[0];
			if (selectedCard.Puntos === true && data.cardPromo === 0) {
				dispatch(toggleShowPoints({ isOpenPoints: false, pointsData: data }));
			} else {
				dispatch(fetchPaymentCard(data));
			}
		}
	}
	// Render functions
	function renderError(error) {
		if (error != null) {
			return <li>{error}</li>;
		}
		return null;
	}
	function renderErrors() {
		if (errorMsg != null) {
			return (
				<div className="error-list">
					<ul>
						{renderError(errorMsg.error)}
						{renderError(errorMsg.code)}
					</ul>
				</div>
			);
		}
		return null;
	}
	// Render
	if (!openPayLoaded || sessId == null) {
		return <Loader msg="Cargando..."></Loader>;
	}
	if (paymentCard === 0) {
		return null;
	}
	function getFieldProps(property) {
		if (errorMsg == null) {
			if (property === "code") {
				return { className: "half" };
			}
			return {};
		}
		if (property in errorMsg && errorMsg[property] != null) {
			if (property === "code") {
				return { className: "half error" };
			}
			return { className: "error" };
		}
		if (property === "code") {
			return { className: "half" };
		}
		return {};
	}
	function renderPromoDropdown() {
		if (promoList != null && promoList.length > 1) {
			const selectedCard = cardList.filter((el) => el.Id === paymentCard)[0];
			if (selectedCard.Tipo.toLowerCase() !== "credit") {
				return null;
			}
			const filteredList = promoList.filter(
				(el) =>
					cartObject.Total >= el.Minimo &&
					(selectedCard.Msi === el.Codigo || el.Codigo === "")
			);
			const options = filteredList.map((el) => {
				return {
					value: el.Id,
					label: el.Nombre + " " + el.Mensaje,
				};
			});
			if (options.length <= 1) {
				return null;
			}
			const selectedOption = promo == null ? options[0] : promo;
			return (
				<div>
					<div>&nbsp;</div>
					<div>
						<label>Meses sin Intereses</label>
						<div>
							<Dropdown
								options={options}
								onChange={handlePromoChange}
								value={selectedOption}
								placeholder="Seleccione"
							/>
						</div>
					</div>
				</div>
			);
		}
		return null;
	}
	return (
		<div className="cart-card">
			<form className="card-form" onSubmit={handleSubmit}>
				<div className="full openpay-cards">
					<div>
						<div>
							<div>Tarjetas de Crédito</div>
							<div>
								<img
									src="/images/cart/openpay-tarjetas.png"
									alt="Tarjetas de crédito"
								/>
							</div>
						</div>
						<div>
							<div>Tarjetas de Débito</div>
							<div>
								<img
									src="/images/cart/openpay-debito1.png"
									alt="tarjetas de débito"
								/>
								<img
									src="/images/cart/openpay-debito2.png"
									alt="tarjetas de débito"
								/>
							</div>
						</div>
					</div>
				</div>
				<div>
					<div>
						<div style={{ fontWeight: "400", fontStyle: "italic" }}>
							{paymentCardItem.label}
						</div>
					</div>
					<div {...getFieldProps("code")}>
						<label>Código de seguridad</label>
						<input
							type="text"
							className="modal-field"
							placeholder="CVV"
							maxLength={4}
							autoComplete="off"
							data-openpay-card="cvv2"
							value={code}
							onChange={handleCodeChange}
							onBlur={handleCodeBlur}
						></input>
					</div>
					<div className="half">
						<img
							className="openpay-cvv"
							src="/images/cart/openpay-cvv.png"
							alt="Código de seguridad"
						/>
					</div>
				</div>
				{renderPromoDropdown()}
				{renderErrors()}
				<div className="full right">
					<div className="openpay-brand">
						<div>
							<div className="openpay-powered">
								<div>Transacciones realizadas vía:</div>
								<div>
									<img src="/images/cart/openpay.png" alt="Openpay" />
								</div>
							</div>
							<div>
								<div>
									<img
										src="/images/cart/security.png"
										alt="Secure"
										style={{ maxWidth: "22px" }}
									/>
								</div>
							</div>
							<div>
								<div>
									Tus pagos se realizan de forma segura con encriptación de 256
									bits
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
			<div className="openpay-checkout">
				<div>
					<div>
						<div className="modal-button" onClick={handlePrevClick}>
							Anterior
						</div>
						<div className="modal-button alternate" onClick={handleSubmit}>
							Finalizar
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
