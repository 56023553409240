import React from "react";
import ReactDOM from "react-dom/client";
import { Route, Routes } from "react-router-dom";
import { sessionService } from "redux-react-session";
import { Provider } from "react-redux";
import { HistoryRouter as Router } from "redux-first-history/rr6";
import ReactModal from "react-modal";
import ReactGA from "react-ga4";
import { setDefaultLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import { store, history } from "app/store";
import CacheBuster from "components/CacheBuster";
import App from "components/App";
import reportWebVitals from "./reportWebVitals";
import packageJson from "../package.json";

import "./fonts.css";
import "./style.css";
import { GOOGLE_ANALYTICS } from "features/constants";

ReactGA.initialize(GOOGLE_ANALYTICS, {
});
ReactGA.event({
	category: "Software",
	action: "Version",
	label: packageJson.version,
});


ReactModal.setAppElement("#root");
setDefaultLocale(es);

sessionService.initSessionService(store);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<CacheBuster>
		{({ loading, isLatestVersion, refreshCacheAndReload }) => {
			if (loading) return null;
			if (!loading && !isLatestVersion) {
				// You can decide how and when you want to force reload
				refreshCacheAndReload();
			}
			return (
				<Provider store={store}>
					<Router history={history}>
						<Routes>
							<Route path="/" element={<App />}>
								<Route
									path="/llanta/:productcode/:brand/:vehicletype/:product/:s1/:s2"
									element={<App />}
								/>
								<Route
									path="/llanta/:productcode/:brand/:vehicletype/:product/:s1"
									element={<App />}
								/>
								<Route
									path="/llantas/:searchtype/:vehicletype/:s1/:s2"
									element={<App />}
								/>
								<Route
									path="/llantas/:searchtype/:vehicletype/:s1"
									element={<App />}
								/>
								<Route path="/eventos/:eventKey" element={<App />} />
								<Route path="/product/:productid" element={<App />} />
								<Route path="/code/:productcode" element={<App />} />
								<Route path="/order/:orderId" element={<App />} />
							</Route>
						</Routes>
					</Router>
				</Provider>
			);
		}}
	</CacheBuster>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
