import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactModal from "react-modal";
import ReactDropdown from "react-dropdown";
import ReactDatePicker from "react-datepicker";
import { sub } from "date-fns";
import {
	fetchLogin,
	setLoginErrorMessage,
	toggleShowLogin,
} from "features/account/loginSlice";
import Loader from "components/Loader";

import "react-datepicker/dist/react-datepicker.css";

export default function LoginModal() {
	const dispatch = useDispatch();
	// Selectors
	const { isOpen, isLoading, errorMsg } = useSelector((state) => state.login);
	const { checked, authenticated } = useSelector((state) => state.session);
	const { options } = useSelector((state) => state.locations);
	// State
	const [employee, setEmployee] = useState("");
	const [birthdate, setBirthdate] = useState(null);
	const [defaultBirthdate, setDefaultBirthdate] = useState(true);
	const [selectedLocation, setSelectedLocation] = useState(null);
	const [accept, setAccept] = useState(true);
	// Refs
	const employeeRef = useRef();
	// Effect
	useEffect(() => {
		employeeRef.current && employeeRef.current.focus();
		let prevAuthenticated;
		if (checked && authenticated && !prevAuthenticated) {
			setEmployee("");
		}
		return () => {
			prevAuthenticated = authenticated;
		};
	}, [checked, authenticated]);

	// Handlers
	function handleEmployeeChange(e) {
		const value = e.target.value.replace(/[^0-9]/g, "");
		if (errorMsg != null && errorMsg.employee != null) {
			dispatch(
				setLoginErrorMessage({
					errorMsg: { ...errorMsg, employee: null, error: null },
				})
			);
		}
		setEmployee(value);
	}
	function handleLocationChange(option) {
		if (errorMsg != null && errorMsg.location != null) {
			dispatch(
				setLoginErrorMessage({
					errorMsg: { ...errorMsg, location: null, error: null },
				})
			);
		}
		setSelectedLocation(option);
	}
	function handleDateChange(date) {
		if (errorMsg != null && errorMsg.birthdate != null) {
			dispatch(
				setLoginErrorMessage({
					errorMsg: { ...errorMsg, birthdate: null, error: null },
				})
			);
		}
		setBirthdate(date);
		setDefaultBirthdate(false);
	}
	function handleTermsChange(e) {
		if (errorMsg != null && errorMsg.accept != null) {
			dispatch(
				setLoginErrorMessage({
					errorMsg: { ...errorMsg, accept: null, error: null },
				})
			);
		}
		setAccept(e.target.checked);
	}
	function handleReset() {}
	function handleClose() {
		handleReset();
		dispatch(toggleShowLogin({ isOpen: isOpen }));
	}
	function handleSubmit(e) {
		e.preventDefault();
		let valid = true;
		let errorMessages = { ...errorMsg };
		if (employee.trim() === "") {
			errorMessages = {
				...errorMessages,
				employee: "Debes escribir tu número de empleado",
			};
			valid = false;
		} else {
			if (employee.length < 7) {
				errorMessages = {
					...errorMessages,
					employee: "Debes escribir un número de empleado válido",
				};
				valid = false;
			}
		}
		if (selectedLocation == null) {
			errorMessages = {
				...errorMessages,
				location: "Debes seleccionar tu localidad",
			};
			valid = false;
		}
		if (defaultBirthdate) {
			errorMessages = {
				...errorMessages,
				birthdate: "Debes seleccionar tu fecha de nacimiento",
			};
			valid = false;
		}
		if (!accept) {
			errorMessages = {
				...errorMessages,
				accept: "Debes aceptar los términos y condiciones de uso",
			};
			valid = false;
		}
		if (valid) {
			dispatch(
				fetchLogin({
					employee,
					birthdate,
					location: selectedLocation.value,
				})
			);
		} else {
			dispatch(setLoginErrorMessage({ errorMsg: errorMessages }));
		}
	}
	// Render
	function renderLoader() {
		if (isLoading) {
			return <Loader msg="Procesando..." />;
		} else {
			return null;
		}
	}
	function renderError(error) {
		if (error != null) {
			if (error !== "") {
				return <li>{error}</li>;
			} else return null;
		} else return null;
	}
	function renderErrorList() {
		if (errorMsg != null) {
			return (
				<div className="error-list">
					<ul>
						{renderError(errorMsg.employee)}
						{renderError(errorMsg.location)}
						{renderError(errorMsg.birthdate)}
						{renderError(errorMsg.accept)}
						{renderError(errorMsg.error)}
					</ul>
				</div>
			);
		} else {
			return null;
		}
	}
	function getFieldProps(property) {
		if (errorMsg == null) {
			if (property === "terms") {
				return { className: "full" };
			}
			return {};
		}
		if (property in errorMsg && errorMsg[property] != null) {
			if (property === "terms") {
				return { className: "full error" };
			}
			return { className: "error" };
		}
		if (property === "terms") {
			return { className: "full" };
		}
		return {};
	}
	function renderRegisterForm() {
		if (!isLoading) {
			const defaultOption =
				selectedLocation != null ? selectedLocation : options[0];
			return (
				<form onSubmit={handleSubmit} onReset={handleReset}>
					<div className="register-form">
						<div>
							<div {...getFieldProps("employee")}>
								<label>Num Empleado</label>
								<input
									type="text"
									ref={employeeRef}
									className="modal-field"
									placeholder="Num Empleado"
									autoComplete="username"
									tabIndex={1}
									onChange={handleEmployeeChange}
									value={employee}
								/>
							</div>
							<div {...getFieldProps("location")}>
								<label>Localidad</label>
								<ReactDropdown
									options={options}
									onChange={handleLocationChange}
									value={defaultOption}
									placeholder="Localidad"
								/>
							</div>
						</div>

						<div>
							<div {...getFieldProps("birthdate")}>
								<label>Fecha de Nacimiento</label>
								<ReactDatePicker
									selected={birthdate}
									onChange={handleDateChange}
									minDate={sub(new Date(), { years: 100 })}
									maxDate={sub(new Date(), { years: 18 })}
									showYearDropdown
									showMonthDropdown
									dropdownMode="select"
									calendarClassName="conti-calendar"
									className="modal-field"
									shouldCloseOnSelect={true}
									strictParsing={true}
									placeholderText="Fecha de Nacimiento"
								/>
							</div>
						</div>
						<div>
							<div className="modal-disclaimer full">
								<h3>TÉRMINOS Y CONDICIONES DE USO</h3>
								<p>
									CONTINENTAL Tire México S.A. de C.V. y sus filiales y
									subsidiarias (en adelante "CONTINENTAL"), le dan la más
									cordial bienvenida.
								</p>
								<h3>AVISO DE PRIVACIDAD DE LA INFORMACIÓN</h3>
								<p>
									Por medio del presente aviso, manifiesto que estoy consciente
									de que mis datos personales en poder de Continental a quien
									previamente autoricé para hacer uso de ellos en los términos
									que establece la ley , serán compartidos con RAMVA NET, S.A.
									DE C.V.en calidad de proveedor del servicio de hospedaje y
									manejo de página web, a fin de llevar a cabo todos los
									trámites y procesos relacionados con la compra y entrega de
									neumáticos a ser realizados a través de la presente
									aplicación, por así convenir a mis intereses.
								</p>
								<p>
									AL INGRESAR Y UTILIZAR ESTE SITIO DE INTERNET, CUYO NOMBRE DE
									DOMINIO ES: www.contishop.com.mx, PROPIEDAD DE CONTINENTAL y
									sus filiales, USTED (EL EMPLEADO) ESTÁ ACEPTANDO LOS TÉRMINOS
									Y LAS CONDICIONES CONTENIDOS EN ESTE CONVENIO Y DECLARA
									EXPRESAMENTE SU ACEPTACIÓN, MANIFESTANDO SU VOLUNTAD EN
									TÉRMINOS DE LO ESTABLECIDO POR LOS ARTÍCULO 1803 Y 1834 BIS
									DEL CÓDIGO CIVIL FEDERAL, 80, 81, 89 Y DEMÁS RELATIVOS Y
									APLICABLES DEL CÓDIGO DE COMERCIO.
								</p>
								<p>
									SI EL USUARIO NO ACEPTA EN FORMA ABSOLUTA LOS TÉRMINOS Y
									CONDICIONES DE ESTE CONVENIO, DEBERÁ ABSTENERSE DE USAR Y VER
									www.contishop.com.mx. PARA EL CASO QUE EL USUARIO CONTINÚE CON
									EL USO DE www.contishop.com.mx, DICHA ACCIÓN SE CONSIDERARÁ
									COMO SU ABSOLUTA ACEPTACIÓN A LOS TÉRMINOS Y CONDICIONES AQUÍ
									ESTABLECIDOS. LA SOLA UTILIZACIÓN DEL SITIO LE OTORGA AL
									EMPELADO, LA CONDICIÓN DE USUARIO E IMPLICA LA ACEPTACIÓN,
									PLENA E INCONDICIONAL, DE TODAS Y CADA UNA DE LAS CONDICIONES
									GENERALES Y PARTICULARES INCLUIDAS EN ESTOS TÉRMINOS DE USO EN
									LA VERSIÓN PUBLICADA POR CONTINENTAL EN EL MOMENTO MISMO EN
									QUE EL USUARIO ACCEDA AL SITIO. CUALQUIER MODIFICACIÓN A LOS
									PRESENTES TÉRMINOS DE USO SERÁ REALIZADA CUANDO CONTINENTAL LO
									CONSIDERE APROPIADO, SIENDO EXCLUSIVA RESPONSABILIDAD DEL
									USUARIO ASEGURARSE DE TOMAR CONOCIMIENTO DE TALES
									MODIFICACIONES.
								</p>
								<p>
									CONTRATO DE ADHESIÓN PARA USO DE SITIO DE INTERNET, QUE
									CELEBRAN POR UNA PARTE CONTINENTAL TIRE DE MÉXICO S.A. DE C.V.
									Y/O SUS FILIALES Y POR LA OTRA EL EMPLEADO, SUJETÁNDOSE, AMBAS
									PARTES, A LO ESTABLECIDO EN EL TEXTO DEL PRESENTE CONTRATO.
								</p>
							</div>
						</div>
						<div>
							<div {...getFieldProps("terms")}>
								<div className="switch">
									<input
										type="checkbox"
										id="accept-terms"
										name="accept-terms"
										checked={accept === true}
										onChange={handleTermsChange}
									/>
									<label htmlFor="accept-terms" tabIndex={11}>
										<span className="icon-switch"></span>
										<div>
											Acepto "Aviso de Privacidad" y "Términos y Condiciones de
											Uso"
										</div>
									</label>
								</div>
							</div>
							<div className="full">{renderErrorList()}</div>
							<div className="full right">
								<div
									className="modal-button alternate large"
									onClick={handleSubmit}
								>
									Validar Datos
								</div>
							</div>
						</div>
					</div>
				</form>
			);
		} else {
			return null;
		}
	}
	return (
		<div>
			<ReactModal
				isOpen={isOpen}
				className="modal"
				overlayClassName="modal-overlay"
				onRequestClose={handleClose}
				contentLabel="Registro"
			>
				<div className="modal-inner" id="login-inner">
					<div className="modal-close" onClick={handleClose}></div>
					<h2>Acceso Empleados</h2>
					{renderLoader()}
					{renderRegisterForm()}
				</div>
			</ReactModal>
		</div>
	);
}
