import { createSlice } from "@reduxjs/toolkit";
import ReactGA from "react-ga4";
import { BASE_API_URL } from "../constants";
import { getMatchingItemsBlank } from "features/functions";
import { sessionService } from "redux-react-session";
import axios from "axios";
import { runUnauthorizedActions } from "features/account/loginSlice";

const initialState = {
	isLoading: false,
	value: "",
	selectedItem: {},
	suggestions: [],
	renderInput: false,
	list: null,
};

const vehicleModels = createSlice({
	name: "vehicleModels",
	initialState,
	reducers: {
		requestVehicleModelsList(state) {
			state.isLoading = true;
		},
		receiveVehicleModelsList(state, { payload }) {
			state.isLoading = false;
			state.list = payload.list;
			state.renderInput = payload.renderInput;
		},
		loadVehicleModelsSuggestionsBegin(state) {
			state.isLoading = true;
		},
		maybeUpdateVehicleModelsSuggestions(state, { payload }) {
			state.isLoading = false;
			if (state.value !== payload.value) {
			} else {
				state.suggestions = payload.suggestions;
			}
		},
		updateVehicleModelValue(state, { payload }) {
			state.value = payload.value;
		},
		updateVehicleModelItem(state, { payload }) {
			if (payload.selectedItem?.Name != null) {
				ReactGA.event({
					category: "Vehiculo",
					action: "Seleccionar Modelo",
					label: payload.selectedItem.Name,
					value: payload.selectedItem.ID,
				});
			}
			state.selectedItem = payload.selectedItem;
		},
		clearVehicleModelsSuggestions(state) {
			state.suggestions = [];
		},
		clearVehicleModelValue(state, { payload }) {
			state.value = "";
			state.selectedItem = {};
			state.renderInput = payload.renderInput;
		},
		clearVehicleModelData: () => initialState,
	},
});

export const {
	requestVehicleModelsList,
	receiveVehicleModelsList,
	loadVehicleModelsSuggestionsBegin,
	maybeUpdateVehicleModelsSuggestions,
	updateVehicleModelValue,
	updateVehicleModelItem,
	clearVehicleModelsSuggestions,
	clearVehicleModelValue,
	clearVehicleModelData,
} = vehicleModels.actions;

const shouldFetchVehicleModels = (state, brandId) => {
	const brand = state.vehicleBrands.selectedItem;
	if (state.vehicleModels.list == null) {
		if (brand != null) {
			return true;
		}
		return false;
	}
	if (brand?.ID !== brandId) {
		return true;
	}
	return false;
};
export const fetchVehicleModelsIfNeeded =
	(brandId) => async (dispatch, getState) => {
		if (shouldFetchVehicleModels(getState(), brandId)) {
			return dispatch(fetchVehicleModels(brandId));
		}
	};
export const loadVehicleModelsSuggestions =
	(value) => async (dispatch, getState) => {
		dispatch(loadVehicleModelsSuggestionsBegin());
		const theState = getState();
		dispatch(
			fetchVehicleModelsIfNeeded(theState.vehicleBrands.selectedItem.ID)
		);
		setTimeout(() => {
			dispatch(
				maybeUpdateVehicleModelsSuggestions({
					suggestions: getMatchingItemsBlank(theState.vehicleModels, value),
					value,
				})
			);
		});
	};
export const fetchVehicleModels = (brandId) => async (dispatch) => {
	sessionService
		.loadSession()
		.then((session) => {
			const headers = {
				Authorization: "bearer " + session.loginData.access_token,
				"Content-Type": "application/json",
			};
			const options = {
				headers: headers,
			};
			dispatch(requestVehicleModelsList());
			const url =
				BASE_API_URL +
				"/api/Empleados/Vehiculos/Buscar/Modelos?marca=" +
				brandId;
			return axios
				.get(url, options)
				.then((response) => {
					dispatch(
						receiveVehicleModelsList({ list: response.data, renderInput: true })
					);
				})
				.catch((error) => {
					// If 401 Unauthorized login failed
					if (error.response.status === 401) {
						dispatch(runUnauthorizedActions());
					}
				});
		})
		.catch((err) => console.log("Error", err));
};

export default vehicleModels.reducer;
