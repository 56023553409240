import React from "react";
import { useSelector } from "react-redux";
import { CART_STEPS as s, PAYMENT_METHODS as p } from "features/constants";
import CartDetails from "./CartDetails";
import CartShip from "./CartShip";
import CartPayment from "./CartPayment";
import CartSummary from "./CartSummary";
import PaymentCreditCardForm from "./PaymentCreditCardForm";
import PaymentPayPalForm from "./PaymentPayPalForm";
import CartMyData from "./CartMyData";

export default function Cart() {
	// Selectors
	const {
		showTab,
		creditCardResponse,
		payPalResponse,
		safetyPayResponse,
		paymentType,
	} = useSelector((state) => state.cart);
	// Render functions
	function renderSummary() {
		if (
			creditCardResponse !== null ||
			payPalResponse !== null ||
			safetyPayResponse !== null
		) {
			switch (paymentType) {
				case p.CREDITCARD:
					return <PaymentCreditCardForm />;
				case p.PAYPAL:
					return <PaymentPayPalForm />;
				case p.SAFETYPAY:
					window.location.assign(safetyPayResponse.Url);
					return null;
				default:
					break;
			}
		} else {
			return <CartSummary />;
		}
	}
	// Render
	switch (showTab) {
		case s.DETAILS:
		default:
			return <CartDetails />;
		case s.SHIP:
			return <CartShip />;
		case s.MYDATA:
			return <CartMyData />;
		case s.PAYMENT:
			return <CartPayment />;
		case s.SUMMARY:
			return renderSummary();
	}
}
