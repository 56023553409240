import React from "react";
import HomeDisclaimer from "./HomeDisclaimer";
import HomeFooter from "./HomeFooter";

export default function HomeContent() {
	return (
		<div className="contenido home">
			<div className="home-header">
				<div>
					<h1>ContiShop Empleados</h1>
				</div>
			</div>
			<HomeDisclaimer />
			<HomeFooter />
		</div>
	);
}
