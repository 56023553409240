import { createSlice } from "@reduxjs/toolkit";
import { sessionService } from "redux-react-session";
import { BASE_API_URL } from "../constants";
import { getPropertyArray } from "../functions";
import { runUnauthorizedActions } from "features/account/loginSlice";
import { setCartShipAddress, setCartShipLocationId } from "./cartSlice";
import axios from "axios";

const initialState = {
	isLoading: false,
	list: null,
	stateList: null,
	selectedState: null,
	cityList: null,
	selectedCity: null,
};
const dealerShipAddress = createSlice({
	name: "dealerShipAddress",
	initialState,
	reducers: {
		requestDealerShipAddress: (state) => {
			state.isLoading = true;
		},
		receiveDealerShipAddress: (state, action) => {
			state.isLoading = false;
			state.list = action.payload.list;
		},
		setDealerShipAddressStateList: (state, action) => {
			const stateList = getPropertyArray(action.payload.list, "State");
			state.stateList = stateList;
		},
		setDealerShipAddressSelectedState: (state, action) => {
			state.selectedState = action.payload.selectedState;
		},
		setDealerShipAddressCityList: (state, action) => {
			const filter = (item) => {
				return item.State === action.payload.selectedState.value;
			};
			const cityList = getPropertyArray(action.payload.list, "City", filter);
			state.cityList = cityList;
		},
		setDealerShipAddressSelectedCity: (state, action) => {
			state.selectedCity = action.payload.selectedCity;
		},
		clearDealerShipAddressData: () => initialState,
	},
});

export const {
	requestDealerShipAddress,
	receiveDealerShipAddress,
	setDealerShipAddressStateList,
	setDealerShipAddressSelectedState,
	setDealerShipAddressCityList,
	setDealerShipAddressSelectedCity,
	clearDealerShipAddressData,
} = dealerShipAddress.actions;

const shouldFetchDealerShipAddressList = (state) => {
	const { dealerShipAddress } = state;
	if (dealerShipAddress.list == null) {
		if (!dealerShipAddress.isLoading) {
			return true;
		}
		return false;
	}
	return false;
};

export const fetchDealerShipAddressListIfNeeded =
	() => async (dispatch, getState) => {
		const theState = getState();
		if (shouldFetchDealerShipAddressList(theState)) {
			return dispatch(fetchDealerShipAddress());
		}
	};
export const setSelectDealerShipAddressCity =
	(list, selectedCity) => async (dispatch) => {
		dispatch(setDealerShipAddressSelectedCity({ selectedCity }));
		if (list.length === 1) {
			dispatch(
				setCartShipAddress({ shipAddress: 0, shipAddressItem: list[0] })
			);
			dispatch(setCartShipLocationId({ shipLocationId: list[0].Id }));
		} else {
			dispatch(
				setCartShipAddress({ shipAddress: null, shipAddressItem: null })
			);
			dispatch(setCartShipLocationId({ shipLocationId: null }));
		}
	};
export const setDealerShipAddressDefaultSelectedCity =
	() => async (dispatch, getState) => {
		const theState = getState();
		const { dealerShipAddress } = theState;
		if (
			dealerShipAddress.cityList != null &&
			dealerShipAddress.cityList.length > 0
		) {
			dispatch(
				setDealerShipAddressSelectedCity({
					selectedCity: dealerShipAddress.cityList[0],
				})
			);
		}
	};

export const fetchDealerShipAddress = () => async (dispatch) => {
	sessionService
		.loadSession()
		.then((session) => {
			const headers = {
				Authorization: "bearer " + session.loginData.access_token,
				"Content-Type": "application/json",
			};
			const options = {
				headers: headers,
			};
			dispatch(requestDealerShipAddress());
			const url = BASE_API_URL + "/api/Empleados/Envios/Distribuidores";
			return axios
				.get(url, options)
				.then((response) => {
					if (response.data.length === 0) {
						dispatch(
							setCartShipAddress({ shipAddress: null, shipAddressItem: null })
						);
						dispatch(receiveDealerShipAddress({ list: null }));
					} else {
						dispatch(receiveDealerShipAddress({ list: response.data }));
						dispatch(setDealerShipAddressStateList({ list: response.data }));
					}
				})
				.catch((error) => {
					// If 401 Unauthorized login failed
					if (error.response.status === 401) {
						dispatch(runUnauthorizedActions());
					}
				});
		})
		.catch((err) => console.log("Error", err));
};

export default dealerShipAddress.reducer;
