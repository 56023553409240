import React from "react";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import Loader from "components/Loader";
import StoreBannerItem from "./StoreBannerItem";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const sliderDivStyle = {
	height: "100%",
};

const defaultSlides = [{
	Id: 0,
	Nombre: "ContiShop Empleados",
	Titulo: null,
	Texto: null,
	Imagen: "banner-default.jpg",
	Desde: null,
	Hasta: null,
	Tipo: 0,
	Url: null
}];

export default function StoreBanner() {
	// Selectors
	const { isLoading, list } = useSelector((state) => state.banners);
	// Render Functions
	function renderLoader() {
		if (isLoading) {
			return <Loader />;
		}
		return null;
	}
	function renderCarousel() {
		if (!isLoading && list != null) {
			const slideList = list.length === 0 ? defaultSlides : list;
			var settings = {
				dots: false,
				infinite: true,
				slidesToShow: 1,
				speed: 500,
				autoplaySpeed: 4000,
				autoplay: true,
				swipeToSlide: true,
			};
			return (
				<Slider {...settings}>
						{slideList.map((el, index) =>{
							return (
								<StoreBannerItem 
									key={index} 
									index={index} 
									image={"/images/slider/" + el.Imagen} 
									name={el.Titulo} 
									text={el.Texto}
									targetUrl={el.Url} />
							)
						})}
				</Slider>
			);
		}
		return null;
	}
	return (
		<div className="main-slider" style={sliderDivStyle}>
			<div className="content-box">
				{renderLoader()}
				{renderCarousel()}
			</div>
		</div>
	);
}
