import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-modal";
import {
	validateCardMonth,
	validateCardYear,
	stringNumberFormat,
} from "features/functions";
import {
	toggleShowEditCard,
	setCardEditErrorMsg,
} from "features/account/accountCardEditSlice";
import { setCardAddId } from "features/account/accountCardAddSlice";
import { cartLoadOpenPay } from "features/cart/cartSlice";
import Loader from "../Loader";
import { setSelectedCard } from "features/account/accountCardsSlice";
import { fetchCardEdit } from "features/account/accountCardEditSlice";

export default function AccountCardEdit() {
	const dispatch = useDispatch();
	// Selectors
	const { isLoading, isOpen, errorMsg } = useSelector(
		(state) => state.accountCardEdit
	);
	const { sessId } = useSelector((state) => state.accountCardAdd);
	const { selectedCard } = useSelector((state) => state.accountCards);
	const { openPayLoaded } = useSelector((state) => state.cart);
	// State
	const [cardValidators, setCardValidators] = useState(null);
	const [month, setMonth] = useState("");
	const [year, setYear] = useState("");
	// Effect
	useEffect(() => {
			// OpenPay not loaded
			if (!openPayLoaded) {
				dispatch(cartLoadOpenPay());
				// Antifraud not loaded
			} else if (sessId == null) {
				dispatch(setCardAddId({ sessId: window.OpenPay.deviceData.setup() }));
				// Antifraud Loaded
			} else {
				let newCardValidators = window.OpenPay.card;
				setCardValidators({
					validateExpiry: newCardValidators.validateExpiry,
				});
			}
	}, [openPayLoaded, sessId, isLoading, dispatch]);
	// Handlers
	function handleReset() {
		setMonth("");
		setYear("");
		dispatch(setCardEditErrorMsg({ errorMsg: null }));
		dispatch(setSelectedCard({ selectedCard: null }));
	}
	function handleClose() {
		handleReset();
		dispatch(toggleShowEditCard({ isOpen }));
	}
	function handleMonthChange(e) {
		const value = e.target.value.replace(/[^0-9]/g, "");
		if (
			errorMsg != null &&
			(errorMsg.month != null || errorMsg.expiry != null)
		) {
			dispatch(
				setCardEditErrorMsg({
					errorMsg: { ...errorMsg, month: null, expiry: null, error: null },
				})
			);
		}
		setMonth(value);
	}
	function validateMonth(cardMonth) {
		if (cardMonth.trim().length === 0) {
			return { isValid: false, error: "Debes escribir el mes de vencimiento." };
		}
		if (!validateCardMonth(cardMonth)) {
			return { isValid: false, error: "El mes de vencimiento no es válido." };
		}
		return { isValid: true, error: null };
	}
	function handleMonthdBlur(e) {
		const validatedMonth = validateMonth(e.target.value);
		if (!validatedMonth.isValid) {
			dispatch(
				setCardEditErrorMsg({
					errorMsg: {
						...errorMsg,
						month: validatedMonth.error,
					},
				})
			);
		}
	}
	function handleYearChange(e) {
		if (
			errorMsg != null &&
			(errorMsg.year != null || errorMsg.expiry != null)
		) {
			dispatch(
				setCardEditErrorMsg({
					errorMsg: { ...errorMsg, year: null, expiry: null, error: null },
				})
			);
		}
		setYear(e.target.value);
	}
	function validateYear(cardYear) {
		if (cardYear.trim().length === 0) {
			return {
				isValid: false,
				error: "Debes escribir el año de vencimiento.",
				expiry: false,
			};
		}
		if (!validateCardYear(cardYear)) {
			return {
				isValid: false,
				error: "El año de vencimiento no es válido.",
				expiry: false,
			};
		}
		const validExpiry = cardValidators.validateExpiry(month, cardYear);
		if (
			!validExpiry ||
			Number(cardYear) < selectedCard.Anio ||
			(Number(cardYear) === selectedCard.Anio &&
				Number(month) <= selectedCard.Mes)
		) {
			return {
				isValid: false,
				error: "La fecha de vencimiento no es válida.",
				expiry: true,
			};
		}
		return { isValid: true, error: null };
	}
	function handleYearBlur(e) {
		const validatedYear = validateYear(e.target.value);
		if (!validatedYear.isValid) {
			if (!validatedYear.expiry) {
				dispatch(
					setCardEditErrorMsg({
						errorMsg: {
							...errorMsg,
							year: validatedYear.error,
						},
					})
				);
			} else {
				dispatch(
					setCardEditErrorMsg({
						errorMsg: {
							...errorMsg,
							expiry: validatedYear.error,
						},
					})
				);
			}
		}
	}
	function handleSubmit(e) {
		e.preventDefault();
		let valid = true;
		let errorMessages = { ...errorMsg };
		const validMonth = validateMonth(month);
		if (!validMonth.isValid) {
			errorMessages = {
				...errorMessages,
				month: validMonth.error,
			};
			valid = false;
		}
		const validYear = validateYear(year);
		if (!validYear.isValid) {
			errorMessages = !validYear.expiry
				? {
						...errorMessages,
						year: validYear.error,
				  }
				: {
						...errorMessages,
						expiry: validYear.error,
				  };
			valid = false;
		}
		if (!valid) {
			dispatch(setCardEditErrorMsg({ errorMsg: errorMessages }));
		} else {
			const data = {
				cardId: selectedCard.Id,
				year,
				month,
			};
			dispatch(fetchCardEdit(data));
		}
	}
	// Render functions
	function getFieldProps(property) {
		if (errorMsg == null) {
			if (property === "month" || property === "year") {
				return { className: "half" };
			}
			return {};
		}
		if (
			(property === "month" || property === "year") &&
			"expiry" in errorMsg &&
			errorMsg["expiry"] != null
		) {
			return { className: "half error" };
		}
		if (property in errorMsg && errorMsg[property] != null) {
			if (property === "month" || property === "year") {
				return { className: "half error" };
			}
			return { className: "error" };
		}
		if (property === "month" || property === "year") {
			return { className: "half" };
		}
		return {};
	}
	function renderError(error) {
		if (error != null) {
			return <li>{error}</li>;
		}
		return null;
	}
	function renderErrors() {
		if (errorMsg != null) {
			return (
				<div className="error-list">
					<ul>
						{renderError(errorMsg.error)}
						{renderError(errorMsg.month)}
						{renderError(errorMsg.year)}
						{renderError(errorMsg.expiry)}
					</ul>
				</div>
			);
		}
		return null;
	}
	function renderLoader() {
		if (isLoading || !openPayLoaded) {
			return <Loader msg="Cargando..."></Loader>;
		}
	}
	function renderForm() {
		if (!isLoading && openPayLoaded && selectedCard != null) {
			return (
				<div className="cart-card">
					<form className="card-form" onSubmit={handleSubmit}>
						<div className="full openpay-cards">
							<div>
								<div>
									<div>Tarjetas de Crédito</div>
									<div>
										<img
											src="/images/cart/openpay-tarjetas.png"
											alt="Tarjetas de crédito"
										/>
									</div>
								</div>
								<div>
									<div>Tarjetas de Débito</div>
									<div>
										<img
											src="/images/cart/openpay-debito1.png"
											alt="tarjetas de débito"
										/>
										<img
											src="/images/cart/openpay-debito2.png"
											alt="tarjetas de débito"
										/>
									</div>
								</div>
							</div>
						</div>
						<div>
							<div>
								<div style={{ fontWeight: "400", fontStyle: "italic" }}>
									{selectedCard.Marca.toUpperCase() +
										" ***" +
										selectedCard.Numero +
										" - " +
										stringNumberFormat(selectedCard.Mes, 2) +
										"/" +
										stringNumberFormat(selectedCard.Anio, 2)}
								</div>
							</div>
							<div {...getFieldProps("month")}>
								<label>Fecha de expiración</label>
								<input
									type="text"
									className="modal-field"
									placeholder="Mes"
									maxLength={2}
									data-openpay-card="expiration_month"
									value={month}
									onChange={handleMonthChange}
									onBlur={handleMonthdBlur}
								></input>
							</div>
							<div {...getFieldProps("year")}>
								<input
									type="text"
									className="modal-field"
									placeholder="Año"
									maxLength={2}
									data-openpay-card="expiration_year"
									value={year}
									onChange={handleYearChange}
									onBlur={handleYearBlur}
								></input>
							</div>
						</div>
						{renderErrors()}
						<div className="full right">
							<div className="openpay-brand">
								<div>
									<div className="openpay-powered">
										<div>Transacciones realizadas vía:</div>
										<div>
											<img src="/images/cart/openpay.png" alt="Openpay" />
										</div>
									</div>
									<div>
										<div>
											<img
												src="/images/cart/security.png"
												alt="Secure"
												style={{ maxWidth: "22px" }}
											/>
										</div>
									</div>
									<div>
										<div>
											Tus pagos se realizan de forma segura con encriptación de
											256 bits
										</div>
									</div>
								</div>
							</div>
						</div>
					</form>
					<div className="openpay-checkout">
						<div>
							<div>
								<div className="modal-button" onClick={handleClose}>
									Cancelar
								</div>
								<div className="modal-button alternate" onClick={handleSubmit}>
									Guardar
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
		return null;
	}
	// Render
	return (
		<div>
			<Modal
				isOpen={isOpen}
				className="modal"
				overlayClassName="modal-overlay"
				onRequestClose={handleClose}
				contentLabel="Tarjetas"
			>
				<div className="modal-inner" id="cards-inner">
					<div className="modal-close" onClick={handleClose}></div>
					<h2>Actualizar Tarjeta</h2>
					<p>Si la fecha de vencimiento de tu tarjeta ha cambiado puedes actualizarla.</p>
					{renderLoader()}
					{renderForm()}
				</div>
			</Modal>
		</div>
	);
}
