import { createSlice } from "@reduxjs/toolkit";
import { sessionService } from "redux-react-session";
import ReactGA from "react-ga4";
import qs from "qs";
import Scroll from "react-scroll";
import { BASE_API_URL, VEHICLE_TYPES } from "features/constants";
import { fetchAccount } from "./accountSlice";
import axios from "axios";
import { toggleShowAlert, setAlert } from "features/alert/alertSlice";
import { format } from "date-fns";
import {
	clearHighlightsData,
	fetchHighlightsTiresListIfNeeded,
} from "features/tireResults/highlightsTiresSlice";
import {
	clearVehicleBrandData,
	fetchVehicleBrandsIfNeeded,
} from "features/tireFinder/vehicleBrandsSlice";
import { clearVehicleModelData } from "features/tireFinder/vehicleModelsSlice";
import { clearVehicleYearData } from "features/tireFinder/vehicleYearsSlice";
import { clearVehicleVersionData } from "features/tireFinder/vehicleVersionsSlice";
import { clearVehicleTiresData } from "features/tireResults/vehicleTiresSlice";
import {
	clearWidthDiameterData,
	fetchWidthDiametersIfNeeded,
} from "features/tireFinder/sizeWidthDiametersSlice";
import { clearSizeRatiosData } from "features/tireFinder/sizeRatiosSlice";
import { clearSizeRimData } from "features/tireFinder/sizeRimsSlice";
import { clearSizeTiresData } from "features/tireResults/sizeTiresSlice";
import { clearWidthSaeData } from "features/tireFinder/sizeSaeWidthsSlice";
import { clearSizeRimSaesData } from "features/tireFinder/sizeSaeRimsSlice";
import { clearSizeSaeTiresData } from "features/tireResults/sizeSaeTiresSlice";
import {
	clearCartData,
	fetchCartObjectIfNeeded,
} from "features/cart/cartSlice";
import {
	clearDealerShipAddressData,
	fetchDealerShipAddressListIfNeeded,
} from "features/cart/dealerShipAddressSlice";
import { fetchPaymentCardListIfNeeded } from "features/cart/paymentCardSlice";
import {
	clearSearchData,
	fetchSearchIfNeeded,
} from "features/tireFinder/searchSlice";
import { clearSearchResultData } from "features/tireResults/searchTiresSlice";
import { clearOrdersData } from "features/orders/ordersSlice";
import { clearOrderConfirmationData } from "features/orders/orderConfirmationSlice";
import { fetchBannersList } from "features/banners/bannersSlice";
import { clearCodeTiresData } from "features/tireResults/codeTiresSlice";
import { fetchAccountCards } from "./accountCardsSlice";
import { fetchShipLocationData } from "./shipLocationsSlice";
import { clearSizeShortData, fetchSizeShortIfNeeded } from "features/tireFinder/sizeShortSlice";

const initialState = {
	isLoading: false,
	isOpen: false,
	errorMsg: null,
};
const loginSlice = createSlice({
	name: "login",
	initialState,
	reducers: {
		requestLoginData(state) {
			state.isLoading = true;
		},
		receiveLoginData(state) {
			state.isLoading = false;
		},
		toggleShowLogin: (state, action) => {
			state.isOpen = !action.payload.isOpen;
		},
		setLoginErrorMessage(state, action) {
			state.errorMsg = action.payload.errorMsg;
		},
	},
});

export const {
	requestLoginData,
	receiveLoginData,
	toggleShowLogin,
	setLoginErrorMessage,
} = loginSlice.actions;

export const fetchLogin = (data) => async (dispatch) => {
	const headers = {
		"Content-Type": "application/x-www-form-urlencoded",
	};
	const options = {
		headers: headers,
	};
	const body = qs.stringify({
		username: data.employee,
		password: data.location,
		birthdate: format(data.birthdate, "yyyy-MM-dd"),
		grant_type: "password",
	});
	const url = BASE_API_URL + "/Employee/Token";
	dispatch(requestLoginData());
	//console.log("fetchLogin", { data, options, body });
	return axios
		.post(url, body, options)
		.then((response) => {
			sessionService
				.saveSession({ loginData: response.data })
				.then(() => dispatch(fetchAccount()))
				.catch((error) => console.log("error", error));
			dispatch(receiveLoginData());
			dispatch(setLoginErrorMessage({ errorMsg: null }));
			ReactGA.event({
				category: "Usuario",
				action: "Iniciar Sesión",
			});
			let scroll = Scroll.animateScroll
			scroll.scrollTo(0, {smooth: true, delay: 100, duration: 500})
		})
		.catch((error) => {
			if (error.response.status === 400) {
				if (error.response.data.error_description != null) {
					dispatch(
						setLoginErrorMessage({
							errorMsg: { error: error.response.data.error_description },
						})
					);
				} else {
					dispatch(
						setLoginErrorMessage({
							errorMsg: {
								error: "El número de empleado, la localidad o la fecha de nacimiento son incorrectos.",
							},
						})
					);
				}
			}
			dispatch(receiveLoginData());
		});
};
export const runAuthenticatedActions = () => async (dispatch) => {
	sessionService.loadSession().then(() => {
		dispatch(fetchBannersList());
		dispatch(fetchCartObjectIfNeeded());
		dispatch(fetchDealerShipAddressListIfNeeded());
		dispatch(fetchVehicleBrandsIfNeeded());
		dispatch(fetchHighlightsTiresListIfNeeded());
		dispatch(fetchAccountCards());
		dispatch(fetchShipLocationData());
		dispatch(fetchPaymentCardListIfNeeded());
		dispatch(fetchWidthDiametersIfNeeded(VEHICLE_TYPES.CAR));
		dispatch(fetchSearchIfNeeded());
		dispatch(fetchSizeShortIfNeeded());
});
};
export const runUnauthorizedActions =
	(logout = false) =>
	async (dispatch) => {
		sessionService.deleteSession();
		sessionService.deleteUser();
		dispatch(clearCartData());
		dispatch(clearDealerShipAddressData());
		dispatch(clearVehicleBrandData());
		dispatch(clearVehicleModelData());
		dispatch(clearVehicleYearData());
		dispatch(clearVehicleVersionData());
		dispatch(clearVehicleTiresData());
		dispatch(clearWidthDiameterData());
		dispatch(clearSizeRatiosData());
		dispatch(clearSizeRimData());
		dispatch(clearSizeTiresData());
		dispatch(clearWidthSaeData());
		dispatch(clearSizeRimSaesData());
		dispatch(clearSizeSaeTiresData());
		dispatch(clearCodeTiresData());
		dispatch(clearHighlightsData());
		dispatch(clearSearchData());
		dispatch(clearSearchResultData());
		dispatch(clearOrdersData());
		dispatch(clearOrderConfirmationData());
		dispatch(clearSizeShortData());
		const scroll = Scroll.animateScroll;
		scroll.scrollTo(0, { smooth: true, delay: 100, duration: 500 });
		if (logout === false) {
			dispatch(
				setAlert({
					alert: {
						title: "Aviso",
						description:
							"Tu sesión ha caducado, para continuar con tu compra debes volver a iniciar sesión.",
						details: null,
					},
				})
			);
			dispatch(toggleShowAlert({ isOpen: false }));
		}
	};
export const doLogout = () => async (dispatch) => {
	ReactGA.event({
		category: "Usuario",
		action: "Cerrar Sesión",
	});
	dispatch(runUnauthorizedActions(true));
};

export default loginSlice.reducer;
