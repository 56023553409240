import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { SHIP_TYPES } from "features/constants";
import { currencyFormat, getModalButtonClass } from "features/functions";
import {
	setCartActiveTab,
	setCartShipAddress,
	setCartShipLocationId,
	setCartShipType,
} from "features/cart/cartSlice";
import { fetchPaymentCardListIfNeeded } from "features/cart/paymentCardSlice";
import CartShipTo from "./CartShipTo";
import CartShipDealer from "./CartShipDealer";
import {
	setDealerShipAddressSelectedCity,
	setDealerShipAddressSelectedState,
} from "features/cart/dealerShipAddressSlice";

export default function CartShip() {
	const dispatch = useDispatch();
	// Selectors
	const { shipType, cartObject, showTab, shipAddressItem, shipLocationId } =
		useSelector((state) => state.cart);
	const { data } = useSelector((state) => state.shipLocations);
	// Handlers
	const handlePrevClick = () => {
		dispatch(setCartActiveTab({ showTab: showTab - 1 }));
	};
	const handleNextClick = () => {
		if (
			shipType !== 0 &&
			((shipType === SHIP_TYPES.DEALER && shipLocationId != null) ||
				(shipType === SHIP_TYPES.EMPLEADOS && shipAddressItem != null))
		) {
			dispatch(setCartActiveTab({ showTab: showTab + 1 }));
			dispatch(fetchPaymentCardListIfNeeded());
		}
	};
	const handleChangeShipType = () => {
		dispatch(setCartShipType({ shipType: SHIP_TYPES.EMPLEADOS }));
		dispatch(
			setCartShipAddress({
				shipAddress: 0,
				shipAddressItem: { Alias: "EMPLEADOS" },
			})
		);
		dispatch(setDealerShipAddressSelectedState({ selectedState: null }));
		dispatch(setDealerShipAddressSelectedCity({ selectedCity: null }));
		dispatch(setCartShipLocationId({ shipLocationId: null }));
	};
	// Render
	const renderChangeShipTypeButton = () => {
		if (
			data != null &&
			data.ShipHR === true &&
			shipType === SHIP_TYPES.DEALER
		) {
			return (
				<div id="cart-back">
					<div
						className="modal-button alternate large"
						onClick={handleChangeShipType}
					>
						<span className="caret-back"></span>Cambiar método de envío
					</div>
				</div>
			);
		}
		return <div id="cart-coupon">&nbsp;</div>;
	};
	const totalAmount =
		shipType === SHIP_TYPES.DHL_EMPLEADOS
			? cartObject.Total * 1.16 + cartObject.Costo_Envio
			: cartObject.Total * 1.16;
	return (
		<div id="cart-content-2">
			<CartShipTo />
			<CartShipDealer />
			<div id="cart-summary">
				{renderChangeShipTypeButton()}
				<div id="cart-totals">
					<div>
						<div>Subtotal</div>
						<div>{currencyFormat(cartObject.Total * 1.16)}</div>
					</div>
					<div>
						<div>Envío</div>
						<div>
							{currencyFormat(
								shipType === SHIP_TYPES.DHL_EMPLEADOS
									? cartObject.Costo_Envio
									: 0
							)}
						</div>
					</div>
					<div>
						<div className="totals">Total</div>
						<div>{currencyFormat(totalAmount)}</div>
					</div>
					<div>
						<div>Desglose IVA</div>
						<div>{currencyFormat(cartObject.Total * 0.16)} </div>
					</div>
					<div>
						<div>
							<div className="modal-button" onClick={handlePrevClick}>
								Anterior
							</div>
						</div>
						<div>
							<div
								className={getModalButtonClass(
									shipType === 0 ||
										(shipType === SHIP_TYPES.DEALER && shipLocationId === null)
								)}
								onClick={handleNextClick}
							>
								Continuar
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
