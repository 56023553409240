import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactModal from "react-modal";
import { stringNumberFormat } from "features/functions";
import { OPENPAY_CARD_LIMIT } from "features/constants";
import { setSelectedCard, toggleShowAccountCards } from "features/account/accountCardsSlice";
import { toggleShowEditCard, toggleShowDeleteCard } from "features/account/accountCardEditSlice";
import Loader from "../Loader";

export default function AccountCards() {
	const dispatch = useDispatch();
	// Selectors
	const { isLoading, list, isOpen } = useSelector(
		(state) => state.accountCards
	);
	// Handlers
	function handleClose() {
		dispatch(toggleShowAccountCards({ isOpen }));
	}
	function handleCardEdit(selectedCard) {
		dispatch(setSelectedCard({selectedCard}));
		dispatch(toggleShowEditCard({isOpen: false}));
	}
	function handleCardDelete(selectedCard) {
		dispatch(setSelectedCard({selectedCard}));
		dispatch(toggleShowDeleteCard({isOpenDelete: false}));
	}
	// Render functions
	function renderCardList() {
		if (isLoading) {
			return <Loader msg="Cargando..." />;
		}
		const now = new Date();
		const year = now.getFullYear() % 100;
		if (list != null && list.length > 0) {
			return (
				<div className="cards-list">
					<div>
						{list.map((el, index) => {
							const expired =
								el.Anio < year ||
								(el.Anio === year && el.Mes <= now.getMonth() + 1)
									? { className: "expired" }
									: {};
							return (
								<div key={index}>
									<div>{"*** " + el.Numero}</div>
									<div {...expired}>
										{stringNumberFormat(el.Mes, 2) +
											"/" +
											stringNumberFormat(el.Anio, 2)}
									</div>
									<div>{el.Marca.replace("_", " ").toUpperCase()}</div>
									<div>
										<span className="icon-edit" onClick={() => handleCardEdit(el)}></span>
									</div>
									<div>
										<span className="icon-remove" onClick={() => handleCardDelete(el)}></span>
									</div>
								</div>
							);
						})}
					</div>
				</div>
			);
		}
		return <div>No hay tarjetas que mostrar.</div>;
	}
	function renderAddCard() {
		if (list != null && list.length < OPENPAY_CARD_LIMIT)
		return (
			<div className="add-item">
				<span className="add-text"></span>
				<span className="icon-add"></span>
			</div>
		);
	}
	// Render
	return (
		<div>
			<ReactModal
				isOpen={isOpen}
				className="modal"
				overlayClassName="modal-overlay"
				onRequestClose={handleClose}
				contentLabel="Tarjetas"
			>
				<div className="modal-inner" id="cards-inner">
					<div className="modal-close" onClick={handleClose}></div>
					<h2>Tarjetas Guardadas</h2>
					<div className="cards-content">
						{renderAddCard()}
						<div className="cards-headers">
							<div>
								<div>Num</div>
								<div>Vence</div>
								<div>Tipo</div>
								<div>&nbsp;</div>
								<div>&nbsp;</div>
							</div>
						</div>
						{renderCardList()}
					</div>
				</div>
			</ReactModal>
		</div>
	);
}
