import { createSlice } from "@reduxjs/toolkit";
import ReactGA from "react-ga4";
import { BASE_API_URL } from "../constants";
import { getMatchingItemsTireSize } from "features/functions";
import { sessionService } from "redux-react-session";
import axios from "axios";
import { runUnauthorizedActions } from "features/account/loginSlice";

const initialState = {
	isLoading: false,
	value: "",
	selectedItem: {},
	suggestions: [],
	list: null,
	renderInput: false,
	showInput: false,
};

const sizeSaeWidths = createSlice({
	name: "sizeSaeWidths",
	initialState,
	reducers: {
		requestWidthSaesList(state) {
			state.isLoading = true;
		},
		receiveWidthSaesList(state, action) {
			state.isLoading = false;
			state.list = action.payload.list;
			state.renderInput = action.payload.renderInput;
		},
		loadWidthSaesSuggestionsBegin(state) {
			state.isLoading = true;
		},
		maybeUpdateWidthSaesSuggestions(state, action) {
			state.isLoading = false;
			if (state.value === action.payload.value) {
				state.suggestions = action.payload.suggestions;
			}
		},
		updateWidthSaeValue(state, action) {
			state.value = action.payload.value;
		},
		updateWidthSaeItem(state, action) {
			const selectedItem = action.payload.selectedItem;
			if (selectedItem?.Value != null) {
				ReactGA.event({
					category: "Medida SAE",
					action: "Seleccionar Ancho",
					label: selectedItem.Value + "",
					value: selectedItem.Value,
				});
			}
			state.selectedItem = selectedItem;
		},
		clearWidthSaesSuggestions(state) {
			state.suggestions = [];
		},
		clearWidthSaeValue(state, action) {
			state.value = "";
			state.selectedItem = {};
			state.list = null;
			state.renderInput = action.payload.renderInput;
			state.showInput = action.payload.showInput;
		},
		clearWidthSaeData: () => initialState,
	},
});

export const {
	requestWidthSaesList,
	receiveWidthSaesList,
	loadWidthSaesSuggestionsBegin,
	maybeUpdateWidthSaesSuggestions,
	updateWidthSaeValue,
	updateWidthSaeItem,
	clearWidthSaesSuggestions,
	clearWidthSaeValue,
	clearWidthSaeData,
} = sizeSaeWidths.actions;

const shouldFetchWidthSaes = (state, newDiameter) => {
	if (state.sizeSaeWidths.list == null) {
		return true;
	}
	const oldDiameter = state.sizeWidthDiameters.selectedItem;
	if (oldDiameter !== newDiameter) {
		return true;
	}
	return false;
};
export const fetchWidthSaesIfNeeded =
	(selectedDiameter) => async (dispatch, getState) => {
		if (shouldFetchWidthSaes(getState(), selectedDiameter)) {
			const theState = getState();
			return dispatch(
				fetchWidthsSae(
					theState.vehicleType.value,
					selectedDiameter.OriginalValue
				)
			);
		}
	};
export const loadWidthSaesSuggestions =
	(value) => async (dispatch, getState) => {
		dispatch(loadWidthSaesSuggestionsBegin());
		let theState = getState();
		if (theState.sizeSaeWidths.list == null) {
			dispatch(
				fetchWidthsSae(
					theState.vehicleType.value,
					theState.sizeWidthDiameters.selectedItem.OriginalValue
				)
			);
		}
		theState = getState();
		return setTimeout(() => {
			dispatch(
				maybeUpdateWidthSaesSuggestions({
					suggestions: getMatchingItemsTireSize(theState.sizeSaeWidths, value),
					value,
				})
			);
		});
	};
export const fetchWidthsSae = (dept, originalValue) => async (dispatch) => {
	sessionService
		.loadSession()
		.then((session) => {
			const headers = {
				Authorization: "bearer " + session.loginData.access_token,
				"Content-Type": "application/json",
			};
			const options = {
				method: "GET",
				headers: headers,
			};
			dispatch(requestWidthSaesList());
			const url =
				BASE_API_URL +
				"/api/Empleados/Medidas/AnchoSAE/?depto=" +
				dept +
				"&marca=0&diametro=" +
				originalValue;
			return axios
				.get(url, options)
				.then((response) => {
					dispatch(
						receiveWidthSaesList({ list: response.data, renderInput: true })
					);
				})
				.catch((error) => {
					// If 401 Unauthorized login failed
					if (error.response.status === 401) {
						dispatch(runUnauthorizedActions());
					}
				});
		})
		.catch((err) => console.log("Error", err));
};

export default sizeSaeWidths.reducer;
