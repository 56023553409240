import { createSlice } from "@reduxjs/toolkit";
import { sessionService } from "redux-react-session";
import axios from "axios";
import { runUnauthorizedActions } from "features/account/loginSlice";
import { BASE_API_URL } from "../constants";

const initialState = {
	isLoading: false,
	list: null,
	hide: false,
};

const highlightsTires = createSlice({
	name: "highlightsTires",
	initialState,
	reducers: {
		requestHighlightsTiresList(state) {
			state.isLoading = true;
		},
		receiveHighlightsTiresList(state, action) {
			state.isLoading = false;
			state.list = action.payload.list;
		},
		clearHighlightsTiresList(state) {
			state.list = null;
		},
		hideHighlightsTiresList(state, action) {
			state.hide = action.payload.hide;
		},
		clearHighlightsData: () => initialState,
	},
});

export const {
	requestHighlightsTiresList,
	receiveHighlightsTiresList,
	clearHighlightsTiresList,
	hideHighlightsTiresList,
	clearHighlightsData,
} = highlightsTires.actions;

const shouldFetchHighlightsTiresList = (state) => {
	if (state.highlightsTires.list == null) {
		return true;
	}
	return false;
};

export const fetchHighlightsTiresListIfNeeded =
	() => async (dispatch, getState) => {
		const theState = getState();
		if (shouldFetchHighlightsTiresList(theState)) {
			return dispatch(fetchHighlightsTiresList());
		}
	};

export const fetchHighlightsTiresList = () => async (dispatch, getState) => {
	sessionService
		.loadSession()
		.then((session) => {
			const headers = {
				Authorization: "bearer " + session.loginData.access_token,
				"Content-Type": "application/json",
			};
			const options = {
				//method: "GET",
				headers: headers,
			};
			const theState = getState();
			const coupon =
				theState.cart.coupon == null ? null : theState.cart.coupon.Code;
			dispatch(requestHighlightsTiresList());
			const url =
				coupon != null
					? BASE_API_URL + "/api/Empleados/Productos/Home/?cupon=" + coupon
					: BASE_API_URL + "/api/Empleados/Productos/Home";
			return axios
				.get(url, options)
				.then((response) => {
					dispatch(receiveHighlightsTiresList({ list: response.data }));
				})
				.catch((error) => {
					// If 401 Unauthorized login failed
					if (error.response.status === 401) {
						dispatch(runUnauthorizedActions());
					}
				});
		})
		.catch((err) => console.log("Error", err));
};

export default highlightsTires.reducer;
