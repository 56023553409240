import React from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactGA from "react-ga4";
import ReactModal from "react-modal";
import { setAlert, toggleShowAlert } from "features/alert/alertSlice";

export default function AlertModal() {
	const dispatch = useDispatch();
	// Selectors
	const { isOpen, alert } = useSelector((state) => state.alert);
	// Handlers
	function handleCloseClick() {
		ReactGA.send({ hitType: "pageview", page: "/" });
		dispatch(setAlert({ alert: null }));
		dispatch(toggleShowAlert({ isOpen: true }));
	}
	// Render
	if (alert == null) {
		return null;
	}
	return (
		<div>
			<ReactModal
				isOpen={isOpen}
				className="modal small"
				overlayClassName="modal-overlay"
				onRequestClose={handleCloseClick}
				contentLabel="Alert"
			>
				<div className="modal-inner" id="alert-inner">
					<div className="modal-close" onClick={handleCloseClick}></div>
					<h2>{alert.title}</h2>
					<div className="alert-main">
						<div>{alert.description}</div>
						<p>
							<em>{alert.details}</em>
						</p>
						<div className="modal-button alternate" onClick={handleCloseClick}>
							Cerrar
						</div>
					</div>
				</div>
			</ReactModal>
		</div>
	);
}
