import { createSlice } from "@reduxjs/toolkit";
import ReactGA from "react-ga4";
import { BASE_API_URL } from "../constants";
import { getMatchingItemsTireSize } from "features/functions";
import { sessionService } from "redux-react-session";
import axios from "axios";
import { runUnauthorizedActions } from "features/account/loginSlice";

const initialState = {
	isLoading: false,
	value: "",
	selectedItem: {},
	suggestions: [],
	list: null,
	renderInput: false,
	showInput: false,
};

const sizeSaeRims = createSlice({
	name: "sizeSaeRims",
	initialState,
	reducers: {
		requestSizeRimSaesList(state) {
			state.isLoading = true;
		},
		receiveSizeRimSaesList(state, action) {
			state.isLoading = false;
			state.list = action.payload.list;
			state.renderInput = action.payload.renderInput;
		},
		loadSizeRimSaesSuggestionsBegin(state) {
			state.isLoading = true;
		},
		maybeUpdateSizeRimSaesSuggestions(state, action) {
			state.isLoading = false;
			if (state.value === action.payload.value) {
				state.suggestions = action.payload.suggestions;
			}
		},
		updateSizeRimSaeValue(state, action) {
			state.value = action.payload.value;
		},
		updateSizeRimSaeItem(state, action) {
			const selectedItem = action.payload.selectedItem;
			if (selectedItem?.Value != null) {
				ReactGA.event({
					category: "Medida SAE",
					action: "Seleccionar Rim",
					label: selectedItem.Value + "",
					value: selectedItem.Value,
				});
			}
			state.selectedItem = selectedItem;
		},
		clearSizeRimSaesSuggestions(state) {
			state.suggestions = [];
		},
		clearSizeRimSaeValue(state, action) {
			state.value = "";
			state.selectedItem = {};
			state.list = null;
			state.renderInput = action.payload.renderInput;
			state.showInput = action.payload.showInput;
		},
		clearSizeRimSaesData: () => initialState,
	},
});

export const {
	requestSizeRimSaesList,
	receiveSizeRimSaesList,
	loadSizeRimSaesSuggestionsBegin,
	maybeUpdateSizeRimSaesSuggestions,
	updateSizeRimSaeValue,
	updateSizeRimSaeItem,
	clearSizeRimSaesSuggestions,
	clearSizeRimSaeValue,
	clearSizeRimSaesData,
} = sizeSaeRims.actions;

const shouldFetchSizeRimSaes = (state, newWidth) => {
	if (state.sizeSaeRims.list == null) {
		return true;
	}
	const oldWidth = state.sizeSaeWidths.selectedItem;
	if (oldWidth !== newWidth) {
		return true;
	}
	return false;
};
export const fetchSizeRimSaesIfNeeded =
	(selectedWidth) => async (dispatch, getState) => {
		if (shouldFetchSizeRimSaes(getState(), selectedWidth)) {
			const theState = getState();
			return dispatch(
				fetchSizeRimSaes(
					theState.vehicleType.value,
					theState.sizeWidthDiameters.selectedItem.OriginalValue,
					selectedWidth.OriginalValue
				)
			);
		}
	};
export const loadSizeRimSaesSuggestions =
	(value) => async (dispatch, getState) => {
		dispatch(loadSizeRimSaesSuggestionsBegin());
		let theState = getState();
		if (theState.sizeSaeRims.list == null) {
			dispatch(
				fetchSizeRimSaes(
					theState.vehicleType.value,
					theState.sizeWidthDiameters.selectedItem.OriginalValue,
					theState.sizeSaeWidths.selectedItem.OriginalValue
				)
			);
		}
		theState = getState();
		return setTimeout(() => {
			dispatch(
				maybeUpdateSizeRimSaesSuggestions({
					suggestions: getMatchingItemsTireSize(theState.sizeSaeRims, value),
					value,
				})
			);
		});
	};
export const fetchSizeRimSaes = (dept, diameter, width) => async (dispatch) => {
	sessionService
		.loadSession()
		.then((session) => {
			const headers = {
				Authorization: "bearer " + session.loginData.access_token,
				"Content-Type": "application/json",
			};
			const options = {
				headers: headers,
			};
			const url =
				BASE_API_URL +
				"/api/Empleados/Medidas/RinSAE/?depto=" +
				dept +
				"&marca=0&diametro=" +
				+diameter +
				"&anchoseccion=" +
				width;
			dispatch(requestSizeRimSaesList());
			return axios
				.get(url, options)
				.then((response) => {
					dispatch(
						receiveSizeRimSaesList({ list: response.data, renderInput: true })
					);
				})
				.catch((error) => {
					// If 401 Unauthorized login failed
					if (error.response.status === 401) {
						dispatch(runUnauthorizedActions());
					}
				});
		})
		.catch((err) => console.log("Error", err));
};

export default sizeSaeRims.reducer;
