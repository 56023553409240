import { createSlice } from "@reduxjs/toolkit";
import { sessionService } from "redux-react-session";
import { BASE_API_URL } from "../constants";
import { runUnauthorizedActions } from "../account/loginSlice";
import axios from "axios";

const initialState = {
	isLoading: false,
	list: [],
};

const banners = createSlice({
	name: "banners",
	initialState,
	reducers: {
		requestBanners(state) {
			state.isLoading = true;
		},
		receiveBanners(state, action) {
			state.isLoading = false;
			state.list = action.payload.list;
		},
		clearBannersData: () => initialState,
	},
});

export const { requestBanners, receiveBanners, clearBannersData } =
	banners.actions;

export const fetchBannersList = () => async (dispatch) => {
	sessionService
		.loadSession()
		.then((session) => {
			const headers = {
				Authorization: "bearer " + session.loginData.access_token,
				"Content-Type": "application/json",
			};
			const options = {
				headers: headers,
			};
			const url = BASE_API_URL + "/api/Empleados/Banners";
			dispatch(requestBanners());
			return axios
				.get(url, options)
				.then((response) => {
					dispatch(receiveBanners({ list: response.data }));
				})
				.catch((error) => {
					// If 401 Unauthorized login failed
					if (error.response.status === 401) {
						dispatch(runUnauthorizedActions());
					}
				});
		})
		.catch((err) => console.log("Error", err));
};

export default banners.reducer;
