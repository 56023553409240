import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Dropdown from "react-dropdown";
import {
	validateCardYear,
	validateCardMonth,
} from "features/functions";
import { OPENPAY_TOKEN_ACTION, OPENPAY_CARD_LIMIT } from "features/constants";
import { cartLoadOpenPay, setPaymentProcess } from "features/cart/cartSlice";
import {
	setCardAddId,
	setCardAddErrorMsg,
	fetchCardToken,
} from "features/account/accountCardAddSlice";
import Loader from "components/Loader";

export default function OpenPayCardForm(props) {
	const dispatch = useDispatch();
	// Selectors
	const { errorMsg, sessId } = useSelector((state) => state.accountCardAdd);
	const isLoadingCart = useSelector((state) => state.cart.isLoading);
	const { openPayLoaded, cartObject } = useSelector((state) => state.cart);
	const promoList = useSelector((state) => state.paymentCard.list);
	const cardList = useSelector((state) => state.accountCards.list);
	// State
	const [cardValidators, setCardValidators] = useState(null);
	const [name, setName] = useState("");
	const [card, setCard] = useState("");
	const [cardType, setCardType] = useState(null);
	const [month, setMonth] = useState("");
	const [year, setYear] = useState("");
	const [code, setCode] = useState("");
	const [save, setSave] = useState(false);
	const [promo, setPromo] = useState(null);
	// Effect
	useEffect(() => {
			// OpenPay not loaded
			if (!openPayLoaded) {
				dispatch(cartLoadOpenPay());
				// Antifraud not loaded
			} else if (sessId == null) {
				dispatch(setCardAddId({ sessId: window.OpenPay.deviceData.setup() }));
				// Antifraud Loaded
			} else {
				let newCardValidators = window.OpenPay.card;
				setCardValidators({
					validateCardNumber: newCardValidators.validateCardNumber,
					validateCVC: newCardValidators.validateCVC,
					validateExpiry: newCardValidators.validateExpiry,
					cardType: newCardValidators.cardType,
				});
			}
	}, [openPayLoaded, sessId, isLoadingCart, dispatch]);
	// Handlers
	function handleReset() {
		setName("");
		setCard("");
		setMonth("");
		setYear("");
		setCode("");
		setSave(false);
		setPromo(null);
		dispatch(setCardAddErrorMsg({ errorMsg: null }));
	}
	function handlePrevClick() {
		handleReset();
		props.onPrev();
	}
	function handleNameChange(e) {
		const value = e.target.value.toUpperCase().replace(/[^A-Z0-9 .Ñ]/g, "");
		if (errorMsg != null && errorMsg.name != null) {
			dispatch(
				setCardAddErrorMsg({
					errorMsg: { ...errorMsg, name: null, error: null },
				})
			);
		}
		setName(value);
	}
	function validateName(cardName) {
		if (cardName.trim().length === 0) {
			return { isValid: false, error: "Debes escribir el nombre del titular." };
		} else if (cardName.trim().length < 5) {
			return { isValid: false, error: "Debes escribir un nombre válido." };
		}
		return { isValid: true, error: null };
	}
	function handleNameBlur(e) {
		const validatedName = validateName(e.target.value);
		if (!validatedName.isValid) {
			dispatch(
				setCardAddErrorMsg({
					errorMsg: {
						...errorMsg,
						name: validatedName.error,
					},
				})
			);
		}
	}
	function handleCardChange(e) {
		const value = e.target.value.replace(/[^0-9]/g, "");
		if (errorMsg != null && errorMsg.card != null) {
			dispatch(
				setCardAddErrorMsg({
					errorMsg: { ...errorMsg, card: null, error: null },
				})
			);
		}
		setCardType(null);
		setCard(value);
	}
	function validateCard(cardNumber) {
		if (cardNumber.trim().length === 0) {
			return {
				isValid: false,
				error: "Debes escribir el número de la tarjeta.",
			};
		}
		const isValid = cardValidators.validateCardNumber(cardNumber);
		if (!isValid) {
			setCardType(null);
			return { isValid: false, error: "El número de tarjeta no es válido." };
		}
		const cType = cardValidators.cardType(cardNumber.trim());
		setCardType(cType);
		return { isValid: true, error: null };
	}
	function handleCardBlur(e) {
		const validatedCard = validateCard(e.target.value);
		if (!validatedCard.isValid) {
			dispatch(
				setCardAddErrorMsg({
					errorMsg: {
						...errorMsg,
						card: validatedCard.error,
					},
				})
			);
		}
	}
	function handleMonthChange(e) {
		const value = e.target.value.replace(/[^0-9]/g, "");
		if (
			errorMsg != null &&
			(errorMsg.month != null || errorMsg.expiry != null)
		) {
			dispatch(
				setCardAddErrorMsg({
					errorMsg: { ...errorMsg, month: null, expiry: null, error: null },
				})
			);
		}
		setMonth(value);
	}
	function validateMonth(cardMonth) {
		if (cardMonth.trim().length === 0) {
			return { isValid: false, error: "Debes escribir el mes de vencimiento." };
		}
		if (!validateCardMonth(cardMonth)) {
			return { isValid: false, error: "El mes de vencimiento no es válido." };
		}
		return { isValid: true, error: null };
	}
	function handleMonthdBlur(e) {
		const validatedMonth = validateMonth(e.target.value);
		if (!validatedMonth.isValid) {
			dispatch(
				setCardAddErrorMsg({
					errorMsg: {
						...errorMsg,
						month: validatedMonth.error,
					},
				})
			);
		}
	}
	function handleYearChange(e) {
		if (
			errorMsg != null &&
			(errorMsg.year != null || errorMsg.expiry != null)
		) {
			dispatch(
				setCardAddErrorMsg({
					errorMsg: { ...errorMsg, year: null, expiry: null, error: null },
				})
			);
		}
		setYear(e.target.value);
	}
	function validateYear(cardYear) {
		if (cardYear.trim().length === 0) {
			return {
				isValid: false,
				error: "Debes escribir el año de vencimiento.",
				expiry: false,
			};
		}
		if (!validateCardYear(cardYear)) {
			return {
				isValid: false,
				error: "El año de vencimiento no es válido.",
				expiry: false,
			};
		}
		const validExpiry = cardValidators.validateExpiry(month, cardYear);
		if (!validExpiry) {
			return {
				isValid: false,
				error: "La fecha de vencimiento no es válida.",
				expiry: true,
			};
		}
		return { isValid: true, error: null };
	}
	function handleYearBlur(e) {
		const validatedYear = validateYear(e.target.value);
		if (!validatedYear.isValid) {
			if (!validatedYear.expiry) {
				dispatch(
					setCardAddErrorMsg({
						errorMsg: {
							...errorMsg,
							year: validatedYear.error,
						},
					})
				);
			} else {
				dispatch(
					setCardAddErrorMsg({
						errorMsg: {
							...errorMsg,
							expiry: validatedYear.error,
						},
					})
				);
			}
		}
	}
	function handleSaveCardChange(e) {
		setSave(e.target.checked);
	}
	function validateCode(cardCode) {
		if (cardCode.trim().length === 0) {
			return {
				isValid: false,
				error: "Debes escribir el código de seguridad.",
			};
		}
		const validCode = cardValidators.validateCVC(cardCode);
		if (!validCode) {
			return { isValid: false, error: "El código de seguridad no es válido." };
		}
		if (cardType == null) {
			if (validateCard(card)) {
				setCardType(cardValidators.cardType(card));
			}
		}
		if (cardType === "American Express" && cardCode.trim().length !== 4) {
			return { isValid: false, error: "El código de seguridad no es válido." };
		}
		if (cardType !== "American Express" && cardCode.trim().length !== 3) {
			return { isValid: false, error: "El código de seguridad no es válido." };
		}
		return { isValid: true, error: null };
	}
	function handleCodeChange(e) {
		const value = e.target.value.replace(/[^0-9]/g, "");
		if (errorMsg != null && errorMsg.code != null) {
			dispatch(
				setCardAddErrorMsg({
					errorMsg: { ...errorMsg, code: null, error: null },
				})
			);
		}
		setCode(value);
	}
	function handleCodeBlur(e) {
		const validatedCode = validateCode(e.target.value);
		if (!validatedCode.isValid) {
			dispatch(
				setCardAddErrorMsg({
					errorMsg: {
						...errorMsg,
						code: validatedCode.error,
					},
				})
			);
		}
	}
	function handlePromoChange(option) {
		setPromo(option);
	}
	function handleSubmit(e) {
		e.preventDefault();
		let valid = true;
		let errorMessages = { ...errorMsg };
		const validName = validateName(name);
		if (!validName.isValid) {
			errorMessages = {
				...errorMessages,
				name: validName.error,
			};
			valid = false;
		}
		const validCard = validateCard(card);
		if (!validCard.isValid) {
			errorMessages = {
				...errorMessages,
				card: validCard.error,
			};
			valid = false;
		}
		const validMonth = validateMonth(month);
		if (!validMonth.isValid) {
			errorMessages = {
				...errorMessages,
				month: validMonth.error,
			};
			valid = false;
		}
		const validYear = validateYear(year);
		if (!validYear.isValid) {
			errorMessages = !validYear.expiry
				? {
						...errorMessages,
						year: validYear.error,
				  }
				: {
						...errorMessages,
						expiry: validYear.error,
				  };
			valid = false;
		}
		const validCode = validateCode(code);
		if (!validCode.isValid) {
			errorMessages = {
				...errorMessages,
				code: validCode.error,
			};
			valid = false;
		}
		if (!valid) {
			dispatch(setCardAddErrorMsg({ errorMsg: errorMessages }));
		} else {
			const data = {
				card,
				name,
				year,
				month,
				code,
				sessId,
				save,
				cardPromo: promo != null ? promo.value : 0,
			};
			dispatch(setPaymentProcess({ isProcessing: true }));
			dispatch(fetchCardToken(data, OPENPAY_TOKEN_ACTION.PAY));
		}
	}
	function renderError(error) {
		if (error != null) {
			return <li>{error}</li>;
		}
		return null;
	}
	function renderErrors() {
		if (errorMsg != null) {
			return (
				<div className="error-list">
					<ul>
						{renderError(errorMsg.error)}
						{renderError(errorMsg.name)}
						{renderError(errorMsg.card)}
						{renderError(errorMsg.month)}
						{renderError(errorMsg.year)}
						{renderError(errorMsg.expiry)}
						{renderError(errorMsg.code)}
					</ul>
				</div>
			);
		}
		return null;
	}
	// Render
	if (!openPayLoaded || sessId == null) {
		return <Loader msg="Cargando..."></Loader>;
	}
	function getFieldProps(property) {
		if (errorMsg == null) {
			if (property === "month" || property === "year" || property === "code") {
				return { className: "half" };
			}
			return {};
		}
		if (
			(property === "month" || property === "year") &&
			"expiry" in errorMsg &&
			errorMsg["expiry"] != null
		) {
			return { className: "half error" };
		}
		if (property in errorMsg && errorMsg[property] != null) {
			if (property === "month" || property === "year" || property === "code") {
				return { className: "half error" };
			}
			return { className: "error" };
		}
		if (property === "month" || property === "year" || property === "code") {
			return { className: "half" };
		}
		return {};
	}
	function renderPromoDropdown() {
		if (promoList != null && promoList.length > 1) {
			const filteredList = promoList.filter(
				(el) => cartObject.Total >= el.Minimo
			);
			const options = filteredList.map((el) => {
				return {
					value: el.Id,
					label: el.Nombre + " " + el.Mensaje,
				};
			});
			const selectedOption = promo == null ? options[0] : promo;
			return (
				<div>
					<div>
						<label>Meses sin Intereses</label>
						<div>
							<Dropdown
								options={options}
								onChange={handlePromoChange}
								value={selectedOption}
								placeholder="Seleccione"
							/>
						</div>
					</div>
				</div>
			);
		}
		return null;
	}
	function renderSaveCard() {
		if (cardList == null || (cardList != null && cardList.length < OPENPAY_CARD_LIMIT)) {
			return (
				<div>
					<div className="full">
						<div className="switch">
							<input
								type="checkbox"
								id="save-card"
								name="save-card"
								checked={save === true}
								onChange={handleSaveCardChange}
							/>
							<label htmlFor="save-card">
								<span className="icon-switch"></span>
								<div>Deseo guardar esta tarjeta para compras futuras</div>
							</label>
						</div>
					</div>
				</div>
			);
		}
		return null;
	}
	return (
		<div className="cart-card">
			<form className="card-form" onSubmit={handleSubmit}>
				<div className="full openpay-cards">
					<div>
						<div>
							<div>Tarjetas de Crédito</div>
							<div>
								<img
									src="/images/cart/openpay-tarjetas.png"
									alt="Tarjetas de crédito"
								/>
							</div>
						</div>
						<div>
							<div>Tarjetas de Débito</div>
							<div>
								<img
									src="/images/cart/openpay-debito1.png"
									alt="tarjetas de débito"
								/>
								<img
									src="/images/cart/openpay-debito2.png"
									alt="tarjetas de débito"
								/>
							</div>
						</div>
					</div>
				</div>
				<div>
					<div {...getFieldProps("card")}>
						<label>Número de tarjeta</label>
						<input
							type="text"
							className="modal-field"
							autoComplete="off"
							placeholder="Número de tarjeta"
							data-openpay-card="card_number"
							value={card}
							onChange={handleCardChange}
							onBlur={handleCardBlur}
						></input>
					</div>
					<div {...getFieldProps("name")}>
						<label>Nombre del titular</label>
						<input
							type="text"
							className="modal-field"
							placeholder="Como aparece en la tarjeta"
							value={name}
							autoComplete="off"
							data-openpay-card="holder_name"
							onChange={handleNameChange}
							onBlur={handleNameBlur}
						></input>
					</div>
				</div>
				<div>
					<div {...getFieldProps("month")}>
						<label>Fecha de expiración</label>
						<input
							type="text"
							className="modal-field"
							placeholder="Mes"
							maxLength={2}
							data-openpay-card="expiration_month"
							value={month}
							onChange={handleMonthChange}
							onBlur={handleMonthdBlur}
						></input>
					</div>
					<div {...getFieldProps("year")}>
						<input
							type="text"
							className="modal-field"
							placeholder="Año"
							maxLength={2}
							data-openpay-card="expiration_year"
							value={year}
							onChange={handleYearChange}
							onBlur={handleYearBlur}
						></input>
					</div>
					<div {...getFieldProps("code")}>
						<label>Código de seguridad</label>
						<input
							type="text"
							className="modal-field"
							placeholder="CVV"
							maxLength={4}
							autoComplete="off"
							data-openpay-card="cvv2"
							value={code}
							onChange={handleCodeChange}
							onBlur={handleCodeBlur}
						></input>
					</div>
					<div className="half">
						<img
							className="openpay-cvv"
							src="/images/cart/openpay-cvv.png"
							alt="Código de seguridad"
						/>
					</div>
				</div>
				{renderPromoDropdown()}
				{renderErrors()}
				<div className="full right">
					<div className="openpay-brand">
						<div>
							<div className="openpay-powered">
								<div>Transacciones realizadas vía:</div>
								<div>
									<img src="/images/cart/openpay.png" alt="Openpay" />
								</div>
							</div>
							<div>
								<div>
									<img
										src="/images/cart/security.png"
										alt="Secure"
										style={{ maxWidth: "22px" }}
									/>
								</div>
							</div>
							<div>
								<div>
									Tus pagos se realizan de forma segura con encriptación de 256
									bits
								</div>
							</div>
						</div>
					</div>
				</div>
				{renderSaveCard()}
			</form>
			<div className="openpay-checkout">
				<div>
					<div>
						<div className="modal-button" onClick={handlePrevClick}>
							Anterior
						</div>
						<div className="modal-button alternate" onClick={handleSubmit}>
							Finalizar
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
