import { createSlice } from "@reduxjs/toolkit";
import ReactGA from "react-ga4";
import { BASE_API_URL, VEHICLE_TYPES } from "../constants";
import { getMatchingItemsTireSizeShort } from "features/functions";
import { sessionService } from "redux-react-session";
import axios from "axios";
import { runUnauthorizedActions } from "features/account/loginSlice";

const initialState = {
	isLoading: false,
	value: "",
	selectedItem: {},
	suggestions: [],
	list: null,
};

const sizeShort = createSlice({
	name: "sizeShort",
	initialState,
	reducers: {
		requestSizeShortList(state) {
			state.isLoading = true;
		},
		receiveSizeShortList(state, action) {
			state.isLoading = false;
			state.list = action.payload.list;
		},
		loadSizeShortSuggestionsBegin(state) {
			state.isLoading = true;
		},
		maybeUpdateSizeShortSuggestions(state, action) {
			state.isLoading = false;
			if (state.value === action.payload.value) {
				state.suggestions = action.payload.suggestions;
			}
		},
		updateSizeShortValue(state, action) {
			state.value = action.payload.value;
		},
		updateSizeShortItem(state, action) {
			const selectedItem = action.payload.selectedItem;
			if (selectedItem?.Value != null) {
					ReactGA.event({
						category: "Medida",
						action: "Seleccionar Medida",
						label: selectedItem.Value + ""
					});
			}
			state.selectedItem = selectedItem;
		},
		clearSizeShortSuggestions(state) {
			state.suggestions = [];
		},
		clearSizeShortValue(state) {
			state.value = "";
			state.selectedItem = {};
		},
		clearSizeShortData: () => initialState,
	},
});

export const {
	requestSizeShortList,
	receiveSizeShortList,
	loadSizeShortSuggestionsBegin,
	maybeUpdateSizeShortSuggestions,
	updateSizeShortValue,
	updateSizeShortItem,
	clearSizeShortSuggestions,
	clearSizeShortValue,
	clearSizeShortData,
} = sizeShort.actions;

const shouldFetchSizeShort = (state, value) => {
	if (state.sizeShort.list == null) {
		return true;
	}
	if (state.sizeShort.value !== value) {
		return true;
	}
	return false;
};
export const fetchSizeShortIfNeeded =
	(value = "") =>
	async (dispatch, getState) => {
		if (shouldFetchSizeShort(getState(), value)) {
			return dispatch(fetchSizeShort(VEHICLE_TYPES.TRUCK));
		}
	};
export const loadSizeShortSuggestions =
	(value) => async (dispatch, getState) => {
		dispatch(loadSizeShortSuggestionsBegin());
		// dispatch(fetchSizeShortIfNeeded());
		const theState = getState();
		setTimeout(() => {
			dispatch(
				maybeUpdateSizeShortSuggestions({
					suggestions: getMatchingItemsTireSizeShort(
						theState.sizeShort,
						value
					),
					value,
				})
			);
		});
	};
export const fetchSizeShort = (dept) => async (dispatch) => {
	sessionService
		.loadSession()
		.then((session) => {
			const headers = {
				Authorization: "bearer " + session.loginData.access_token,
				"Content-Type": "application/json",
			};
			const options = {
				headers: headers,
			};
			const url = BASE_API_URL + "/api/Empleados/Medidas/Corta/?depto=" + dept;
			dispatch(requestSizeShortList());
			return axios
				.get(url, options)
				.then((response) => {
					dispatch(receiveSizeShortList({ list: response.data }));
				})
				.catch((error) => {
					// If 401 Unauthorized login failed
					if (error.response.status === 401) {
						dispatch(runUnauthorizedActions());
					}
				});
		})
		.catch((err) => console.log("Error", err));
};

export default sizeShort.reducer;
