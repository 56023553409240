import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { runUnauthorizedActions } from "features/account/loginSlice";
import ReactGA from "react-ga4";
import { sessionService } from "redux-react-session";
import { BASE_API_URL } from "../constants";

const initialState = {
	isLoading: false,
	list: null,
};
const vehicleTires = createSlice({
	name: "vehicleTires",
	initialState,
	reducers: {
		requestVehicleTiresList(state) {
			state.isLoading = true;
		},
		receiveVehicleTiresList(state, action) {
			state.isLoading = false;
			state.list = action.payload.list;
		},
		clearVehicleTiresData: () => initialState,
	},
});

export const {
	requestVehicleTiresList,
	receiveVehicleTiresList,
	clearVehicleTiresData,
} = vehicleTires.actions;

const shouldFetchVehicleTiresList = (state) => {
	const selectedBrand = state.vehicleBrands.selectedItem;
	const selectedModel = state.vehicleModels.selectedItem;
	const selectedYear = state.vehicleYears.selectedItem;
	const selectedVersion = state.vehicleVersions.selectedItem;
	const tires = state.vehicleTires.list;
	if (selectedVersion == null) {
		return false;
	}
	if (tires == null) {
		return true;
	}
	if (
		selectedBrand?.ID !== tires.BrandId ||
		selectedModel?.ID !== tires.ModelId ||
		selectedYear?.anio !== tires.Year ||
		selectedVersion?.ID !== tires.VersionId
	) {
		return true;
	}
	return false;
};

export const fetchVehicleTiresListIfNeeded =
	() => async (dispatch, getState) => {
		const theState = getState();
		if (shouldFetchVehicleTiresList(theState)) {
			return dispatch(
				fetchVehicleTiresList(
					theState.vehicleBrands.selectedItem.ID,
					theState.vehicleModels.selectedItem.ID,
					theState.vehicleYears.selectedItem.anio,
					theState.vehicleVersions.selectedItem.ID
				)
			);
		}
	};

export const fetchVehicleTiresList =
	(brand, model, year, version) => async (dispatch, getState) => {
		sessionService
			.loadSession()
			.then((session) => {
				const headers = {
					Authorization: "bearer " + session.loginData.access_token,
					"Content-Type": "application/json",
				};
					const options = {
					method: "GET",
					headers: headers,
				};
				const theState = getState();
				const coupon =
					theState.cart.coupon == null ? null : theState.cart.coupon.Code;
				dispatch(requestVehicleTiresList());
				const url =
					coupon != null
						? BASE_API_URL +
						  "/api/Empleados/Vehiculos/Buscar/?marca=" +
						  brand +
						  "&modelo=" +
						  model +
						  "&anio=" +
						  year +
						  "&version=" +
						  version +
						  "&cupon=" +
						  coupon
						: BASE_API_URL +
						  "/api/Empleados/Vehiculos/Buscar?marca=" +
						  brand +
						  "&modelo=" +
						  model +
						  "&anio=" +
						  year +
						  "&version=" +
						  version;
				return axios
					.get(url, options)
					.then((response) => {
						ReactGA.event({
							category: "Llantas",
							action: "Vehículo",
							label: response.data.Name + " " + response.data.Year,
							value: response.data.Id
						});
						dispatch(receiveVehicleTiresList({ list: response.data }));
					})
					.catch((error) => {
						// If 401 Unauthorized login failed
						if (error.response.status === 401) {
							dispatch(runUnauthorizedActions());
						}
					});
			})
			.catch((err) => console.log("Error", err));
	};

export default vehicleTires.reducer;
