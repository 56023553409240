import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import ReactGA from "react-ga4";
import Scroll from "react-scroll";
import { IMAGE_TYPES } from "features/constants";
import {
	fetchAddItemToCartObject,
	setCartActiveTab,
	toggleShowCart,
} from "features/cart/cartSlice";
import {
	imageFormat,
	getBrandClass,
	getVehicleTypeClass,
	renderPrice,
	renderOriginalPrice,
	formatDetails,
	renderRadial,
	renderIndiceVelocidad,
	renderIndiceCarga,
	renderSsr,
	renderDureza,
	renderCamara,
} from "features/functions";
import ProductDetailsQty from "./ProductDetailsQty";

const Element = Scroll.Element;

export default function TireDetails(props) {
	const dispatch = useDispatch();
	// State
	const [qty, setQty] = useState(1);
	const [showDetails, setShowDetails] = useState(false);
	const defaultQty = 1;
	// Effects
	useEffect(() => {
		setShowDetails(false);
	}, []);
	// Handlers
	function handleQtyChange(value) {
		setQty(value);
	}
	function handleQtyBlur(qty) {
		if (qty === 0) {
			setQty(defaultQty);
		}
	}
	function handleBuyClick() {
		const tire = props;
		const product = props.TireProduct;
		// const data = {
		// 	Brand: product.Brand,
		// 	Name: product.Name,
		// 	Code: tire.Code,
		// 	Size: tire.Size,
		// 	qty,
		// };
		ReactGA.gtag("event", "add_to_cart", {
			currency: "MXN",
			value: tire.ListPrice * qty,
			items: [
				{
					item_id: tire.Code,
					item_name: product.Name,
					item_brand: product.Brand,
					item_category: "Llantas/" + tire.VehicleType,
					item_variant: tire.Size,
					price: tire.ListPrice,
					quantity: qty,
				},
			],
		});
		ReactGA.event({
			category: "Compra",
			action: "Agregar Producto",
			label: props.Code,
			value: qty,
		});
		dispatch(fetchAddItemToCartObject(props.Code, qty));
		dispatch(setCartActiveTab({ showTab: 1 }));
		dispatch(toggleShowCart({ isOpen: false }));
	}
	function toggleDetailsClick() {
		if (showDetails) {
			ReactGA.event({
				category: "Producto",
				action: "Mostrar Imagen",
				label: props.Code,
			});
		} else {
			ReactGA.event({
				category: "Producto",
				action: "Mostrar Detalles",
				label: props.Code,
			});
		}
		setShowDetails(!showDetails);
	}
	function renderImage() {
		if (!showDetails) {
			return (
				<div className="product-image">
					<div className="image-options">
						<a id="show-details" onClick={toggleDetailsClick}>
							Detalles
						</a>
					</div>
					<div className="product-image-box">
						<img
							id="product-image-file"
							src={imageFormat(props.TireProduct.Image, IMAGE_TYPES.DETAILS)}
							alt={props.TireProduct.Name}
						/>
					</div>
				</div>
			);
		} else {
			return null;
		}
	}
	function renderDetailsSpecs() {
		if (showDetails) {
			return (
				<div className="product-description">
					<div className="description-options">
						<a id="show-image" onClick={toggleDetailsClick}>
							Imagen
						</a>
					</div>
					{formatDetails(props.TireProduct.Details)}
				</div>
			);
		} else {
			return null;
		}
	}
	// Render
	if (props.TireProduct == null) {
		return null;
	}
	return (
		<div id="details-view">
			<Element
				name="resultTireDetails"
				id="resultTireDetails"
				className="element"
			></Element>
			<div className="product-separator">
				<span className="product-separator-image"></span>
			</div>
			<div className="product-details">
				<p className="brand-type">
					<span
						id="details-brand"
						className={getBrandClass(props.TireProduct.Brand)}
					></span>
					<span
						id="product-type"
						className={getVehicleTypeClass(props.VehicleType)}
					></span>
				</p>
				<h3 className={getBrandClass(props.TireProduct.Brand)}>
					{props.TireProduct.Name}
				</h3>
				<h4>{props.TireProduct.Slogan}</h4>
				<div className="product-measures">
					<h2>Medida de Llanta</h2>
					<h3>{props.Size}</h3>
					<p>
						Código: <span id="product-code">{props.Code}</span>
					</p>
				</div>
				<div className="product-purchase">
					{renderOriginalPrice(props, qty)}
					{renderPrice(props, qty)}
					<h4>IVA Incluido</h4>
					<div>
						<div>Cantidad</div>
						<ProductDetailsQty
							Kartis={props.Code}
							onChange={handleQtyChange}
							onBlur={handleQtyBlur}
							value={qty}
						/>
					</div>
					<div>
						<button
							id={props.Code + "-qty"}
							name="shop-cart-submit"
							onClick={handleBuyClick}
							type="button"
						>
							Comprar
						</button>
					</div>
				</div>
				<div
					className={"product-specs " + getBrandClass(props.TireProduct.Brand)}
				>
					{renderRadial(props.Radial)}
					{renderIndiceCarga(props.Load)}
					{renderIndiceVelocidad(props.Speed)}
					{renderSsr(props.SSR)}
					{renderDureza(props.Strength)}
					{renderCamara(props.Tube)}
				</div>
			</div>
			{renderImage()}
			{renderDetailsSpecs()}
		</div>
	);
}
