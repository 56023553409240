import { createSlice } from "@reduxjs/toolkit";
import ReactGA from "react-ga4";

const initialState = {
	isOpen: false,
	alert: null,
};

const alert = createSlice({
	name: "resetPassword",
	initialState,
	reducers: {
		setAlert(state, action) {
			state.alert = action.payload.alert;
		},
		toggleShowAlert(state, action) {
			state.isOpen = !action.payload.isOpen;
			if (!action.payload.isOpen) {
				ReactGA.event({
					category: "Alerta",
					action: "Mostrar alerta",
				});
			}
		},
	},
});

export const { setAlert, toggleShowAlert } = alert.actions;

export default alert.reducer;
