import { createSlice } from "@reduxjs/toolkit";
import { sessionService } from "redux-react-session";
import ReactGA from "react-ga4";
import { BASE_API_URL } from "../constants";
import { runUnauthorizedActions } from "features/account/loginSlice";
import axios from "axios";

const initialState = {
	isLoading: false,
	isLoadingItem: false,
	list: null,
	isOpen: false,
	isOpenItem: false,
	selectedItem: null,
	item: null,
};
const orders = createSlice({
	name: "orders",
	initialState,
	reducers: {
		requestOrdersList(state) {
			state.isLoading = true;
		},
		receiveOrdersList(state, action) {
			state.isLoading = false;
			state.list = action.payload.list;
		},
		toggleShowOrdersList(state, action) {
			if (!action.payload.isOpen) {
				ReactGA.event({
					category: "Cuenta",
					action: "Pedidos",
				});
			}
			state.isOpen = !action.payload.isOpen;
		},
		setOrdersListSelectedItem(state, action) {
			state.selectedItem = action.payload.selectedItem;
		},
		toggleShowOrdersListItem(state, action) {
			state.isOpenItem = !action.payload.isOpenItem;
		},
		requestOrder(state) {
			state.isLoadingItem = true;
		},
		receiveOrder(state, action) {
			state.isLoadingItem = false;
			state.item = action.payload.item;
		},
		clearOrdersData: () => initialState,
	},
});

export const {
	requestOrdersList,
	receiveOrdersList,
	toggleShowOrdersList,
	setOrdersListSelectedItem,
	toggleShowOrdersListItem,
	requestOrder,
	receiveOrder,
	clearOrdersData,
} = orders.actions;

const shouldFetchOrder = (state, selected) => {
	if (state.orders.item == null) {
		return true;
	}
	const oldSelected = state.orders.selectedItem;
	if (oldSelected == null) {
		return true;
	}
	if (oldSelected !== selected) {
		return true;
	}
	return false;
};

export const fetchOrderIfNeeded = (selected) => async (dispatch, getState) => {
	const theState = getState();
	if (shouldFetchOrder(theState, selected)) {
		return dispatch(fetchOrder(selected.Id));
	}
};

export const fetchOrdersList = () => async (dispatch) => {
	sessionService
		.loadSession()
		.then((session) => {
			const headers = {
				Authorization: "bearer " + session.loginData.access_token,
				"Content-Type": "application/json",
			};
			const options = {
				headers: headers,
			};
			dispatch(requestOrdersList());
			const url = BASE_API_URL + "/api/Cuenta/MiCuenta/MisPedidos";
			return axios
				.get(url, options)
				.then((response) => {
					dispatch(receiveOrdersList({ list: response.data }));
				})
				.catch((error) => {
					// If 401 Unauthorized login failed
					if (error.response.status === 401) {
						dispatch(runUnauthorizedActions());
					}
				});
		})
		.catch((err) => console.log("Error", err));
};

export const fetchOrder = (id) => async (dispatch) => {
	sessionService
		.loadSession()
		.then((session) => {
			const headers = {
				Authorization: "bearer " + session.loginData.access_token,
				"Content-Type": "application/json",
			};
			const options = {
				headers: headers,
			};
			dispatch(requestOrder());
			const url = BASE_API_URL + "/api/Pedidos/MisPedidos/" + id;
			return axios
				.get(url, options)
				.then((response) => {
					dispatch(toggleShowOrdersListItem({ isOpenItem: false }));
					dispatch(receiveOrder({ item: response.data }));
					dispatch(
						setOrdersListSelectedItem({ selectedItem: response.data.Id })
					);
					ReactGA.event({
						category: "Cuenta",
						action: "Pedidos",
						label: "Detalles de Pedido",
						value: response.data.Numero
					});
				})
				.catch((error) => {
					dispatch(receiveOrder({ item: null }));
					dispatch(setOrdersListSelectedItem({ selectedItem: null }));
					// If 401 Unauthorized login failed
					if (error.response.status === 401) {
						dispatch(runUnauthorizedActions());
					}
				});
		})
		.catch((err) => console.log("Error", err));
};

export default orders.reducer;
