import { toggleShowDealers, toggleShowTerms } from "features/account/footerSlice";
import React from "react";
import { useDispatch } from "react-redux";

export default function StoreFooter() {
	const dispatch = useDispatch();
	// Handlers
	function handleTermsClick() {
		dispatch(toggleShowTerms({ isOpenTerms: false }));
	}
	function handleDealersClick() {
		dispatch(toggleShowDealers({ isOpenDealers: false }));
	}
	function handleVisionZeroClick() {
		window.open(
			"https://www.continentaltire.mx/car/continental/vision-zero",
			"_blank"
		);
	}

	// Render
	return (
		<div id="footer-view">
			<div id="contact-form">
				<h3>Contacto</h3>
				<article>
					<div className="contact-category">Atención a Clientes:</div>
					<div>
						<a href="mailto:servicioalcliente.ti_mj_sa@conti.com.mx">
							<span className="icon-mail" /> servicioalcliente.ti_mj_sa@conti.com.mx
						</a>
					</div>
					<div className="contact-category">Garantías:</div>
					<div>
						<a href="mailto:servicioaclientes@conti.com.mx">
							<span className="icon-mail" /> servicioaclientes@conti.com.mx
						</a>
					</div>
					<div>
						<a>
							<span className="icon-phone" /> 800 821 2000
						</a>
					</div>
					<div>
						<a
							href="https://facebook.com/ContiTireMX"
							target="_blank"
							rel="noopener noreferrer"
						>
							<span className="icon-facebook" /> Facebook
						</a>
					</div>
					<div>
						<a
							href="https://twitter.com/ContinentalMex"
							target="_blank"
							rel="noopener noreferrer"
						>
							<span className="icon-twitter" /> Twitter
						</a>
					</div>
					<div>
						<a
							href="https://youtube.com/ContiMexico"
							target="_blank"
							rel="noopener noreferrer"
						>
							<span className="icon-youtube" /> Youtube
						</a>
					</div>
				</article>
			</div>
			<div id="footer-data">
				<h3>Sitio</h3>
				<aside>
					<ul>
						<li>
							<a onClick={handleTermsClick}>Política de Ventas</a>
						</li>
						<li>
                <a onClick={handleDealersClick}>Servicios Complementarios</a>
            </li>
					</ul>
				</aside>
				<article>
					<div className="sponsor">
						<p>&nbsp;</p>
						<p>
						<img
								src="/images/footer/logo-tecnologia-alemana-inverted.png"
								alt="Tecnología Alemana que conecta tu vida"
								style={{ width: "150px" }}
							/>
						</p>
					</div>
					<div className="sponsor">
						<p>
							<img
								src="/images/footer/esr-logo.png"
								alt="Empresa Socialmente Responsable"
								style={{ width: "124px" }}
							/>
						</p>
					</div>
				</article>
			</div>
		</div>
	);
}
