import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_API_URL } from "features/constants";

const initialState = {
	isLoading: false,
	list: null,
	options: [{ label: "Seleccione", value: "0" }],
};

const locations = createSlice({
	name: "locations",
	initialState,
	reducers: {
		requestLocationList: (state) => {
			state.isLoading = true;
		},
		receiveLocationList: (state, action) => {
			state.list = action.payload.list;
			state.options = mapOptions(action.payload.list);
		},
	},
});

export const { requestLocationList, receiveLocationList } = locations.actions;

const mapOptions = (list) => {
	if (list == null || (list != null && list.length === 0)) {
		return [{ value: 0, label: "Seleccione" }];
	}
	let options = list.map((el) => {
		return {
			value: el.Id,
			label: el.Name,
		};
	});
	options.unshift({ value: 0, label: "Seleccione" });
	return options;
};

const shouldFetchLocationList = (state) => {
	const locations = state.locations;
	if (locations.list == null && !locations.isLoading) {
		return true;
	} else {
		return false;
	}
};

export const fetchLocationListIfNeeded = () => async (dispatch, getState) => {
	if (shouldFetchLocationList(getState())) {
		return dispatch(fetchLocationList());
	}
	return null;
};

const fetchLocationList = () => async (dispatch) => {
	const headers = {
		"Content-Type": "application/x-www-form-urlencoded",
	};
	const options = {
		headers: headers,
	};
	const url = BASE_API_URL + "/api/Localidades";
	dispatch(requestLocationList());
	return axios
		.get(url, options)
		.then((response) => {
			dispatch(receiveLocationList({ list: response.data }));
		})
		.catch((error) => {
			console.log("Error", error);
		});
};

export default locations.reducer;
