import React from "react";

export default function StoreDealers() {
	return (
		<div id="dealers-view">
			<div className="dealers-stripe">
				<h3>Continental Tire de México</h3>
			</div>
			<div className="dealers-map">
				<div id="map_wrapper">
					<div id="map_canvas"></div>
				</div>
				<div id="info_wrapper"></div>
				<div id="info"></div>
			</div>
		</div>
	);
}
