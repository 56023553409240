import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import history from "features/history";
import Scroll from "react-scroll";
import { sessionService } from "redux-react-session";
import { BASE_API_URL } from "../constants";

const initialState = {
	isLoading: false,
	list: null,
	code: null,
	scroll: false,
};
const codeTires = createSlice({
	name: "codeTires",
	initialState,
	reducers: {
		requestCodeTiresList(state) {
			state.isLoading = true;
		},
		receiveCodeTiresList(state, action) {
			state.isLoading = false;
			state.list = action.payload.list;
		},
		setCodeTiresCode(state, action) {
			state.code = action.payload.code;
		},
		setCodeTiresScroll(state, action) {
			state.scroll = action.payload.scroll;
		},
		clearCodeTiresData: () => initialState,
	},
});

export const {
	requestCodeTiresList,
	receiveCodeTiresList,
	setCodeTiresCode,
	setCodeTiresScroll,
	clearCodeTiresData,
} = codeTires.actions;

const shouldFetchCodeTiresList = (state, code) => {
	const tires = state.codeTires;
	if (tires.list == null) {
		return true;
	}
	if (code != null && code !== tires.code) {
		return true;
	}
	return false;
};

export const fetchCodeTiresListIfNeeded =
	(code) => async (dispatch, getState) => {
		const theState = getState();
		if (shouldFetchCodeTiresList(theState, code)) {
			return dispatch(fetchCodeTiresList(code));
		}
	};

export const fetchCodeTiresList = (code) => async (dispatch, getState) => {
	sessionService
		.loadSession()
		.then((session) => {
			const headers = {
				Authorization: "bearer " + session.loginData.access_token,
				"Content-Type": "application/json",
			};
			const options = {
				headers: headers,
			};
			const theState = getState();
			const coupon =
				theState.cart.coupon == null ? null : theState.cart.coupon.Code;
			dispatch(requestCodeTiresList());
			const url =
				coupon != null
					? BASE_API_URL +
					  "/api/Empleados/Productos/Codigo/?id=" +
					  code +
					  "&cupon=" +
					  coupon
					: BASE_API_URL + "/api/Empleados/Productos/Codigo/?id=" + code;
			return axios
				.get(url, options)
				.then((response) => {
					dispatch(receiveCodeTiresList({ list: response.data }));
					dispatch(setCodeTiresScroll({ scroll: true }));
					dispatch(setCodeTiresCode({ code }));
				})
				.catch((error) => {
					// TODO: fetch error actions
					dispatch(clearCodeTiresData());
					history.push("");
				});
		})
		.catch((err) => console.log("Error", err));
};
export const scrollToCodeTires = () => async (dispatch, getState) => {
	const theState = getState();
	if (theState.codeTires.list != null && theState.codeTires.scroll) {
		let scroll = Scroll.animateScroll;
		let target = document.getElementById("resultCarousel");
		let targetPos =
			target.offsetWidth > 980 ? target.offsetTop - 100 : target.offsetTop - 50;
		scroll.scrollTo(targetPos, { smooth: true, delay: 100, duration: 500 });
		dispatch(setCodeTiresScroll({ scroll: false }));
	}
};
export default codeTires.reducer;
