import React from "react";

export default function StoreBannerItem(props) {
	function handleLinkClick() {
		if (props.targetUrl != null) {
			window.open(props.targetUrl, "_blank");
		}
	}
	function renderTitle() {
		if (props.name != null) {
			return <p className="slide-title">{props.name}</p>;
		} else {
			return null;
		}
	}
	function renderText() {
		if (props.text != null) {
			return <p className="slide-text">{props.text}</p>;
		} else return null;
	}
	let slideStyles = { backgroundImage: "url('" + props.image + "')" };
	if (props.targetUrl != null) {
		slideStyles = { ...slideStyles, cursor: "pointer" };
	}
	return (
		<li>
			<div
				className="conti-slide-content"
				style={slideStyles}
				onClick={handleLinkClick}
			>
				{renderTitle()}
				{renderText()}
			</div>
		</li>
	);
}
