import React from "react";
import VehicleFinder from "./VehicleFinder";
// import SizeShortFinder from "./SizeShortFinder";
import SizeFinder from "./SizeFinder";
import SizeShortFinder from "./SizeShortFinder";

export default function TireFinder() {
	return (
		<div id="tire-finder">
			<VehicleFinder />
			<SizeFinder />
			<SizeShortFinder />
		</div>
	);
}
