import React, { useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router";
import Autosuggest from "react-autosuggest";
import Scroll from "react-scroll";
import { VEHICLE_TYPES as vt } from "features/constants";
import {
	clearSearchActions,
	clearSizeWidthDiameterActions,
} from "features/tireFinder/searchSlice";
import { setVehicleType } from "features/tireFinder/vehicleTypeSlice";
import { clearSizeRimValue } from "features/tireFinder/sizeRimsSlice";
import { clearWidthSaeValue } from "features/tireFinder/sizeSaeWidthsSlice";
import { clearSizeRimSaeValue } from "features/tireFinder/sizeSaeRimsSlice";
import { fetchSizeStringTireList } from "features/tireResults/sizeTiresSlice";
import { hideHighlightsTiresList } from "features/tireResults/highlightsTiresSlice";
import {
	clearSizeShortSuggestions,
	loadSizeShortSuggestions,
	updateSizeShortItem,
	updateSizeShortValue,
} from "features/tireFinder/sizeShortSlice";
import { clearSizeRatioValue } from "features/tireFinder/sizeRatiosSlice";
// import TruckTireCanvas from "./TruckTireCanvas";
import VehicleTypeSelector from "./VehicleTypeSelector";
import { setCanvasTruckFirstItem, setCanvasTruckFocus } from "features/tireFinder/sizeCanvasTruckSlice";

const renderSuggestion = (suggestion) => {
	return <div>{suggestion}</div>;
};
const getSuggestionValue = (suggestion) => {
	return suggestion + "";
};

const scroll = Scroll.animateScroll;

export default function SizeShortFinder() {
	const dispatch = useDispatch();
	// Selectors
	const { vehicleType, sizeShort } = useSelector((state) => state);
	const tireList = useSelector((state) => state.sizeTires.list);
	// const tireSaeList = useSelector((state) => state.sizeSaeTires.list);
	// Refs
	const sizeRef = useRef();
	// Navigate
	const navigate = useNavigate();
	const location = useLocation();
	// Effect
	useEffect(() => {
		if (vehicleType?.value === "5" && sizeShort.list != null && tireList == null) {
			sizeRef.current.focus();
		}
	}, [sizeShort.list, tireList, vehicleType?.value]);
	// Handlers
	function handleLocationReset() {
		if (location !== "/") {
			navigate("/");
		}
	}
	function handleVehicleTypeChange(value) {
		dispatch(clearSearchActions());
		dispatch(setVehicleType({ value }));
		// dispatch(fetchSizeShortIfNeeded(value));
	}
	// Size Short
	function handleSizeShortChange(e, { newValue, method }) {
		if (method === "type" || method === "enter" || method === "click") {
			dispatch(clearSizeWidthDiameterActions());
			dispatch(updateSizeShortValue({ value: newValue }));
			dispatch(updateSizeShortItem({ selectedItem: {} }));
			handleLocationReset();
		}
	}
	function handleSizeShortFocus(e) {
		e.target.select();
		dispatch(setCanvasTruckFocus({ focus: 0 }));
	}
	function onSizeShortSelected(e, { suggestion }) {
		dispatch(setCanvasTruckFirstItem({ first: suggestion + "", isSAE: false }));
		dispatch(fetchSizeStringTireList(vehicleType.value, suggestion));
		dispatch(hideHighlightsTiresList({ hide: true }));
		dispatch(clearSizeRatioValue({ renderInput: true, showInput: true }));
		dispatch(clearSizeRimValue({ renderInput: false, showInput: true }));
		dispatch(clearWidthSaeValue({ renderInput: false, showInput: false }));
		dispatch(clearSizeRimSaeValue({ renderInput: false, showInput: false }));
		dispatch(updateSizeShortItem({ selectedItem: suggestion }));
		navigate(
			`/llantas/medida/${vt.properties[vehicleType.value].slug}/${suggestion}`
		);
		setTimeout(() => {
			const target = document.getElementById("resultCarousel");
			const targetPos =
				target.offsetWidth > 980
					? target.offsetTop - 200
					: target.offsetTop - 150;
			scroll.scrollTo(targetPos, { smooth: true, delay: 100, duration: 500 });
		}, 500);
		sizeRef.current.blur();
	}
	function onSizeShortFetchRequested({ value }) {
		dispatch(loadSizeShortSuggestions(value));
	}
	function onSizeShortClearRequested() {
		dispatch(clearSizeShortSuggestions());
	}
	if (vehicleType.value === "0") {
		return null;
	}
	// Input props
	const sizeShortProps = {
		placeholder: "Escribe la medida...",
		value: sizeShort.value,
		onChange: handleSizeShortChange,
		onFocus: handleSizeShortFocus,
		tabIndex: 201,
		ref: sizeRef,
	};
	// Render
	return (
		<div id="measure-finder">
			<VehicleTypeSelector
				onChange={handleVehicleTypeChange}
				vehicleType={vehicleType}
			/>
			<div>
				<div className="field">
					<div>
						<Autosuggest
							highlightFirstSuggestion
							suggestions={sizeShort.suggestions}
							onSuggestionsFetchRequested={onSizeShortFetchRequested}
							onSuggestionsClearRequested={onSizeShortClearRequested}
							getSuggestionValue={getSuggestionValue}
							renderSuggestion={renderSuggestion}
							onSuggestionSelected={onSizeShortSelected}
							inputProps={sizeShortProps}
							// shouldRenderSuggestions={() => true}
							id="sizeShort"
						/>
					</div>
				</div>
			</div>
			{/* <TruckTireCanvas /> */}
		</div>
	);
}
