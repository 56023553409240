import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { SHIP_TYPES as s, PAYMENT_METHODS as p } from "features/constants";
import {
	getBrandClass,
	imageFormat,
	doesDiscountAplies,
	formatProductCount,
	getModalButtonClass,
	currencyFormat,
} from "features/functions";
import {
	setCartActiveTab,
	fetchPaymentCard,
	fetchPaymentDeposit,
	fetchPaymentPaynet,
	fetchPaymentPayPal,
	fetchPaymentSafetyPay,
} from "features/cart/cartSlice";
import Loader from "../Loader";
import OpenPayCardForm from "./OpenPayCardForm";
import OpenPayCvvCardForm from "./OpenPayCvvCardForm";

export default function CartSummary() {
	const dispatch = useDispatch();
	// Selectors
	const {
		isLoading,
		cartObject,
		paymentType,
		showTab,
		shipType,
		paymentCard,
		isProcessing,
} = useSelector((state) => state.cart);
	// Handlers
	function handlePrevClick() {
		dispatch(setCartActiveTab({ showTab: showTab - 1 }));
	}
	function handleFinishClick() {
		switch (paymentType) {
			case p.CREDITCARD:
				dispatch(fetchPaymentCard());
				break;
			case p.PAYPAL:
				dispatch(fetchPaymentPayPal());
				break;
			case p.SAFETYPAY:
				dispatch(fetchPaymentSafetyPay());
				break;
			case p.DEPOSIT:
				dispatch(fetchPaymentDeposit());
				break;
			case p.PAYNET:
				dispatch(fetchPaymentPaynet());
				break;
			default:
				break;
		}
	}
	function getFinishStatus() {
		let result = true;
		if (shipType !== null && paymentType !== null) {
			result = false;
		}
		if (paymentType === p.CREDITCARD || paymentType === p.PAYPAL) {
			result = false;
		}
		return result;
	}
	// Render functions
	function renderContentSummary(data, aplicaDescuento) {
		const campania = cartObject.CuponDescuento.Campania;
		return (
			<div id="cart-content-summary">
				{data.map(function (element, index) {
					// Generador de Precio y Subtotal
					let price = null;
					let subtotal = null;
					if (aplicaDescuento) {
						// Si el tipo de campaña es 6 (SinCuponPorProductoMinPorCodigo)
						if (campania.Tipo === 6) {
							// Si aplica el descuento
							if (
								element.Tire.Discount > 0 &&
								element.Cantidad >= campania.MinProductos
							) {
								// Si la llanta tiene descuento y aplica
								price = (
									<div>
										<h3 className="discount">
											{currencyFormat(element.Tire.ListPrice * 1.16)}{" "}
										</h3>
										<h3>{currencyFormat(element.Tire.Price * 1.16)} </h3>
										<p>MXN</p>
									</div>
								);
								subtotal = (
									<div>
										<h3>
											{currencyFormat(
												element.Tire.Price * element.Cantidad * 1.16
											)}{" "}
										</h3>
										<p>MXN</p>
									</div>
								);
							} else {
								// Si la llanta no tiene descuento
								price = (
									<div>
										<h3>{currencyFormat(element.Tire.ListPrice * 1.16)} </h3>
										<p>MXN</p>
									</div>
								);
								subtotal = (
									<div>
										<h3>
											{currencyFormat(
												element.Tire.ListPrice * element.Cantidad * 1.16
											)}{" "}
										</h3>
										<p>MXN</p>
									</div>
								);
							}
						} else {
							// Si aplica el descuento
							if (element.Tire.Discount > 0) {
								// Si la llanta tiene descuento
								price = (
									<div>
										<h3 className="discount">
											{currencyFormat(element.Tire.ListPrice * 1.16)}{" "}
										</h3>
										<h3>{currencyFormat(element.Tire.Price * 1.16)} </h3>
										<p>MXN</p>
									</div>
								);
								subtotal = (
									<div>
										<h3>
											{currencyFormat(
												element.Tire.Price * element.Cantidad * 1.16
											)}{" "}
										</h3>
										<p>MXN</p>
									</div>
								);
							} else {
								// Si la llanta no tiene descuento
								price = (
									<div>
										<h3>{currencyFormat(element.Tire.ListPrice * 1.16)} </h3>
										<p>MXN</p>
									</div>
								);
								subtotal = (
									<div>
										<h3>
											{currencyFormat(
												element.Tire.ListPrice * element.Cantidad * 1.16
											)}{" "}
										</h3>
										<p>MXN</p>
									</div>
								);
							}
						}
					} else {
						// Si no aplica el descuento
						price = (
							<div>
								<h3>{currencyFormat(element.Tire.ListPrice * 1.16)} </h3>
								<p>MXN</p>
							</div>
						);
						subtotal = (
							<div>
								<h3>
									{currencyFormat(
										element.Tire.ListPrice * element.Cantidad * 1.16
									)}{" "}
								</h3>
								<p>MXN</p>
							</div>
						);
					}
					return (
						<div className="item" id={element.Kartis} key={index}>
							<div>
								<h3>{element.Cantidad}</h3>
							</div>
							<div>
								<img
									src={imageFormat(element.Tire.TireProduct.Image)}
									alt={element.Tire.TireProduct.Name}
								/>
								<span
									className={"cart-" + getBrandClass(element.Tire.TireProduct.Brand)}
								/>
							</div>
							<div>
								<h2>{element.Tire.TireProduct.Name}</h2>
								<h3>{element.Tire.Size}</h3>
								<p>{element.Kartis}</p>
							</div>
							{price}
							{subtotal}
						</div>
					);
				})}
			</div>
		);
	}
	if (isLoading) {
		return (
			<div id="cart-content-4">
				<Loader msg="Por favor espere..." />
			</div>
		);
	}
	function renderCardForm() {
		if (paymentType === p.CREDITCARD && paymentCard === 0) {
			return (
				<OpenPayCardForm
					onPrev={handlePrevClick}
					finishStatus={finishStatus}
					onFinish={handleFinishClick}
				/>
			);
		}
		return null;
	}
	function renderCvvForm() {
		if (paymentType === p.CREDITCARD && paymentCard > 0) {
			return (
				<OpenPayCvvCardForm
					onPrev={handlePrevClick}
					finishStatus={finishStatus}
					onFinish={handleFinishClick}
				/>
			);
		}
	}
	function renderSubmit() {
		if (paymentType !== p.CREDITCARD) {
			return (
				<div>
					<div>
						<div className="modal-button" onClick={handlePrevClick}>
							Anterior
						</div>
					</div>
					<div>
						<div
							className={
								!finishStatus
									? getModalButtonClass(finishStatus) + " alternate"
									: getModalButtonClass(finishStatus)
							}
							onClick={handleFinishClick}
						>
							Finalizar
						</div>
					</div>
				</div>
			);
		}
		return null;
	}
	// Render
	const totalAmount =
		shipType === s.DHL_EMPLEADOS
			? cartObject.Total * 1.16 + cartObject.Costo_Envio
			: cartObject.Total * 1.16;
	const finishStatus = getFinishStatus();
	if (isProcessing) {
		return <Loader msg="Procesando..." />;
	}
	return (
		<div id="cart-content-4">
			<div id="cart-items-count">
				{formatProductCount(cartObject.Articulos)}
			</div>
			<div id="cart-content-headers">
				<div>Cantidad</div>
				<div>Descripción</div>
				<div>Precio</div>
				<div>Importe</div>
			</div>
			{renderContentSummary(
				cartObject.Elementos,
				doesDiscountAplies(
					cartObject.ArticulosDescuento,
					cartObject.CuponDescuento
				)
			)}
			<div id="cart-summary">
				<div id="cart-summary-details">
					<div>
						<span>Envío - </span>
						{s.properties[shipType].fullname}
					</div>
					<div>
						<span>Forma de pago - </span>
						{p.properties[paymentType].name}
					</div>
				</div>
				<div id="cart-totals">
					<div>
						<div>Subtotal</div>
						<div>{currencyFormat(cartObject.Total * 1.16)}</div>
					</div>
					<div>
						<div>Envío</div>
						<div>
							{currencyFormat(
								shipType === s.DHL_EMPLEADOS ? cartObject.Costo_Envio : 0
							)}
						</div>
					</div>
					<div>
						<div className="totals">Total</div>
						<div>{currencyFormat(totalAmount)}</div>
					</div>
					<div>
						<div>Desglose IVA</div>
						<div>{currencyFormat(cartObject.Total * 0.16)} </div>
					</div>
					{renderSubmit()}
				</div>
			</div>
			{renderCardForm()}
			{renderCvvForm()}
		</div>
	);
}
