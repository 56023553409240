import React from "react";
import { useSelector } from "react-redux";
import { SHIP_TYPES } from "features/constants";
import CartShipToButton from "./CartShipToButton";

const getShipToButtonClass = (selected) => {
	if (selected) {
		return "shipto-item selected";
	}
	return "shipto-item";
};

export default function CartShipTo() {
	// Selectors
	const { shipType } = useSelector((state) => state.cart);
	const { data } = useSelector((state) => state.shipLocations);
	// Render functions
	const renderEmpleados = () => {
		const type = SHIP_TYPES.EMPLEADOS;
		if (data != null && data.ShipHR) {
			return (
				<div
					className={getShipToButtonClass(shipType === SHIP_TYPES.EMPLEADOS)}
				>
					<div>
						<span className="icon-check" />
						{SHIP_TYPES.properties[type].fullname}
					</div>
					<CartShipToButton amount={0} shipType={type} />
					<div>{data.Name}</div>
				</div>
			);
		}
		return null;
	};
	function renderDealer() {
		const type = SHIP_TYPES.DEALER;
		return (
			<div className={getShipToButtonClass(shipType === SHIP_TYPES.DEALER)}>
				<div>
					<span className="icon-check"></span>
					{SHIP_TYPES.properties[type].fullname}
				</div>
				<CartShipToButton amount={0} shipType={type} />
			</div>
		);
	}
	// Render
	if (
		shipType === 0 ||
		(shipType === SHIP_TYPES.EMPLEADOS && data.ShipHR) ||
		(shipType === SHIP_TYPES.DHL_EMPLEADOS && data.ShipDHL) ||
		(shipType === SHIP_TYPES.ShipDealer && data.ShipDealer)
	) {
		return (
			<div id="cart-content-shipto">
				{renderEmpleados()}
				{renderDealer()}
			</div>
		);
	}
	return null;
}
