import { createSlice } from "@reduxjs/toolkit";
import ReactGA from "react-ga4";
import { BASE_API_URL } from "../constants";
import { getMatchingItemsYears } from "features/functions";
import { sessionService } from "redux-react-session";
import axios from "axios";
import { runUnauthorizedActions } from "features/account/loginSlice";

const initialState = {
	isLoading: false,
	value: "",
	selectedItem: {},
	suggestions: [],
	renderInput: false,
	list: null,
};

const vehicleYears = createSlice({
	name: "vehicleYears",
	initialState,
	reducers: {
		requestVehicleYearsList(state) {
			state.isLoading = true;
		},
		receiveVehicleYearsList(state, { payload }) {
			state.isLoading = false;
			state.list = payload.list;
			state.renderInput = payload.renderInput;
		},
		loadVehicleYearsSuggestionsBegin(state) {
			state.isLoading = true;
		},
		maybeUpdateVehicleYearsSuggestions(state, { payload }) {
			state.isLoading = false;
			if (state.value !== payload.value) {
			} else {
				state.suggestions = payload.suggestions;
			}
		},
		updateVehicleYearValue(state, { payload }) {
			state.value = payload.value;
		},
		updateVehicleYearItem(state, { payload }) {
			if (payload.selectedItem?.anio != null) {
				ReactGA.event({
					category: "Vehiculo",
					action: "Seleccionar Año",
					label: payload.selectedItem.anio + "",
					value: payload.selectedItem.anio,
				});
			}
			state.selectedItem = payload.selectedItem;
		},
		clearVehicleYearsSuggestions(state) {
			state.suggestions = [];
		},
		clearVehicleYearValue(state, { payload }) {
			state.value = "";
			state.selectedItem = {};
			state.renderInput = payload.renderInput;
		},
		clearVehicleYearData: () => initialState,
	},
});

export const {
	requestVehicleYearsList,
	receiveVehicleYearsList,
	loadVehicleYearsSuggestionsBegin,
	maybeUpdateVehicleYearsSuggestions,
	updateVehicleYearValue,
	updateVehicleYearItem,
	clearVehicleYearsSuggestions,
	clearVehicleYearValue,
	clearVehicleYearData,
} = vehicleYears.actions;

const shouldFetchVehicleYears = (state, modelId) => {
	const model = state.vehicleModels.selectedItem;
	if (state.vehicleYears.list == null) {
		if (model != null) {
			return true;
		}
		return false;
	}
	if (model?.ID !== modelId) {
		return true;
	}
	return false;
};
export const fetchVehicleYearsIfNeeded =
	(modelId) => async (dispatch, getState) => {
		const theState = getState();
		if (shouldFetchVehicleYears(theState, modelId)) {
			return dispatch(
				fetchVehicleYears(theState.vehicleBrands.selectedItem.ID, modelId)
			);
		}
	};
export const loadVehicleYearsSuggestions =
	(value) => async (dispatch, getState) => {
		dispatch(loadVehicleYearsSuggestionsBegin());
		const theState = getState();
		dispatch(fetchVehicleYearsIfNeeded(theState.vehicleModels.selectedItem.ID));
		setTimeout(() => {
			dispatch(
				maybeUpdateVehicleYearsSuggestions({
					suggestions: getMatchingItemsYears(theState.vehicleYears, value),
					value,
				})
			);
		});
	};
export const fetchVehicleYears = (brandId, modelId) => async (dispatch) => {
	sessionService
		.loadSession()
		.then((session) => {
			const headers = {
				Authorization: "bearer " + session.loginData.access_token,
				"Content-Type": "application/json",
			};
			const options = {
				headers: headers,
			};
			dispatch(requestVehicleYearsList());
			const url =
				BASE_API_URL +
				"/api/Empleados/Vehiculos/Buscar/Anios/?marca=" +
				brandId +
				"&modelo=" +
				modelId;
			return axios
				.get(url, options)
				.then((response) => {
					dispatch(
						receiveVehicleYearsList({ list: response.data, renderInput: true })
					);
				})
				.catch((error) => {
					// If 401 Unauthorized login failed
					if (error.response.status === 401) {
						dispatch(runUnauthorizedActions());
					}
				});
		})
		.catch((err) => console.log("Error", err));
};

export default vehicleYears.reducer;
