import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCartActiveTab, setCartUserData } from "features/cart/cartSlice";
import { fetchPaymentCardListIfNeeded } from "features/cart/paymentCardSlice";
import { SHIP_TYPES } from "features/constants";
import {
	currencyFormat,
	getModalButtonClass,
	validateMail,
	validatePhone,
} from "features/functions";
import { setAccountErrorMsg } from "features/account/accountSlice";

export default function CartMyData() {
	const dispatch = useDispatch();
	// Selectors
	const { cartObject, shipType, showTab } = useSelector((state) => state.cart);
	const { errorMsg } = useSelector((state) => state.account);
	const { authenticated, checked } = useSelector((state) => state.session);
	const user = useSelector((state) => state.session.user);
	// State
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	// Effect
	useEffect(() => {
		if (checked && authenticated) {
			setEmail(user.user.email);
			setPhone(user.user.phone);
		}
	}, [checked, authenticated, user]);
	// Handlers
	function handleMailChange(e) {
		if (errorMsg != null && errorMsg.email != null) {
			dispatch(
				setAccountErrorMsg({
					errorMsg: { ...errorMsg, email: null, error: null },
				})
			);
		}
		setEmail(e.target.value);
	}
	function handlePhoneChange(e) {
		if (errorMsg != null && errorMsg.phone != null) {
			dispatch(
				setAccountErrorMsg({
					errorMsg: { ...errorMsg, phone: null, error: null },
				})
			);
		}
		setPhone(e.target.value);
	}
	function handlePrevClick() {
		dispatch(setAccountErrorMsg({ errorMsg: null }));
		dispatch(setCartActiveTab({ showTab: showTab - 1 }));
	}
	function handleNextClick() {
		let valid = true;
		let errorMessage = {};
		if (email === "") {
			errorMessage = {
				...errorMessage,
				email: "Debes escribir tu correo electrónico.",
			};
			valid = false;
		} else if (!validateMail(email)) {
			errorMessage = {
				...errorMessage,
				email: "Debes escribir un correo electrónico válido.",
			};
			valid = false;
		}
		if (phone === "") {
			errorMessage = { ...errorMessage, phone: "Debes escribir tu teléfono." };
			valid = false;
		} else if (!validatePhone(phone)) {
			errorMessage = {
				...errorMessage,
				phone: "Debes escribir un teléfono válido.",
			};
			valid = false;
		}
		if (!valid) {
			dispatch(setAccountErrorMsg({ errorMsg: errorMessage }));
		} else {
			dispatch(setCartUserData({ email, phone }));
			dispatch(setCartActiveTab({ showTab: showTab + 1 }));
			dispatch(fetchPaymentCardListIfNeeded());
		}
	}
	// Render
	function renderError(error) {
		if (error != null) {
			if (error !== "") {
				return <li>{error}</li>;
			} else return null;
		} else return null;
	}
	function renderErrorList() {
		if (errorMsg != null) {
			return (
				<ul className="error-list">
					{renderError(errorMsg.email)}
					{renderError(errorMsg.phone)}
					{renderError(errorMsg.error)}
				</ul>
			);
		} else {
			return null;
		}
	}
	const totalAmount =
		shipType === SHIP_TYPES.DHL_EMPLEADOS
			? cartObject.Total * 1.16 + cartObject.Costo_Envio
			: cartObject.Total * 1.16;
	return (
		<div id="cart-content-2">
			<div className="register-form">
				<div>
					<div className="full">
						<p>
							Por favor complete y/o actualice su correo electrónico y celular
							para que podamos contactarlo respecto de su compra en caso de ser
							necesario:
						</p>
					</div>
					<div>
						<label>Email</label>
						<input
							type="email"
							className="modal-field"
							placeholder="Email"
							tabIndex={1}
							onChange={handleMailChange}
							value={email}
						/>
					</div>
					<div>
						<label>Celular</label>
						<input
							type="tel"
							className="modal-field"
							placeholder="Teléfono"
							tabIndex={6}
							onChange={handlePhoneChange}
							value={phone}
						/>
					</div>
				</div>
				{renderErrorList()}
			</div>
			<div id="cart-summary-2">
				<div id="cart-totals">
					<div>
						<div>Subtotal</div>
						<div>{currencyFormat(cartObject.Total * 1.16)}</div>
					</div>
					<div>
						<div>Envío</div>
						<div>
							{" "}
							{currencyFormat(
								shipType === SHIP_TYPES.DHL_EMPLEADOS
									? cartObject.Costo_Envio
									: 0
							)}
						</div>
					</div>
					<div>
						<div className="totals">Total</div>
						<div>{currencyFormat(totalAmount)}</div>
					</div>
					<div>
						<div>Desglose IVA</div>
						<div>{currencyFormat(cartObject.Total * 0.16)} </div>
					</div>
					<div>
						<div>
							<div className="modal-button" onClick={handlePrevClick}>
								Anterior
							</div>
						</div>
						<div>
							<div
								className={getModalButtonClass(email === 0 || phone == null)}
								onClick={handleNextClick}
							>
								Continuar
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
