import React from "react";
import Scroll from "react-scroll";
import StoreBanner from "./StoreBanner";
import TireFinder from "./search/TireFinder";
import Highlights from "./Highlights";
import ResultProduct from "./search/ResultProduct";
import ResultVehicle from "./search/ResultVehicle";
import ResultSize from "./search/ResultSize";
import ResultSizeSae from "./search/ResultSizeSae";
import StoreDealers from "./StoreDealers";
import StoreFooter from "./StoreFooter";
import ResultCode from "./search/ResultCode";

var Element = Scroll.Element;

export default function StoreContent() {
	return (
		<div className="contenido">
			<StoreBanner />
			<Element
				name="tireFinderElement"
				id="tireFinderElement"
				className="element"
			></Element>
			<TireFinder />
			<Element
				name="resultCarousel"
				id="resultCarousel"
				className="element"
			></Element>
			<Highlights />
			<ResultCode />
			<ResultProduct />
			<ResultVehicle />
			<ResultSize />
			<ResultSizeSae />
			<StoreDealers />
			<StoreFooter />
		</div>
	);
}
