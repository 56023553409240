import React from "react";
import HomeContent from "./HomeContent";
import AlertModal from "components/alert/AlertModal";
import LoginModal from "components/account/LoginModal";

export default function Home() {
	// Handlers
	return (
		<div>
			<div id="logo-conti-top">
				<span></span>
			</div>
			<div id="main-menu" className="home">
				<div id="logo-conti-top-mobile">
					<span></span>
				</div>
			</div>
			<div id="outer-frame">
				<div id="main-container"></div>
			</div>
			<HomeContent />
			<LoginModal />
			<AlertModal />
		</div>
	);
}
