import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-modal";
import { toggleShowTerms } from "features/account/footerSlice";

export default function TermsModal() {
	const dispatch = useDispatch();
	// Selectors
	const { isOpenTerms } = useSelector((state) => state.footer);
	// Handlers
	function handleCloseClick() {
		dispatch(toggleShowTerms({ isOpenTerms: true }));
	}
	return (
		<div>
			<Modal
				isOpen={isOpenTerms}
				className="modal full"
				overlayClassName="modal-overlay full"
				onRequestClose={handleCloseClick}
				contentLabel="Registro"
			>
				<div className="modal-inner" id="legal-inner">
					<div className="modal-close" onClick={handleCloseClick}></div>
					<h2>POLÍTICA DE VENTA DE LLANTAS A EMPLEADOS</h2>
					<h3>1. Propósito</h3>
					<p>
						Establecer los lineamientos para la administración del Programa de
						Venta de Llantas a Empleados de Continental.
					</p>
					<h3>2. Área de aplicación</h3>
					<p>
						Esta política abarca a todas las localidades de todas las divisiones
						de Continental en México
					</p>
					<h3>3. Procedimiento / Responsabilidades</h3>
					<blockquote>
						<h3>3.1 Elegibilidad</h3>
						<p>
							Serán elegibles para participar en el programa todos los empleados
							activos de Continental en México que tengan contrato de planta. No
							participan empleados de outsourcing o proveedores.
						</p>
						<h3>3.2 Modelos disponibles</h3>
						<p>
							Participan en este programa exclusivamente las marcas, modelos y
							líneas de producto para motocicleta, auto y camioneta
							comercializados en México; que tengan código de producto activo al
							momento de la compra.
						</p>
						<h3>3.3. Proceso de Compra</h3>
						<p>
							Para recibir los precios de venta asignados al Programa de Venta a
							Empleados Continental, el empleado deberá realizar su compra
							directamente a través de la página www.contishopempleados.mx
						</p>
						<p>
							Cada empleado podrá comprar hasta un máximo de{" "}
							<strong>16 Llantas</strong> durante el año, que serán
							contabilizadas en forma automática al realizar la compra.
						</p>
						<blockquote>
							<h3>3.3.1 Opciones de Entrega</h3>
							<p>
								Los empleados podrán seleccionar uno de los Talleres / Llanteras
								autorizados dentro del programa para recibir sus llantas, así
								como el servicio de instalación GRATIS (Montaje, válvula y
								balanceo) en un plazo de 7 días hábiles.
							</p>
							<p>
								En algunas localidades y de acuerdo con sus políticas internas,
								los empleados también tendrán disponible el servicio de entrega
								a través del transporte de la Compañía.
							</p>
							<p>
								En este caso el Departamento de RH de la localidad será
								responsable de determinar el proceso de recepción de las
								llantas, así como de la respectiva entrega al empleado.
							</p>
							<p>
								Se podrán consultar las opciones disponibles al momento de la
								compra.
							</p>
							<h3>3.3.2 Métodos de pago</h3>
							<p>
								Para completar la compra, el empleado liquidará el costo
								completo de su pedido a través de las opciones que se indican en
								la página www.contishopempleados.mx.
							</p>
							<h3>3.3.3 Devoluciones</h3>
							<p>
								No se aceptan Cambios ni Devoluciones una vez entregadas las
								llantas en cada localidad, excepto por equivocación del mismo
								envío no imputable al empleado.
							</p>
						</blockquote>
						<h3>3.3.4 Garantías</h3>
						<p>
							Para Garantías y métodos de aplicación, consultar las condiciones
							en la página www.contishopempleados.mx.
						</p>
						<h3>3.4 Servicios Complementarios</h3>
						<p>
							En caso de requerir servicios complementarios relacionados con sus
							llantas como la alineación, el empleado podrá adquirir estos
							servicios con un precio especial a través de la red de
							distribuidores de Continental.
						</p>
						<p>
							La lista de distribuidores, sucursales se encuentra disponible
							ingresando a www.continentaltire.mx en la sección de Localizador
							de distribuidores.
						</p>
					</blockquote>
					<h3>4. Administración</h3>
					<p>
						Cada Gerente de Recursos Humanos es responsable de la Administración
						de esta política. Cualquier pregunta referente a la interpretación
						de esta política deberá ser referida al Coordinador Nacional de
						Recursos Humanos y/o al Gerente Nacional de Ventas-PLT.
					</p>
					<p>
						La Compañía se reserva el derecho de interpretar cualquier pregunta
						referente a la elegibilidad, aplicación o implementación de esta
						política. Cualquier pregunta referente a ella deberá ser dirigida al
						Gerente local de Recursos Humanos.
					</p>
					<p>
						La compañía se reserva el derecho de modificar o dar por terminada
						esta política en cualquier momento. Cualquier modificación será
						realizada por el Coordinador Nacional de Recursos Humanos y el
						Gerente Nacional de Ventas-PLT.
					</p>
					<h2>¡Importante!</h2>
					<h3>El inventario es dinámico y está sujeto a disponibilidad.</h3>
				</div>
			</Modal>
		</div>
	);
}
