import React from "react";
import ReactModal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import {
	fetchFamily,
	toggleShowFamily,
} from "features/account/familyFriendsSlice";
import Loader from "components/Loader";

export default function FamilyModal() {
	const dispatch = useDispatch();
	// Selectors
	const { isOpen, isLoading } = useSelector((state) => state.familyFriends);
	// Handlers
	const handleCloseClick = () => {
		dispatch(toggleShowFamily({ isOpen: true }));
	};
	const handleDownloadClick = () => {
		dispatch(fetchFamily());
	};
	const renderFamily = () => {
		if (!isLoading) {
			return (
				<div className="register-form">
					<div>
						<p>
							<img
								src="/images/familyfriends.jpg"
								style={{ width: "100%" }}
								alt="ContiShop Family &amp; Friends"
							/>
						</p>
						<p>
							Aquí puedes solicitar tus cupones Family &amp; Friends para
							compartir tus beneficios de empleado con tus familiares y amigos.
						</p>
						<p>Para solicitar un cupón debes realizar lo siguiente:</p>
						<ol>
							<li>Hacer click el botón de solicitar cupón.</li>
							<li>
								Esperar a que se realice la descarga del cupón en formato PDF de
								forma automática.
							</li>
							<li>Compártirlo con tus familiares o amigos.</li>
						</ol>
						<p>
							Recuerda que no podrás exceder el límite permitido de cupones por
							empleado.
						</p>
						<p>
							Para cualquier duda respecto del programa Family &amp; Friends
							envía un correo a la siguiente dirección:
							<a
								href="mailto:servicioalcliente.ti_mj_sa@conti.com.mx"
								target="_blank"
								rel="noopener noreferrer"
							>
								{" "}
								servicioalcliente.ti_mj_sa@conti.com.mx
							</a>
						</p>
					</div>
					<div>
						<div className="full center">
							<div
								tabIndex={20}
								className="modal-button alternate large"
								onClick={handleDownloadClick}
							>
								Solicitar cupón
							</div>
						</div>
					</div>
				</div>
			);
		}
		return null;
	};
	const renderLoader = () => {
		if (isLoading) {
			return <Loader msg="Por favor espere..." />;
		} else {
			return null;
		}
	};
	return (
		<div>
			<ReactModal
				isOpen={isOpen}
				className="modal"
				overlayClassName="modal-overlay"
				onRequestClose={handleCloseClick}
				contentLabel="FamilyFriends"
			>
				<div className="modal-inner" id="login-inner">
					<div className="modal-close" onClick={handleCloseClick} />
					<h2>Family &amp; Friends</h2>
					{renderFamily()}
					{renderLoader()}
				</div>
			</ReactModal>
		</div>
	);
}
